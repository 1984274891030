import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import classNames from 'classnames/bind';
import TechnologyAdoption from '../../../TechnologyAdoption';

import useStyles from './styles';

const TechnologyAdoptionSegments = (props) => {
	const { data, colourTheme } = props;

	const classes = useStyles(props);

	return (
		<div className={classes.technologyAdoptionSection}>
			<Grid item xs={12}>
				<h3 className={classes.statTitle}>{data.headingText}</h3>
			</Grid>
			<Grid item container xs={12}>
				<div className={classNames(classes.technologyAdoption)}>
					<TechnologyAdoption data={data.data} colourTheme={colourTheme} />
				</div>
			</Grid>
		</div>
	);
};

TechnologyAdoptionSegments.propTypes = {
	data: PropTypes.shape({
		headingText: PropTypes.string,
		data: PropTypes.shape({
			earlyAdopters: PropTypes.string,
			earlyMajority1: PropTypes.string,
			earlyMajority2: PropTypes.string,
			lateMajority1: PropTypes.string,
			lateMajority2: PropTypes.string,
			laggards: PropTypes.string,
		}),
	}),
	colourTheme: PropTypes.object,
};

TechnologyAdoptionSegments.defaultProps = {
	data: {
		headingText: '',
		data: {
			earlyAdopters: '',
			earlyMajority1: '',
			earlyMajority2: '',
			lateMajority1: '',
			lateMajority2: '',
			laggards: '',
		},
	},
	colourTheme: null,
};

export default TechnologyAdoptionSegments;
