import * as ACTION_TYPES from '../../constants/ActionTypes';

export function signIn(username, password) {
	return {
		type: ACTION_TYPES.SIGNIN_REQUEST,
		username,
		password,
	};
}

export function loginSuccess(result) {
	return {
		type: ACTION_TYPES.LOGIN_SUCCESS,
		result,
	};
}

export function loginFailure(error) {
	return {
		type: ACTION_TYPES.LOGIN_ERROR,
		error,
	};
}

export function signOut() {
	return {
		type: ACTION_TYPES.SIGNOUT_REQUEST,
	};
}

export function logoutSuccess() {
	return {
		type: ACTION_TYPES.LOGOUT_SUCCESS,
	};
}

export function logoutFailure(error) {
	return {
		type: ACTION_TYPES.LOGOUT_ERROR,
		error,
	};
}

export function inputLoginUser(value) {
	return {
		type: ACTION_TYPES.INPUT_LOGIN_USER,
		value,
	};
}

export function inputLoginPassword(value) {
	return {
		type: ACTION_TYPES.INPUT_LOGIN_PASSWORD,
		value,
	};
}

export function getUserPermissionsAction(username) {
	return {
		type: ACTION_TYPES.GET_USER_PERMISSIONS,
		username,
	};
}

export function getUserPermissionsActionDone(data) {
	return {
		type: ACTION_TYPES.GET_USER_PERMISSIONS_DONE,
		data,
	};
}

export function getUserPermissionsActionFailed(error) {
	return {
		type: ACTION_TYPES.GET_USER_PERMISSIONS_FAILED,
		error,
	};
}

export function fetchOwnerUserAction() {
	return {
		type: ACTION_TYPES.FETCH_OWNER_USER_INFO,
	};
}

export function fetchOwnerUserActionDone(data) {
	return {
		type: ACTION_TYPES.FETCH_OWNER_USER_INFO_DONE,
		data,
	};
}

export function fetchOwnerUserActionFailed(error) {
	return {
		type: ACTION_TYPES.FETCH_OWNER_USER_INFO_FAILED,
		error,
	};
}

export function resetUserPassword(userId, newPassword) {
	return {
		type: ACTION_TYPES.RESET_USER_PASSWORD,
		userId,
		newPassword,
	};
}

export function resetUserPasswordDone(data) {
	return {
		type: ACTION_TYPES.RESET_USER_PASSWORD_DONE,
		data,
	};
}

export function resetUserPasswordFailed(error) {
	return {
		type: ACTION_TYPES.RESET_USER_PASSWORD_FAILED,
		error,
	};
}

export function toggleResetPasswordModal(bools) {
	return {
		type: ACTION_TYPES.TOGGLE_RESET_USER_PASSWORD_MODAL,
		bools,
	};
}

export function inputUserNewPassword(value) {
	return {
		type: ACTION_TYPES.INPUT_RESET_USER_PASSWORD,
		value,
	};
}

export function inputUserNewPasswordAgain(value) {
	return {
		type: ACTION_TYPES.INPUT_RESET_USER_PASSWORD_AGAIN,
		value,
	};
}

export function closeSnackbarModal() {
	return {
		type: ACTION_TYPES.CLOSE_SNACKBAR_MODAL,
	};
}