import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
	svgContainer: {
		textAlign: 'center',
	},
	socioSvg: {
		width: '100%',
		height: 'auto',
		maxHeight: '180px',
	},
	quintilesLine: {
		stroke: (props) => props.colourTheme.defultColour || '#55cae5',
		fill: (props) => props.colourTheme.defultColour || '#55cae5',
	},
	quintilesOuterCircle: {
		fill: (props) => props.colourTheme.socioEconomic.circleColor || '#55cae5',
	},
	quintilesWhiteCircle: {
		fill: '#000',
	},
	quintilesInnerCircle: {
		fill: (props) => props.colourTheme.socioEconomic.dotColor || '#ffffff',
	},
	quintilesLabel: {
		fill: (props) => props.colourTheme.defultColour || '#55cae5',
		fontSize: '18px',
		textAnchor: 'middle',
	},
	quintilesPercentage: {
		fill: (props) => props.colourTheme.defultColour || '#55cae5',
		fontSize: '14px',
		fontWeight: 'bold',
		textAnchor: 'middle',
	},
	quintilesPercentageHide: {
		fill: (props) => props.colourTheme.defultColour || '#55cae5',
		fontSize: '14px',
		fontWeight: 'bold',
		textAnchor: 'middle',
		opacity: 0,
	},
	quintilesPercentagePath: {
		fill: (props) => props.colourTheme.defultColour || '#55cae5',
	},
	quintilesBigPercentageHide: {
		height: '20px',
		opacity: 0,
	},
	quintilesBigPercentageShow: {
		height: '20px',
		opacity: 0.9,
	},
	quintilesBigPercentageText: {
		fontSize: '13px',
		fontWeight: 'bold',
		textAnchor: 'middle',
	},
	quintilesPercentagePathShow: {
		backgroundColor: '#ffffff',
	},
}));
