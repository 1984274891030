import { applyMiddleware, createStore, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import rootReducer from '../reducers';
import rootSaga from '../sagas';

export default function configureStore() {
	const sagaMiddleware = createSagaMiddleware();
	const store = createStore(
		rootReducer,
		compose(applyMiddleware(sagaMiddleware), window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f)
	);
	sagaMiddleware.run(rootSaga);
	return store;
}
