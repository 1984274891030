import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import DetailedSinglePieChart from '../../Charts/DetailedSinglePieChart';
import CustomLoader from '../../CustomLoader';
import ErrorMessageSpan from '../../TextFields/ErrorMessageSpan';
import CustomCard from '../../CustomCard';
import PopulationSummary from '../PopulationSummary';
import SiteDashboardHeader from '../../Headers/SiteDashboardHeader';
import { Colors } from '../../../constants/Constants.json';
import images from '../../Icons';

import useStyles from './styles';

const PropertyOwnershipAndMortgage = (props) => {
	const classes = useStyles(props);

	const { propertyOwnershipDataLoader, propertyOwnershipDataErrorMessage, propertyOwnershipData, monthlyMortgageRepaymentsData } = props;

	return (
		<CustomCard isCentrePage={true}>
			<SiteDashboardHeader
				title='PROPERTY OWNERSHIP AND MORTGAGE REPAYMENTS'
				color={Colors[16]}
				textColor={'#000'}
				isCentrePage={true}
				icon={images.topApps}
			/>
			<div className={classes.paperContainer}>
				{propertyOwnershipDataLoader ? (
					<CustomLoader size={40} />
				) : propertyOwnershipDataErrorMessage ? (
					<ErrorMessageSpan text={propertyOwnershipDataErrorMessage} />
				) : (
					<Grid container spacing={2} justifyContent='center'>
						<Grid item xs={6}>
							<DetailedSinglePieChart
								id='property_ownership_chart'
								data={propertyOwnershipData}
								categoryX='label'
								valueY='count'
								isLegend={true}
								position='right'
								valign='middle'
								contentAlign='center'
								width={150}
								paddingBottom={10}
								paddingLeft={10}
								paddingTop={10}
								legendPaddingLeft={10}
								colors={['#2774AE', '#00C2A2', '#DF4661', '#7FA0AC', '#A7E6D7', '#F1EB9C', '#56B7E6']}
								footerText='Property Ownership'
								isPercent={true}
								showLabelsInside={true}
								showLabels={false}
							/>
						</Grid>
						<Grid item xs={6}>
							<PopulationSummary
								data={[
									{
										category: 'Monthly mortgage repayments',
										count: monthlyMortgageRepaymentsData,
									},
								]}
							/>
						</Grid>
					</Grid>
				)}
			</div>
		</CustomCard>
	);
};

PropertyOwnershipAndMortgage.propTypes = {
	propertyOwnershipDataLoader: PropTypes.bool,
	propertyOwnershipDataErrorMessage: PropTypes.string,
	propertyOwnershipData: PropTypes.array,
	monthlyMortgageRepaymentsData: PropTypes.string,
};

PropertyOwnershipAndMortgage.defaultProps = {
	propertyOwnershipDataLoader: false,
	propertyOwnershipDataErrorMessage: '',
	propertyOwnershipData: [],
	monthlyMortgageRepaymentsData: '',
};

export default PropertyOwnershipAndMortgage;
