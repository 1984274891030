import * as ACTION_TYPES from '../../constants/ActionTypes';

export function getCentreDashboardReport(siteId, startDate, endDate) {
	return {
		type: ACTION_TYPES.CENTRE_DASHBOARD_REPORT,
		siteId,
		startDate,
		endDate,
	};
}

export function getSiteReportMonthlyStatsDone(data) {
	return {
		type: ACTION_TYPES.SITE_REPORT_MONTHLY_STATS_DONE,
		data,
	};
}

export function getSiteReportMonthlyStatsFailed(error) {
	return {
		type: ACTION_TYPES.SITE_REPORT_MONTHLY_STATS_FAILED,
		error,
	};
}

export function getSiteReportVisitsByDay(siteId, startDate, endDate) {
	return {
		type: ACTION_TYPES.SITE_REPORT_VISITS_BY_DAY,
		siteId,
		startDate,
		endDate,
	};
}

export function getSiteReportVisitsByDayDone(data) {
	return {
		type: ACTION_TYPES.SITE_REPORT_VISITS_BY_DAY_DONE,
		data,
	};
}

export function getSiteReportVisitsByDayFailed(error) {
	return {
		type: ACTION_TYPES.SITE_REPORT_VISITS_BY_DAY_FAILED,
		error,
	};
}

export function getSiteReportVisitsByDate(siteId, startDate, endDate) {
	return {
		type: ACTION_TYPES.SITE_REPORT_VISITS_BY_DATE,
		siteId,
		startDate,
		endDate,
	};
}

export function getSiteReportVisitsByDateDone(data) {
	return {
		type: ACTION_TYPES.SITE_REPORT_VISITS_BY_DATE_DONE,
		data,
	};
}

export function getSiteReportVisitsByDateFailed(error) {
	return {
		type: ACTION_TYPES.SITE_REPORT_VISITS_BY_DATE_FAILED,
		error,
	};
}

export function getSiteReportPopularityByDayDone(data) {
	return {
		type: ACTION_TYPES.SITE_REPORT_POPULARITY_BY_DAY_DONE,
		data,
	};
}

export function getSiteReportPopularityByDayFailed(error) {
	return {
		type: ACTION_TYPES.SITE_REPORT_POPULARITY_BY_DAY_FAILED,
		error,
	};
}

export function getSiteReportDemographicsDone(data) {
	return {
		type: ACTION_TYPES.SITE_REPORT_DEMOGRAPHICS_DONE,
		data,
	};
}

export function getSiteReportDemographicsFailed(error) {
	return {
		type: ACTION_TYPES.SITE_REPORT_DEMOGRAPHICS_FAILED,
		error,
	};
}

