export default {
	root: {
		boxShadow: 'none',
		backgroundColor: 'inherit',
		color: '#fff',
	},
	menuItem: {
		fontSize: 13,
		padding: '7px 20px 7px 35px',
		'&:hover': {
			backgroundColor: 'rgba(0, 0, 0, 0.15)',
		},
	},
	userFirstName: {
		color: '#fff',
		fontSize: 14,
		marginBottom: 20,
		lineHeight: '20px',
	},
	menuItemIcon: {
		fontSize: 20,
		marginRight: 10,
	},
};