import React from 'react';
import PageNotFoundTemplate from '../../templates/pageNotFoundTemplate/index.template';

class PageNotFound extends React.Component {
	render() {
		return <PageNotFoundTemplate {...this.props} />;
	}
}

export default PageNotFound;
