export default {
	formControl_autoComplete: {
		width: '230px',
	},
	autocompleteRoot: {
		backgroundColor: '#fff',
	},
	allSites: {
		borderBottom: '1px solid rgba(0,0,0,0.15)',
	},
	placeholder: {
		opacity: 0.5,
		paddingLeft: 32,
	},
	siteName: {
		marginLeft: 5,
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		fontSize: 12,
	},
	wifiIcon: {
		width: 18,
		height: 18,
		color: '#0088f3',
		marginTop: 'auto',
		marginBottom: 'auto',
		display: 'block',
	},
	wifiIconBlue: {
		width: 18,
		height: 18,
		color: '#2774AE',
		marginTop: 'auto',
		marginBottom: 'auto',
	},
	wifiOffIcon: {
		width: 18,
		height: 18,
		color: '#ddd',
		marginTop: 'auto',
		marginBottom: 'auto',
	},
	popupIndicator: {
		padding: 0,
	},
	noOptions: {
		color: '#2774AE',
		fontSize: 13,
	},
	listbox: {
		maxHeight: '80vh',
		width: '100%',
	},
	groupLabel: {
		fontSize: 15,
	},
};
