import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { amchartsStyle } from '../../../constants/Theme';

am4core.useTheme(am4themes_animated);

class DemographicsAmchartsBarChart extends React.Component {
	componentDidMount() {
		const {
			id,
			data,
			title,
			valueY,
			category,
			valueY01,
			numberFormat = '#.#s',
			tooltipText = '{name}: {categoryX}: [bold]{valueY}[/]',
			labelText = '{valueY}',
			color,
			color01,
			paddingTop,
			isCentrePage = false,
		} = this.props;

		let chart = am4core.create(`chart-${id}`, am4charts.XYChart);

		// Add data
		chart.data = data;
		chart.paddingRight = 20;

		if (paddingTop) {
			chart.paddingTop = paddingTop;
		}

		chart.numberFormatter.numberFormat = numberFormat;

		let categoryAxis = null;
		let valueAxis = null;

		// Create axes
		categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
		categoryAxis.dataFields.category = category;
		categoryAxis.renderer.grid.template.location = 0;
		categoryAxis.renderer.minGridDistance = 30;
		categoryAxis.renderer.labels.template.horizontalCenter = 'center';
		categoryAxis.renderer.labels.template.verticalCenter = 'middle';
		categoryAxis.renderer.labels.template.fontSize = amchartsStyle.labelsFontSize;

		valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
		valueAxis.title.text = title;
		valueAxis.title.fill = '#6a6564';
		valueAxis.renderer.labels.template.fontSize = amchartsStyle.labelsFontSize;
		valueAxis.strictMinMax = true;
		// Create series
		let series = chart.series.push(new am4charts.ColumnSeries());
		series.dataFields.valueY = valueY;
		series.dataFields.categoryX = category;
		series.name = valueY;
		series.columns.template.width = am4core.percent(60);
		series.clustered = false;
		series.tooltip.fontSize = amchartsStyle.tooltipFontSize;
		series.columns.template.fill = color;
		series.columns.template.strokeWidth = 0;
		series.columns.template.tooltipText = tooltipText;

		let bullet = series.bullets.push(new am4charts.LabelBullet());
		bullet.interactionsEnabled = false;
		bullet.label.text = labelText;
		bullet.label.fill = am4core.color('#ffffff');
		bullet.label.fontSize = amchartsStyle.labelsFontSize;

		let series01 = chart.series.push(new am4charts.ColumnSeries());
		series01.dataFields.valueY = valueY01;
		series01.dataFields.categoryX = category;
		series01.name = valueY01;
		series01.columns.template.width = am4core.percent(60);
		series01.clustered = false;
		series01.tooltip.fontSize = amchartsStyle.tooltipFontSize;
		series01.columns.template.fill = color01;
		series01.columns.template.strokeWidth = 0;
		series01.columns.template.tooltipText = tooltipText;

		let bullet01 = series01.bullets.push(new am4charts.LabelBullet());
		bullet01.interactionsEnabled = false;
		bullet01.label.text = labelText;
		bullet01.label.fill = am4core.color('#ffffff');
		bullet01.label.fontSize = amchartsStyle.labelsFontSize;

		if (isCentrePage) {
			// adds extra space top and bottom
			valueAxis.extraMax = 0.25;
			valueAxis.extraMin = 0.25;

			categoryAxis.renderer.labels.template.horizontalCenter = 'middle';

			// bullets to be on top of the bars
			bullet.label.dy = -2;
			bullet.label.verticalCenter = 'bottom';
			bullet01.label.dy = 2;
			bullet01.label.verticalCenter = 'top';

			// label font width
			bullet.label.fill = bullet01.label.fill = '#000';
			bullet.label.x = 0;

			// changes axes colour
			categoryAxis.renderer.labels.template.fill = valueAxis.renderer.labels.template.fill = '#716E6A';

			// disables vertical grid lines
			categoryAxis.renderer.grid.template.disabled = true;
			valueAxis.renderer.grid.template.disabled = true;

			// adds border radius to bars
			series.columns.template.column.cornerRadiusTopLeft = 6;
			series.columns.template.column.cornerRadiusTopRight = 6;
			series01.columns.template.column.cornerRadiusBottomLeft = 6;
			series01.columns.template.column.cornerRadiusBottomRight = 6;
		} else {
			bullet.locationY = bullet01.locationY = 0.5;
			series.columns.template.events.on('sizechanged', function (ev) {
				if (ev.target.dataItem && ev.target.dataItem.bullets) {
					var height = ev.target.pixelHeight;
					ev.target.dataItem.bullets.each(function (id, bullet) {
						if (height > 10) {
							bullet.show();
						} else {
							bullet.hide();
						}
					});
				}
			});
			series01.columns.template.events.on('sizechanged', function (ev) {
				if (ev.target.dataItem && ev.target.dataItem.bullets) {
					var height = ev.target.pixelHeight;
					ev.target.dataItem.bullets.each(function (id, bullet) {
						if (height > 10) {
							bullet.show();
						} else {
							bullet.hide();
						}
					});
				}
			});
		}

		// Add legend
		chart.legend = new am4charts.Legend();
		chart.legend.fontSize = amchartsStyle.legendFontSize;
		chart.legend.maxHeight = 20;
		chart.legend.labels.template.text = '{name}[/]';
		var markerTemplate = chart.legend.markers.template;
		markerTemplate.width = amchartsStyle.legendWidthHeight;
		markerTemplate.height = amchartsStyle.legendWidthHeight;

		this.chart = chart;
	}
	componentDidUpdate(prevProps) {
		const { data } = this.props;
		if (data.length > 0 && !isEqual(prevProps.data, data)) {
			this.chart.data = data;
		}
	}
	componentWillUnmount() {
		if (this.chart) {
			this.chart.dispose();
		}
	}
	render() {
		const { id } = this.props;
		return <div id={`chart-${id}`} style={{ width: '100%', height: '100%' }} data-testid='demo-graphics-amcharts-bar-chart'></div>;
	}
}

DemographicsAmchartsBarChart.propTypes = {
	id: PropTypes.string,
	data: PropTypes.array,
	title: PropTypes.string,
	valueY: PropTypes.string,
	category: PropTypes.string,
	valueY01: PropTypes.string,
	numberFormat: PropTypes.string,
	tooltipText: PropTypes.string,
	labelText: PropTypes.string,
	color: PropTypes.string,
	color01: PropTypes.string,
	paddingTop: PropTypes.number,
	isCentrePage: PropTypes.bool,
};

DemographicsAmchartsBarChart.defaultProps = {
	id: '',
	data: [],
	title: '',
	valueY: '',
	category: '',
	valueY01: '',
	numberFormat: '#.#s',
	tooltipText: '{name}: {categoryX}: [bold]{valueY}[/]',
	labelText: '',
	color: '',
	color01: '',
	paddingTop: 0,
	isCentrePage: false,
};

export default DemographicsAmchartsBarChart;
