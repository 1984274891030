import activeClients from './activeClients.svg';
import answers from './answers.svg';
import audiences from './audiences.svg';
import audiencesGrey from './audiencesGrey.svg';
import calendarDay from './calendar-day.svg';
import calendarMonth from './calendar-month.svg';
import camera from './camera.svg';
import date from './date.svg';
import day from './day.svg';
import emails from './emails.svg';
import exportSvg from './export.svg';
import gender from './gender.svg';
import location from './location.svg';
import loginBackground from './login-background.jpg';
import logins from './logins.svg';
import magnifier from './magnifier.svg';
import menuBkg from './menuBkg.jpg';
import metrixLogo from './metrix-logo.png';
import newUsers from './newUsers.svg';
import peak from './peak.svg';
import player from './player.svg';
import shopperLogo from './shopper-logo.png';
import topApps from './topApps.svg';
import up from './up.svg';
import wifi from './wifi.svg';

export default {
	activeClients,
	answers,
	audiences,
	audiencesGrey,
	calendarDay,
	calendarMonth,
	camera,
	date,
	day,
	emails,
	exportSvg,
	gender,
	location,
	loginBackground,
	logins,
	magnifier,
	menuBkg,
	metrixLogo,
	newUsers,
	peak,
	player,
	shopperLogo,
	topApps,
	up,
	wifi,
};
