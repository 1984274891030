import { combineReducers } from 'redux';
import authReducer from './authReducer';
import appReducer from './appReducer';
import sitesReducer from './sitesReducer';
import contactsReducer from './contactsReducer';
import censusDataReducer from './censusDataReducer';
import personasReducer from './personasReducer';
import communitiesReducer from './communitiesReducer';

const smgReducer = combineReducers({
	authReducer,
	appReducer,
	sitesReducer,
	contactsReducer,
	censusDataReducer,
	personasReducer,
	communitiesReducer,
});

const rootReducer = (state, action) => {
	if (action.type === 'LOGOUT_SUCCESS') {
		state = undefined;
	}
	return smgReducer(state, action);
};

export default rootReducer;
