import React from 'react';
import PropTypes from 'prop-types';
import Accordion from '@material-ui/core/Accordion';
import { withStyles } from '@material-ui/core/styles';
import useStyles, { styles } from './styles';

const CustomExpansionPanel = withStyles(styles)(Accordion);

const ExpansionPanel = (props) => {
	const { expanded, onChange, onClick, square, children } = props;
	const classes = useStyles(props);
	return (
		<CustomExpansionPanel
			className={classes.root}
			expanded={expanded}
			onChange={onChange}
			onClick={onClick}
			square={square}
			data-testid='expansion-panel'
			data-expanded={expanded}
			data-square={square}
		>
			{children}
		</CustomExpansionPanel>
	);
};

ExpansionPanel.propTypes = {
	expanded: PropTypes.bool,
	onChange: PropTypes.func,
	onClick: PropTypes.func,
	square: PropTypes.bool,
};

ExpansionPanel.defaultProps = {
	expanded: false,
	onChange: null,
	onClick: null,
	square: false,
};

export default ExpansionPanel;
