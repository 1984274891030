import { makeStyles } from '@material-ui/styles';

export default makeStyles({
	statTitle: {
		fontSize: '16px',
		fontWeight: '600',
		textTransform: 'uppercase',
		color: '#ffffff',
		marginBottom: '20px',
	},
	socioEconomicContainer: {
		margin: '20px 0px 0px 0px',
	},
	socioEconomicTitle: {
		padding: '0px 0px 20px 0px',
	},
	socioEconomicChart: {
		justifyContent: 'center',
	},
});
