import React from 'react';
import UserPreferencePageContainer from '../../containers/userPreference/userPreferencePageContainer';

class UserPreferencePage extends React.Component {
	render() {
		return <UserPreferencePageContainer {...this.props} />;
	}
}

export default UserPreferencePage;
