import internalAxiosInstance from '../axios/internalAxiosInstance';
import { responseHandler, errorHandler } from './utility';

export function getUserPermissionsByUserName(username) {
    return internalAxiosInstance.get('/auth/permissions?user_id='+username)
        .then(response => {
            return responseHandler(response);
        })
        .catch(err => {
            console.log(err);
            return errorHandler(err)
        });
}

export function authenticateUser(username, password) {
    return internalAxiosInstance.post('/auth/authenticate', {
            "username": username,
            "password": password
        })
        .then(response => {
            return responseHandler(response);
        })
        .catch(err => {
            console.log(err);
            return errorHandler(err)
        });
}

export function fetchOwnerUserPreferences() {
    return internalAxiosInstance.get('/auth/users?query=userprofile')
        .then(response => {
            return responseHandler(response);
        })
        .catch(err => {
            console.log(err);
            return errorHandler(err)
        });
}

export function resetUserPassword(userId, newPassword) {
    return internalAxiosInstance.patch('/auth/resetpwd/'+userId, {
            "password": newPassword
        })
        .then(response => {
            return responseHandler(response);
        })
        .catch(err => {
            console.log(err);
            return errorHandler(err)
        });
}




