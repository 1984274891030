import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import useStyles from './styles';

const Progressiveness = (props) => {
	const { data } = props;

	const classes = useStyles(props);

	return (
		<div className={classNames(classes.statComponent)}>
			<h3 className={classNames(classes.statTitle)}>{data.headingText}</h3>
			<div className={classNames(classes.progChart)}>
				<div className={classNames(classes.progChartScale)}></div>
				<span className={classNames(classes.progChartMarker)}></span>
				<div className={classNames(classes.progChartLabels)}>
					<span>{data.data.leftText}</span>
					<span>{data.data.rightText}</span>
				</div>
			</div>
		</div>
	);
};

Progressiveness.propTypes = {
	data: PropTypes.shape({
		headingText: PropTypes.string,
		data: PropTypes.shape({
			midpoint: PropTypes.string,
			leftText: PropTypes.string,
			rightText: PropTypes.string,
		}),
	}),
	colourTheme: PropTypes.object,
};

Progressiveness.defaultProps = {
	data: {
		headingText: '',
		data: {
			midpoint: '',
			leftText: '',
			rightText: '',
		},
	},
	colourTheme: null,
};

export default Progressiveness;
