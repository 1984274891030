import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
	techAdoptionContainer: {
		textAlign: 'center',
	},
	techAdoptionPath: {
		fill: '#d3d3d3',
		fillOpacity: '1',
	},
	techAdoptionPathMax: {
		fill: (props) => props.colourTheme.defultColour || '#4cc7e3',
		fillOpacity: '0.67',
	},
	techAdoptionPathSpace: {
		fill: 'rgb(205, 205, 205, 1)',
	},
	techAdoptionCategory: {
		fontSize: '35px',
		strokeWidth: '0',
		textAnchor: 'middle',
		fill: 'rgb(39, 37, 31)',
		fontWeight: 'bold',
	},
	techAdoptionLabel: {
		fontSize: '33px',
		strokeWidth: '0',
		textAnchor: 'middle',
		fill: 'rgb(85, 85, 85)',
	},
	techAdoptionValue: {
		fontSize: '40px',
		fontWeight: '600',
		strokeWidth: '0',
		textAnchor: 'middle',
		fill: 'rgb(85, 85, 85)',
	},
	techAdoptionValueMax: {
		fontSize: '40px',
		fontWeight: '600',
		strokeWidth: '0',
		textAnchor: 'middle',
		fill: '#fff!important',
	},
	techAdoptionCategoryValue: {
		fontSize: '32px',
		strokeWidth: '0',
		textAnchor: 'middle',
		fill: 'rgb(85, 85, 85)',
	},
}));
