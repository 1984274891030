import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import classNames from 'classnames/bind';
import styles from './styles.scss';

const cx = classNames.bind(styles);

class CustomModal extends React.PureComponent {
	render() {
		const {
			open,
			loader,
			title,
			saveAction,
			cancelAction,
			buttonName = 'Save',
			errorMessage,
			isCreate,
			removeErrorMessageAction,
			nopadding = false,
			hideIcon = false,
			hideHeader = false,
			hideSaveButton = false,
			customModalContentMainFixedHeight = false,
			cancelButtonName = 'Cancel',
			children,
		} = this.props;
		return (
			<Modal
				open={open}
				className={cx('customFullModal')}
				aria-labelledby='simple-modal-title'
				aria-describedby='simple-modal-description'
				data-testid='custom-modal'
				data-open={open}
			>
				<div className={customModalContentMainFixedHeight ? cx('customModalContentDynamic') : cx('customModalContent')}>
					{!hideHeader && (
						<div className={cx('modalTitle')} data-testid='custom-modal-title'>
							{!hideIcon &&
								(isCreate ? (
									<AddIcon className={cx('modalEditIcon')} data-testid='custom-modal-add-icon' />
								) : (
									<EditIcon className={cx('modalEditIcon')} data-testid='custom-modal-edit-icon' />
								))}
							<span>{title}</span>
							{loader && <div className={cx('loader')} data-testid='custom-modal-loader' />}
						</div>
					)}
					<div className={cx('customModalContentMain', { nopadding, customModalContentMainFixedHeight })} data-testid='custom-modal-children'>
						{children}
					</div>
					<div className={cx('actionWrapper')}>
						{errorMessage && (
							<div className={cx('errorMessage')} data-testid='custom-modal-error-message'>
								<span>{errorMessage}</span>
								<CloseIcon
									className={cx('closeErrorMessageIcon')}
									onClick={() => {
										if (typeof removeErrorMessageAction === 'function') {
											removeErrorMessageAction();
										}
									}}
								/>
							</div>
						)}
						<div className={cx('actionButtons')} data-testid='custom-modal-action'>
							{!hideSaveButton && (
								<Button
									variant='outlined'
									disabled={typeof saveAction !== 'function'}
									className={cx('constantButton')}
									onClick={saveAction}
									data-testid='custom-modal-save-button'
								>
									{buttonName}
								</Button>
							)}
							<Button variant='outlined' className={cx('constantButton')} onClick={cancelAction} data-testid='custom-modal-cancel-button'>
								{cancelButtonName}
							</Button>
						</div>
					</div>
				</div>
			</Modal>
		);
	}
}

CustomModal.propTypes = {
	open: PropTypes.bool,
	loader: PropTypes.bool,
	title: PropTypes.string,
	saveAction: PropTypes.func,
	cancelAction: PropTypes.func,
	buttonName: PropTypes.string,
	errorMessage: PropTypes.string,
	isCreate: PropTypes.bool,
	removeErrorMessageAction: PropTypes.func,
	nopadding: PropTypes.bool,
	hideIcon: PropTypes.bool,
	hideHeader: PropTypes.bool,
	hideSaveButton: PropTypes.bool,
	customModalContentMainFixedHeight: PropTypes.bool,
	cancelButtonName: PropTypes.string,
	children: PropTypes.any,
};

CustomModal.defaultProps = {
	open: false,
	loader: false,
	title: '',
	saveAction: null,
	cancelAction: null,
	buttonName: 'Save',
	errorMessage: '',
	isCreate: false,
	removeErrorMessageAction: null,
	nopadding: false,
	hideIcon: false,
	hideHeader: false,
	hideSaveButton: false,
	customModalContentMainFixedHeight: false,
	cancelButtonName: 'Cancel',
	children: null,
};

export default CustomModal;
