import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { amchartsStyle } from '../../../constants/Theme';

am4core.useTheme(am4themes_animated);

let timeout = null;

class ColumnBarChart extends React.Component {
	componentDidMount() {
		const {
			id,
			data,
			valueYText,
			valueY,
			category,
			rotation = 0,
			height = 'auto',
			color = '#2774AE',
			isLegend = false,
			isBullet = false,
			paddingRight = 20,
			opposite = false,
			paddingTop = 10,
			paddingBottom = 10,
			showOnInit = true,
			barWidth = 60,
			isCentrePage = false,
			retrieveImageData,
			tooltipText,
			labelMaxWidth,
		} = this.props;

		const chart = am4core.create(`chart-${id}`, am4charts.XYChart);

		// Add data
		chart.data = data;
		chart.paddingRight = paddingRight;
		chart.paddingTop = paddingTop;
		chart.paddingBottom = paddingBottom;

		// Create axes
		const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());

		categoryAxis.dataFields.category = category;
		categoryAxis.renderer.grid.template.location = 0;
		categoryAxis.renderer.minGridDistance = 30;
		if (rotation) {
			categoryAxis.renderer.labels.template.horizontalCenter = 'center';
			categoryAxis.renderer.labels.template.verticalCenter = 'middle';
		}

		categoryAxis.renderer.labels.template.rotation = rotation;
		categoryAxis.renderer.labels.template.fontSize = 11;

		categoryAxis.renderer.height = height;
		categoryAxis.tooltip.disabled = true;

		if (labelMaxWidth) {
			categoryAxis.renderer.labels.template.truncate = true;
			categoryAxis.renderer.labels.template.maxWidth = labelMaxWidth;

			categoryAxis.events.on('sizechanged', function (ev) {
				var axis = ev.target;
				var cellWidth = axis.pixelWidth / (axis.endIndex - axis.startIndex);
				axis.renderer.labels.template.maxWidth = cellWidth;
			});
		}

		const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
		valueAxis.title.text = valueYText;
		valueAxis.title.fill = '#6a6564';
		valueAxis.renderer.labels.template.fontSize = 11;
		valueAxis.min = 0;
		valueAxis.strictMinMax = true;
		valueAxis.tooltip.fontSize = amchartsStyle.tooltipFontSize;
		valueAxis.renderer.opposite = opposite;

		// Create series
		const series = chart.series.push(new am4charts.ColumnSeries());
		series.dataFields.valueY = valueY;
		series.dataFields.categoryX = category;
		series.columns.template.width = am4core.percent(barWidth);
		series.clustered = false;
		series.columns.template.fill = color;
		series.columns.template.strokeWidth = 0;
		series.tooltip.fontSize = amchartsStyle.tooltipFontSize;
		series.name = valueYText;
		series.columns.template.tooltipText = tooltipText;
		series.showOnInit = showOnInit;

		const bullet = series.bullets.push(new am4charts.LabelBullet());
		bullet.label.text = '{valueY}';
		bullet.label.fontSize = 11;
		if (!isCentrePage) {
			bullet.locationY = 0.5;
		}

		if (isBullet) {
			bullet.disabled = false;
		} else {
			bullet.disabled = true;
		}

		if (isLegend) {
			chart.legend = new am4charts.Legend();
			chart.legend.fontSize = 11;
			const markerTemplate = chart.legend.markers.template;
			markerTemplate.width = 8;
			markerTemplate.height = 8;
		}

		if (isCentrePage) {
			// label font width
			bullet.label.fill = '#000';

			// adds extra space top and bottom
			valueAxis.extraMax = 0.25;

			// bullets to be on top of the bars
			bullet.label.verticalCenter = 'bottom';
			bullet.label.dy = -2;

			// changes axes colour
			categoryAxis.renderer.labels.template.fill = valueAxis.renderer.labels.template.fill = '#716E6A';

			// centres label to middle
			categoryAxis.renderer.labels.template.horizontalCenter = 'middle';

			// disables vertical grid lines
			categoryAxis.renderer.grid.template.disabled = true;
			valueAxis.renderer.grid.template.disabled = true;

			// adds border radius to bars
			series.columns.template.column.cornerRadiusTopLeft = 6;
			series.columns.template.column.cornerRadiusTopRight = 6;
		}

		chart.cursor = new am4charts.XYCursor();
		chart.cursor.lineX.opacity = 0;

		chart.cursor = new am4charts.XYCursor();
		chart.cursor.lineX.opacity = 0;

		this.chart = chart;
		this.bullet = bullet;

		if (typeof retrieveImageData === 'function') {
			chart.events.on('ready', () => {
				timeout = setTimeout(() => {
					this.exportPNG();
				}, 500);
			});
		}
	}

	componentDidUpdate(prevProps) {
		const { data, isBullet } = this.props;
		if (data.length > 0 && !isEqual(prevProps.data, data)) {
			this.chart.data = data;
		}

		if (isBullet !== prevProps.isBullet) {
			this.bullet.disabled = false;
			if (isBullet) {
				this.bullet.show();
			} else {
				this.bullet.hide();
			}
		}
	}

	componentWillUnmount() {
		if (this.chart) {
			this.chart.dispose();
			clearTimeout(timeout);
		}
	}

	exportPNG = () => {
		const { retrieveImageData } = this.props;
		this.chart.exporting.getImage('png').then((imgData) => {
			retrieveImageData(imgData);
		});
	};

	render() {
		const { id } = this.props;
		return <div id={`chart-${id}`} style={{ width: '100%', height: '100%' }} data-testid='column-bar-chart' />;
	}
}

ColumnBarChart.propTypes = {
	data: PropTypes.array,
	category: PropTypes.string,
	axisColor: PropTypes.string,
	isCentrePage: PropTypes.bool,
	id: PropTypes.string,
	valueYText: PropTypes.string,
	valueY: PropTypes.string,
	rotation: PropTypes.number,
	height: PropTypes.string,
	color: PropTypes.string,
	isLegend: PropTypes.bool,
	isBullet: PropTypes.bool,
	paddingRight: PropTypes.number,
	opposite: PropTypes.bool,
	paddingTop: PropTypes.number,
	paddingBottom: PropTypes.number,
	showOnInit: PropTypes.bool,
	barWidth: PropTypes.number,
	retrieveImageData: PropTypes.func,
	tooltipText: PropTypes.string,
	labelMaxWidth: PropTypes.number,
};

ColumnBarChart.defaultProps = {
	data: [],
	category: '',
	axisColor: '#000',
	isCentrePage: false,
	id: '',
	valueYText: '',
	valueY: '',
	rotation: 0,
	height: 'auto',
	color: '#2774AE',
	isLegend: false,
	isBullet: false,
	paddingRight: 20,
	opposite: false,
	paddingTop: 10,
	paddingBottom: 10,
	showOnInit: true,
	barWidth: 60,
	retrieveImageData: null,
	tooltipText: '{categoryX}: [bold]{valueY}[/]',
	labelMaxWidth: 0,
};

export default ColumnBarChart;
