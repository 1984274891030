import React from 'react';
import images from '../../Icons';
import classNames from 'classnames/bind';
import styles from './index.scss';

const cx = classNames.bind(styles);

const LogoHeader = () => {
	return (
		<div className={cx('root')} data-testid='logo-header'>
			<div className={cx('logoInsideWrapper')}>
				<img src={images.shopperLogo} alt='shopper metrix logo' className={cx('shopperLogo')} />
			</div>
		</div>
	);
};

export default LogoHeader;
