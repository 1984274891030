import React from 'react';
import PropTypes from 'prop-types';
import SingleUserOverviewTemplate from '../userOverviewTemplate/index.template';
import CustomSnackbarModal from '../../components/Modals/CustomSnackbarModal';
import classNames from 'classnames/bind';
import styles from './styles.scss';
import ResetPasswordModalTemplate from '../resetPasswordModalTemplate/index.template';

const cx = classNames.bind(styles);

class UserPreferenceTemplate extends React.Component {
	render() {
		const {
			userName,
			userLastName,
			userFirstName,
			userPassword,
			userEmail,
			userAyudaName,
			userId,
			handleToggle,
			handleResetPassword,
			toggleResetPswModal,
			userNewPassword,
			userNewPasswordAgain,
			inputUserNewPasswordAgain,
			inputUserNewPassword,
			resetUserPasswordFailed,
			resetLoader,
			preferenceErrorMessage,
			handleSave,
			handleCancel,
			snackbarOpen,
			handleCloseAction,
		} = this.props;

		return (
			<div className={cx('preferencesWrapper')}>
				<div className={cx('singleUserWrapper')}>
					<SingleUserOverviewTemplate
						fullName={userName}
						firstName={userFirstName}
						lastName={userLastName}
						email={userEmail}
						password={userPassword}
						preferredName={userAyudaName}
						toggleResetPasswordModal={handleToggle}
					/>
				</div>
				<ResetPasswordModalTemplate
					userId={userId}
					userNewPassword={userNewPassword}
					userNewPasswordAgain={userNewPasswordAgain}
					resetUserPasswordFailed={resetUserPasswordFailed}
					resetLoader={resetLoader}
					preferenceErrorMessage={preferenceErrorMessage}
					handleResetPassword={handleResetPassword}
					toggleResetPswModal={toggleResetPswModal}
					inputUserNewPasswordAgain={inputUserNewPasswordAgain}
					inputUserNewPassword={inputUserNewPassword}
					handleSave={handleSave}
					handleCancel={handleCancel}
				/>
				<CustomSnackbarModal open={snackbarOpen} handleClose={handleCloseAction} message='Password successfully updated' />
			</div>
		);
	}
}

UserPreferenceTemplate.propTypes = {
	fullName: PropTypes.string,
	firstName: PropTypes.string,
	lastName: PropTypes.string,
	email: PropTypes.string,
	password: PropTypes.string,
	preferredName: PropTypes.string,
	toggleResetPasswordModal: PropTypes.func,
	userId: PropTypes.string,
	userNewPassword: PropTypes.string,
	userNewPasswordAgain: PropTypes.string,
	resetUserPasswordFailed: PropTypes.func,
	resetLoader: PropTypes.bool,
	preferenceErrorMessage: PropTypes.string,
	handleResetPassword: PropTypes.func,
	toggleResetPswModal: PropTypes.bool,
	inputUserNewPasswordAgain: PropTypes.func,
	inputUserNewPassword: PropTypes.func,
	handleSave: PropTypes.func,
	handleCancel: PropTypes.func,
};

UserPreferenceTemplate.defaultProps = {
	fullName: '',
	firstName: '',
	lastName: '',
	email: '',
	password: '',
	preferredName: '',
	toggleResetPasswordModal: null,
	userId: '',
	userNewPassword: '',
	userNewPasswordAgain: '',
	resetUserPasswordFailed: null,
	resetLoader: false,
	preferenceErrorMessage: '',
	handleResetPassword: null,
	toggleResetPswModal: false,
	inputUserNewPasswordAgain: null,
	inputUserNewPassword: null,
	handleSave: null,
	handleCancel: null,
};

export default UserPreferenceTemplate;
