import { makeStyles } from '@material-ui/styles';

export default makeStyles({
	header: {
		margin: '0px 0px 50px 20px',
	},
	headerText: {
		fontSize: '30px',
		fontWeight: 'bold',
	},
	dominantSection: {
		margin: '0px 50px 50px 20px',
	},
	dominantText: {
		fontSize: '22px',
		fontWeight: 'bold',
		color: '#000000',
	},
	textSpace: {
		marginTop: '10px',
	},
});
