import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import DemographicsItem from '../DemographicsItem';

import useStyles from './styles';

const Demographics = (props) => {
	const classes = useStyles(props);

	const { demographics, colourTheme } = props;

	return (
		<div className={classes.section}>
			<Grid item xs={12}>
				<h3 className={classes.statTitle}>{demographics.headingText}</h3>
			</Grid>
			<Grid item container xs={12}>
				{demographics.data.map((demographicsItem, index) => {
					return <DemographicsItem demographicsItem={demographicsItem} colourTheme={colourTheme} key={`demographics-item-${index}`} />;
				})}
			</Grid>
		</div>
	);
};

Demographics.propTypes = {
	demographics: PropTypes.shape({
		headingText: PropTypes.string,
		demographics: PropTypes.array,
	}),
	colourTheme: PropTypes.object,
};

Demographics.defaultProps = {
	demographics: {
		headingText: '',
		demographics: [],
	},
	colourTheme: null,
};

export default Demographics;
