import { all } from 'redux-saga/effects';
import authSaga from './authSaga';
import sitesSaga from './sitesSaga';
import contactsSaga from './contactsSaga';
import censusDataSaga from './censusDataSaga';
import personasSaga from './personasSaga';
import communitiesSaga from './communitiesSaga';

export default function* rootSaga() {
	yield all([authSaga(), sitesSaga(), contactsSaga(), censusDataSaga(), personasSaga(), communitiesSaga()]);
}
