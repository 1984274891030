import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import useStyles from './styles';

const PageNotFoundTemplate = (props) => {
	const classes = useStyles();
	const { url } = props;
	return (
		<div className={classes.page}>
			<div className={classes.inside} data-testid='page-not-found-template'>
				<h1 className={classes.notFoundTitle} data-testid='page-not-found-template-title'>
					Oops! That page can’t be found.
				</h1>
				<p className={classes.notFoundDesc} data-testid='page-not-found-template-p'>
					It looks like nothing was found at this location. Maybe press
					<Link to={url ? url : '/sign_in'} data-testid='page-not-found-template-link'>
						back
					</Link>
					to go to the previous page.
				</p>
			</div>
		</div>
	);
};

PageNotFoundTemplate.propTypes = {
	url: PropTypes.string,
};

PageNotFoundTemplate.defaultProps = {
	url: '/sign_in',
};

export default PageNotFoundTemplate;
