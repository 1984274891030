import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
	mainContainer: {
		display: 'flex',
		flexDirection: 'column',
		maxWidth: '100%',
		borderLeft: '1px solid #000000',
		borderBottom: '1px solid #000000',
		borderRight: '1px solid #000000',
		borderRadius: '5px',
		margin: '1em',
		[theme.breakpoints.down('sm')]: {
			minWidth: '630px',
		},
	},
	childrenContainer: {
		padding: '1em',
		display: 'flex',
		flexDirection: 'row',
	},
	header: {
		display: 'flex',
		flexDirection: 'row',
		width: '100% !important',
	},
	headerBorderBefore: {
		borderTop: '1px solid #000000',
		width: '1em',
		borderTopLeftRadius: '5px',
	},
	headerTitle: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'nowrap',
		alignItems: 'center',
		gap: '0.25em',
		width: 'fit-content',
		height: '2em',
		margin: '-1em 0.5em 0em 0.5em',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		fontSize: '16px',
		fontWeight: '600',
		textTransform: 'uppercase',
		color: '#000000',
	},
	headerBorderAfter: {
		borderTop: '1px solid #000000',
		width: '1em',
		flexGrow: '2',
		borderTopRightRadius: '5px',
	},
}));
