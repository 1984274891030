import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import DetailedSinglePieChart from '../../Charts/DetailedSinglePieChart';
import CustomLoader from '../../CustomLoader';
import ErrorMessageSpan from '../../TextFields/ErrorMessageSpan';
import CustomCard from '../../CustomCard';
import DetailedColumnBarChart from '../../Charts/DetailedColumnBarChart';
import SiteDashboardHeader from '../../Headers/SiteDashboardHeader';
import { Colors } from '../../../constants/Constants.json';
import images from '../../Icons';

import useStyles from './styles';

const MaritalStatusAndFamilyTypes = (props) => {
	const classes = useStyles(props);

	const { maritalStatusAndFamilyTypesDataLoader, maritalStatusAndFamilyTypesDataErrorMessage, maritalStatusData, familyTypesData } = props;

	return (
		<CustomCard isCentrePage={true}>
			<SiteDashboardHeader title='MARITAL STATUS AND FAMILY TYPES' color={Colors[16]} textColor={'#000'} isCentrePage={true} icon={images.topApps} />
			<div className={classes.paperContainer}>
				{maritalStatusAndFamilyTypesDataLoader ? (
					<CustomLoader size={40} />
				) : maritalStatusAndFamilyTypesDataErrorMessage ? (
					<ErrorMessageSpan text={maritalStatusAndFamilyTypesDataErrorMessage} />
				) : (
					<Grid container spacing={2} justifyContent='center'>
						<Grid item xs={6}>
							<DetailedSinglePieChart
								id='marital_status_chart'
								data={maritalStatusData}
								categoryX='label'
								valueY='count'
								isLegend={true}
								position='right'
								valign='middle'
								contentAlign='center'
								width={120}
								paddingBottom={10}
								paddingLeft={10}
								paddingTop={10}
								legendPaddingLeft={10}
								colors={['#2774AE', '#00C2A2', '#56B7E6', '#DF4661', '#7FA0AC', '#A7E6D7', '#F1EB9C', '#FFC915']}
								footerText='Marital Status'
								isPercent={true}
								showLabelsInside={true}
								showLabels={false}
							/>
						</Grid>
						<Grid item xs={6}>
							<DetailedColumnBarChart
								id='family_types_chart'
								data={familyTypesData}
								category='label'
								paddingTop={20}
								paddingRight={20}
								paddingBottom={0}
								barWidth={80}
								isCentrePage={true}
								valueY='count'
								color={Colors[10]}
								footerText='Family Types'
								tooltipText='{categoryX}: [bold]{valueY}[/]'
								rotation={0}
								isBullet={true}
								labelMaxWidth={80}
							/>
						</Grid>
					</Grid>
				)}
			</div>
		</CustomCard>
	);
};

MaritalStatusAndFamilyTypes.propTypes = {
	maritalStatusAndFamilyTypesDataLoader: PropTypes.bool,
	maritalStatusAndFamilyTypesDataErrorMessage: PropTypes.string,
	maritalStatusData: PropTypes.array,
	familyTypesData: PropTypes.array,
};

MaritalStatusAndFamilyTypes.defaultProps = {
	maritalStatusAndFamilyTypesDataLoader: false,
	maritalStatusAndFamilyTypesDataErrorMessage: '',
	maritalStatusData: [],
	familyTypesData: [],
};

export default MaritalStatusAndFamilyTypes;
