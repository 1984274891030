import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { List } from 'immutable';
import HyveCard from '../../CustomCard';
import CustomLoader from '../../CustomLoader';
import ErrorMessageSpan from '../../TextFields/ErrorMessageSpan';
import { calculateCentrePageSummaryData } from '../../../helpers/utility';
import styles from './styles.scss';

const cx = classNames.bind(styles);

class CentrePageSummary extends React.Component {
	constructor(props) {
		super(props);
		this.wrapperRef = React.createRef();
		this.state = {
			totalVisitors: 0,
			avgDailyVisitors: 0,
			totalWiFiUsers: 0,
			avgDailyWiFiUsers: 0,
		};
	}

	componentDidMount() {
		const { demographics, visitorsData } = this.props;
		if (visitorsData && demographics && visitorsData.length > 0 && demographics.size > 0) {
			const centrePageSummaryData = calculateCentrePageSummaryData(demographics, visitorsData);
			this.setState(centrePageSummaryData);
		}
	}

	componentDidUpdate(prevProps) {
		const { demographics, visitorsData } = this.props;
		if (
			(prevProps.demographics !== demographics || prevProps.visitorsData !== visitorsData) &&
			visitorsData.length > 0 &&
			demographics &&
			demographics.size > 0
		) {
			const centrePageSummaryData = calculateCentrePageSummaryData(demographics, visitorsData);
			this.setState(centrePageSummaryData);
		}
	}

	render() {
		const { RVBDateLoader, errorMessageRVBDate, errorMessageDemographics, DemographicsLoader } = this.props;
		const { totalVisitors, avgDailyVisitors, totalWiFiUsers, avgDailyWiFiUsers } = this.state;

		return (
			<HyveCard isCentrePage={true}>
				{RVBDateLoader || DemographicsLoader ? (
					<CustomLoader size={40} />
				) : errorMessageRVBDate || errorMessageDemographics ? (
					<ErrorMessageSpan text={errorMessageRVBDate || errorMessageDemographics} />
				) : (
					<div className={cx('centrePageSummaryInside')}>
						<div className={cx('monthlyVisitorsSummary')}>
							<h2 data-value='totalVisitors'>{totalVisitors} </h2>
							<h3>Visitors Last Month</h3>
						</div>
						<div className={cx('avgVisitorsSummary')}>
							<h2 data-value='avgDailyVisitors'>{avgDailyVisitors} </h2>
							<h3>Average Daily Visitors </h3>
						</div>
						<div className={cx('monthlyWiFiSummary')}>
							<h2 data-value='totalWiFiUsers'>{totalWiFiUsers} </h2>
							<h3>Wi-Fi Users Last Month</h3>
						</div>
						<div className={cx('avgWiFiSummary')}>
							<h2 data-value='avgDailyWiFiUsers'>{avgDailyWiFiUsers} </h2>
							<h3>Average Daily Wi-Fi Users </h3>
						</div>
					</div>
				)}
			</HyveCard>
		);
	}
}

CentrePageSummary.propTypes = {
	RVBDateLoader: PropTypes.bool,
	errorMessageRVBDate: PropTypes.string,
	visitorsData: PropTypes.array,
	DemographicsLoader: PropTypes.bool,
	errorMessageDemographics: PropTypes.string,
	demographics: PropTypes.object,
};

CentrePageSummary.defaultProps = {
	RVBDateLoader: false,
	errorMessageRVBDate: '',
	visitorsData: [],
	DemographicsLoader: false,
	errorMessageDemographics: '',
	demographics: new List(),
};

export default CentrePageSummary;
