import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import ActionMenu from '../../Menus/ActionMenu';
import images from '../../Icons';
import styles from './styles.scss';

const cx = classNames.bind(styles);

const AppHeader = (props) => {
	const { history, signOut, userInfo, isCentrePage } = props;

	const goBackToMainDashboard = (event) => {
		event.stopPropagation();
		history.push('/centre_dashboard');
	};

	return (
		<header className={cx('header', { headerCentrePage: isCentrePage })} data-testid='custom-app-header'>
			<div className={cx('logo', { logoCentrePage: isCentrePage })}>
				<div className={cx('appName')}>
					<img
						src={images.metrixLogo}
						className={cx('logoIcon', { logoIconCentrePage: isCentrePage })}
						alt={'logo'}
						onClick={goBackToMainDashboard}
					/>
				</div>
			</div>
			<div className={cx('userWrapper')}>
				<ActionMenu
					handleLogout={signOut}
					history={history}
					firstName={userInfo.size > 0 ? userInfo.get('first_name') : ''}
					lastName={userInfo.size > 0 ? userInfo.get('last_name') : ''}
				/>
			</div>
		</header>
	);
};

AppHeader.propTypes = {
	history: PropTypes.object,
	signOut: PropTypes.func,
	userInfo: PropTypes.object,
	isCentrePage: PropTypes.bool,
};

AppHeader.defaultProps = {
	history: {},
	signOut: () => {},
	userInfo: {},
	isCentrePage: false,
};

export default AppHeader;
