import internalAxiosInstance from '../axios/internalAxiosInstance';
import { responseHandler, errorHandler } from './utility';

export function getPersonasData(siteId, radius) {
	return internalAxiosInstance
		.get(`/sites/${siteId}/reports/roymorgan?type=personas&radius=${radius}`)
		.then((response) => responseHandler(response))
		.catch((err) => {
			console.log(err);
			return errorHandler(err);
		});
}
