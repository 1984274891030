import { takeEvery, call, fork, all, put } from 'redux-saga/effects';
import * as ACTION_TYPES from '../../constants/ActionTypes';
import { getCommunitiesData } from '../apis/communitiesAPIs';
import { fetchCommunitiesDone, fetchCommunitiesFailed } from '../actions/communitiesAction';

function* watchFetchCommunitiesDone() {
	yield takeEvery(ACTION_TYPES.FETCH_COMMUNITIES, function* (action) {
		try {
			const result = yield call(getCommunitiesData, action.siteId, action.radius);
			yield put(fetchCommunitiesDone(result));
		} catch (error) {
			yield put(fetchCommunitiesFailed(error));
		}
	});
}

export default function* rootSaga() {
	yield all([fork(watchFetchCommunitiesDone)]);
}
