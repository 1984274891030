import React from 'react';
import classNames from 'classnames/bind';
import styles from './styles.scss';
import TextFieldHeader from '../../components/Headers/TextFieldHeader';

const cx = classNames.bind(styles);

const HelpTemplate = () => {
	return (
		<div className={cx('helpWrapper')} data-testid='help-template'>
			<TextFieldHeader title='Contact Us' />
			<p data-testid='help-template-p'>
				For help or any questions please contact <a href='mailto:lyndall.pamp@shoppermedia.com.au'>lyndall.pamp@shoppermedia.com.au</a>.
			</p>
		</div>
	);
};

export default HelpTemplate;
