import React from 'react';
import { connect } from 'react-redux';
import { inputLoginUser, inputLoginPassword, loginFailure, signIn } from '../../redux/actions/authActions';
import SignInTemplate from '../../templates/signInTemplate/index.template';

class SignInPageContainer extends React.Component {
	render() {
		const { loginUser, loginPassword, loginLoader, loginErrorMessage, loginFailure, inputLoginPassword, inputLoginUser } = this.props;
		return (
			<SignInTemplate
				loginUser={loginUser}
				loginPassword={loginPassword}
				loginLoader={loginLoader}
				loginErrorMessage={loginErrorMessage}
				loginFailure={loginFailure}
				inputLoginPassword={inputLoginPassword}
				inputLoginUser={inputLoginUser}
				handleSignIn={this.handleSignIn}
			/>
		);
	}

	handleSignIn = () => {
		const { loginUser, loginPassword, signIn, loginFailure } = this.props;
		if (loginUser && loginPassword.length >= 8) {
			signIn(loginUser, loginPassword);
		} else {
			loginFailure({ message: 'Invalid body request' });
		}
	};
}

const mapStateToProps = (state) => {
	return {
		loginUser: state.authReducer.get('loginUser'),
		loginPassword: state.authReducer.get('loginPassword'),
		loginLoader: state.authReducer.get('loginLoader'),
		loginErrorMessage: state.authReducer.get('loginErrorMessage'),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		inputLoginUser: (value) => dispatch(inputLoginUser(value)),
		inputLoginPassword: (value) => dispatch(inputLoginPassword(value)),
		signIn: (username, password) => dispatch(signIn(username, password)),
		loginFailure: (error) => dispatch(loginFailure(error)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SignInPageContainer);
