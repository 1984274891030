import { Map, List, fromJS } from 'immutable';
import * as ACTION_TYPES from '../../constants/ActionTypes';

export const initState = new Map({
	fetchContactSitesLoader: false,
	contactSitesErrorMessage: '',
	contactSites: new List(),
	selectedSite: null,
	selectedRadius: null,
});

const contactsReducer = (state = initState, action) => {
	switch (action.type) {
		case ACTION_TYPES.FETCH_CONTACT_SITE_LIST:
			return state.set('fetchContactSitesLoader', true).set('contactSitesErrorMessage', '');

		case ACTION_TYPES.FETCH_CONTACT_SITE_LIST_DONE:
			return state.set('contactSites', fromJS(action.data)).set('fetchContactSitesLoader', false);

		case ACTION_TYPES.FETCH_CONTACT_SITE_LIST_FAILED:
			return state.set('fetchContactSitesLoader', false).set('contactSites', new List()).set('contactSitesErrorMessage', action.error.message);

		case ACTION_TYPES.SELECT_SITE:
			return state.set('selectedSite', action.selectedSite);

		case ACTION_TYPES.SELECT_RADIUS:
			return state.set('selectedRadius', action.selectedRadius);

		default:
			return state;
	}
};

export default contactsReducer;
