import moment from 'moment-timezone';
import _, { get } from 'lodash';

export const TimeRange = [
	{
		value: '06:00:00 - 06:59:59',
		label: '6am',
	},
	{
		value: '07:00:00 - 07:59:59',
		label: '7am',
	},
	{
		value: '08:00:00 - 08:59:59',
		label: '8am',
	},
	{
		value: '09:00:00 - 09:59:59',
		label: '9am',
	},
	{
		value: '10:00:00 - 10:59:59',
		label: '10am',
	},
	{
		value: '11:00:00 - 11:59:59',
		label: '11am',
	},
	{
		value: '12:00:00 - 12:59:59',
		label: '12pm',
	},
	{
		value: '13:00:00 - 13:59:59',
		label: '1pm',
	},
	{
		value: '14:00:00 - 14:59:59',
		label: '2pm',
	},
	{
		value: '15:00:00 - 15:59:59',
		label: '3pm',
	},
	{
		value: '16:00:00 - 16:59:59',
		label: '4pm',
	},
	{
		value: '17:00:00 - 17:59:59',
		label: '5pm',
	},
	{
		value: '18:00:00 - 18:59:59',
		label: '6pm',
	},
	{
		value: '19:00:00 - 19:59:59',
		label: '7pm',
	},
	{
		value: '20:00:00 - 20:59:59',
		label: '8pm',
	},
	{
		value: '21:00:00 - 21:59:59',
		label: '9pm',
	},
];

export function clearToken() {
	window.localStorage.removeItem('access_token');
	window.localStorage.removeItem('user_info');
	window.localStorage.removeItem('refresh_token');
}

export function getToken() {
	try {
		if (window.localStorage.getItem('access_token')) {
			return JSON.parse(window.localStorage.getItem('access_token'));
		}
		return null;
	} catch (err) {
		return null;
	}
}

export function getRefreshToken() {
	try {
		if (window.localStorage.getItem('refresh_token')) {
			return JSON.parse(window.localStorage.getItem('refresh_token'));
		}
		return null;
	} catch (err) {
		return null;
	}
}

export function getCurrentUserInfo() {
	try {
		if (window.localStorage.getItem('user_info')) {
			return JSON.parse(window.localStorage.getItem('user_info'));
		}
		return null;
	} catch (err) {
		return null;
	}
}

export function saveToken(token, userInfo, refreshToken) {
	try {
		if (token !== null) {
			window.localStorage.setItem('access_token', JSON.stringify(token));
		}
		if (refreshToken !== null) {
			window.localStorage.setItem('refresh_token', JSON.stringify(refreshToken));
		}
		if (userInfo !== null) {
			window.localStorage.setItem('user_info', JSON.stringify(userInfo));
		}
		return { message: 'Success' };
	} catch (err) {
		return { message: 'Failed' };
	}
}

export function numberWithCommas(num) {
	return num
		? parseInt(num)
				.toString()
				.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
		: 0;
}

export const initPreferredName = (name) => {
	let FN = '';
	let LN = '';
	if (name) {
		if (name.toString().includes('@') && name.toString().includes('.')) {
			// email
			const substr01 = name.toString().split('@');
			if (substr01.length) {
				const substr02 = substr01[0].toString().split('.');
				FN = substr02.length > 0 ? substr02[0].toUpperCase()[0] : '';
				LN = substr02.length > 1 ? substr02[1].toUpperCase()[0] : '';
			}
		} else if (name.toString().includes(' ')) {
			// full name
			const substr01 = name.toString().split(' ');
			if (substr01.length) {
				FN = substr01.length > 0 ? substr01[0].toUpperCase()[0] : '';
				LN = substr01.length > 1 ? substr01[1].toUpperCase()[0] : '';
			}
		} else {
			FN = name.toString()[0];
		}
	}
	return FN + LN;
};

export const convertToCSVData = (array, header, selectedColumns) => {
	const csvData = [];
	const keys = [];
	const columnHeader = [];
	header.forEach((th) => {
		if (selectedColumns === undefined || !selectedColumns.includes(th.name)) {
			keys.push(th.value);
			columnHeader.push(th.name);
		}
	});
	csvData.push(columnHeader);
	array.forEach((arr) => {
		const tmp = [];
		keys.forEach((key) => {
			tmp.push(arr.get(key) || '-');
		});
		csvData.push(tmp);
	});
	return csvData;
};

export const convertToCSVDataWithKeys = (array, keys, isList = true) => {
	const csvData = [];
	array.forEach((arr) => {
		const tmp = [];
		keys.forEach((key) => {
			tmp.push(isList ? arr.get(key) || '-' : arr[key] || '-');
		});
		csvData.push(tmp);
	});
	return csvData;
};

export const getRangedDate = (date, dateType) => {
	const undefinedResult = (function () {
		return;
	})();
	if (Array.isArray(date)) {
		return get(date[0], `${dateType}`, false) ? moment(date[0][dateType]).format('YYYY-MM-DD') : undefinedResult;
	} else {
		return undefinedResult;
	}
};

export const getSitesIdsString = (sites, siteKey = '_mist_site_id') => {
	let siteIds = '';
	sites.forEach((site) => {
		site[siteKey] && site[siteKey] !== 'all' && (siteIds === '' ? (siteIds += site[siteKey]) : (siteIds += ',' + site[siteKey]));
	});
	return siteIds;
};

export const calculateCentrePageSummaryData = (demographics, visitorsData) => {
	const totalVisitors = visitorsData.reduce((a, b) => ({ Substitute: parseInt(a.Substitute) + parseInt(b.Substitute) })).Substitute;
	const avgDailyVisitors = Math.round(parseInt(totalVisitors) / visitorsData.length);
	const totalWiFiUsers = demographics.toJS().reduce((a, b) => ({ count: parseInt(a.count || 0) + parseInt(b.count || 0) })).count;
	const avgDailyWiFiUsers = Math.round(parseInt(totalWiFiUsers) / visitorsData.length);
	return {
		totalVisitors: numberWithCommas(totalVisitors),
		avgDailyVisitors: numberWithCommas(avgDailyVisitors),
		totalWiFiUsers: numberWithCommas(totalWiFiUsers),
		avgDailyWiFiUsers: numberWithCommas(avgDailyWiFiUsers),
	};
};

export const getTimeRangeDetails = (timeRange, ranges = null, endDateProps = null, startDateProps = null) => {
	let infoTitle = 'The data of this graph is based on the following timeline';
	let endDate = moment().tz('Australia/Sydney').subtract(24, 'hours').format('MMM DD');
	let startDate = moment().tz('Australia/Sydney').subtract(30, 'days').format('MMM DD');

	switch (timeRange) {
		case 7: // custom date range
			if (ranges) {
				endDate = endDateProps ? moment(endDateProps).format('MMM DD YYYY') : moment().format('MMM DD YYYY');
				startDate = startDateProps ? moment(startDateProps).format('MMM DD YYYY') : moment().format('MMM DD YYYY');
			}
			break;

		case 1: // last 30days
			infoTitle = 'This graph is based on last 30 days of data';
			break;

		case 2: // last 24 hours
			endDate = moment().tz('Australia/Sydney').format('MMM DD, HH:mm');
			startDate = moment(endDate, 'MMM DD, HH:mm').tz('Australia/Sydney').subtract(24, 'hours').format('MMM DD, HH:mm');
			infoTitle = 'This graph is based on last 24 hours of data';
			break;

		case 3: // first day of the last three months to today
			startDate = moment().tz('Australia/Sydney').subtract(3, 'months').date(1).format('MMM DD');
			endDate = moment().tz('Australia/Sydney').subtract(24, 'hours').format('MMM DD');
			break;

		case 4: // quarterly
			endDate = moment().tz('Australia/Sydney').startOf('quarter').format('MMM DD');
			startDate = moment().tz('Australia/Sydney').startOf('year').format('MMM DD');
			break;

		case 5: // year to today
			endDate = moment().tz('Australia/Sydney').subtract(24, 'hours').format('MMM DD');
			startDate = moment().tz('Australia/Sydney').startOf('year').format('MMM DD');
			break;

		case 6: // last 60 days
			endDate = moment().tz('Australia/Sydney').subtract(24, 'hours').format('MMM DD');
			startDate = moment().tz('Australia/Sydney').subtract(60, 'days').format('MMM DD');
			infoTitle = 'This graph is based on last 60 days of data';
			break;

		default:
			break;
	}
	return { infoTitle, endDate, startDate };
};

export const getSitePopularityByDayReportData = (data) => {
	const arr = [];
	TimeRange.forEach((time) => {
		const obj = { timerange: time.label };
		data.filter((item) => item.get('timerange') === time.value).forEach((value) => {
			obj[value.get('day')] = value.get('avg_visits');
		});
		arr.push(obj);
	});
	return arr;
};

export const getUserDemographicsDate = (demographics) => {
	const newData = [];
	let obj = {};
	let age_group = '';
	demographics.forEach((item) => {
		if (age_group === item.get('age_group')) {
			if (item.get('gender') === 'Female') {
				obj.Female = item.get('count');
			} else if (item.get('gender') === 'Male') {
				obj.Male = -Math.abs(item.get('count'));
			}
			obj = {};
		} else {
			obj.id = item.get('age_group');
			if (!('Female' in obj)) {
				obj.Female = 0;
			} else if (!('Male' in obj)) {
				obj.Male = 0;
			}
			if (item.get('gender') === 'Female') {
				obj.Female = item.get('count');
			} else if (item.get('gender') === 'Male') {
				obj.Male = -Math.abs(item.get('count'));
			}
			newData.push(obj);
		}
		age_group = item.get('age_group');
	});
	return newData;
};

export const getDataObj = (sitesReport) => {
	let tmp = [];
	let dates = [];
	sitesReport.forEach((item) => {
		let obj = {};
		obj.date = item.get('date');
		obj.Actual = item.get('unique_visits');
		obj.Substitute = item.get('unique_visits') + item.get('fill_up');
		obj.FillUp = item.get('fill_up');
		tmp.push(obj);
		dates.push(item.get('date').split('-').reverse().join('-'));
	});
	return tmp;
};

export const concatCSV = (exportSiteName, ranges, tmp_data) => {
	let tmp_csv = [
		['DAILY VISITATION REPORT'],
		['Centres', exportSiteName],
		['Report Generated', moment(new Date()).tz('Australia/Sydney').format('DD/MM/YYYY')],
		['Start Date', moment(ranges[0].startDate).tz('Australia/Sydney').format('DD/MM/YYYY')],
		['End Date', moment(ranges[0].endDate).tz('Australia/Sydney').format('DD/MM/YYYY')],
		['Date', 'Visitations'],
	];
	return tmp_csv.concat(tmp_data);
};

export const getAvailableSites = (sites, siteKey = 'id', singleSelect = false) => {
	const result = [];
	if (sites.size > 0) {
		result.push(sites.toJS().flat());
	}
	if (!singleSelect && result.flat().length > 1) {
		const all = { site_name: 'All Centres' };
		all[siteKey] = 'all';
		result.push(all);
	}
	return _.sortBy(result.flat(), 'site_name');
};

export const getFirstDayPreviousMonth = () => {
	return moment().tz('Australia/Sydney').subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
};

export const getLastDayPreviousMonth = () => {
	return moment().tz('Australia/Sydney').subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
};

export const censusRadius = [
	{ value: '3', label: '3km' },
	{ value: '5', label: '5km' },
	{ value: '10', label: '10km' },
];

export const getMaxKay = (obj) => {
	const objKeys = Object.keys(obj);
	let maxKey = objKeys[0];
	objKeys.forEach((item) => {
		if (parseInt(objKeys[maxKey]) < parseInt(objKeys[item])) {
			maxKey = item;
		}
	});
	return maxKey;
};

export const getMaxObject = (objArray) => {
	const maxObj = objArray.reduce(function (prev, current) {
		return parseInt(prev.value) > parseInt(current.value) ? prev : current;
	});
	return maxObj;
};
