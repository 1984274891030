import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import SiteDashboardHeader from '../../../components/Headers/SiteDashboardHeader';
import CustomLoader from '../../../components/CustomLoader';
import ErrorMessageSpan from '../../../components/TextFields/ErrorMessageSpan';
import SingleBarChartBasedOnDate from '../../../components/Charts/SingleBarChartBasedOnDate';
import HyveCard from '../../../components/CustomCard';
import { Colors } from '../../../constants/Constants.json';
import images from '../../../components/Icons';
import styles from './styles.scss';

const cx = classNames.bind(styles);

class DailyVisits extends React.Component {
	render() {
		const { RVBDateLoader, errorMessageRVBDate, ranges, startDate, endDate, data } = this.props;

		return (
			<div data-testid='daily-visits'>
				<HyveCard isCentrePage={true}>
					<SiteDashboardHeader
						title='DAILY VISITS'
						color={Colors[16]}
						isCentrePage={true}
						icon={images.topApps}
						timeRange={7}
						startDateProps={startDate}
						endDateProps={endDate}
						ranges={ranges}
					/>
					{/* DAILY VISITS */}
					<div className={cx('chartArea')} data-testid='daily-visits-chart'>
						{RVBDateLoader ? (
							<CustomLoader size={40} />
						) : errorMessageRVBDate ? (
							<ErrorMessageSpan text={errorMessageRVBDate} />
						) : (
							data.length > 0 && (
								<SingleBarChartBasedOnDate
									data={data}
									valueY='Substitute'
									id='byDate'
									color={Colors[12]}
									paddingRight={20}
									barWidth={80}
									isCentrePage={true}
								/>
							)
						)}
					</div>
				</HyveCard>
			</div>
		);
	}
}

DailyVisits.propTypes = {
	RVBDateLoader: PropTypes.bool,
	errorMessageRVBDate: PropTypes.string,
	ranges: PropTypes.array,
	startDate: PropTypes.string,
	endDate: PropTypes.string,
	data: PropTypes.array,
};

DailyVisits.defaultProps = {
	RVBDateLoader: false,
	errorMessageRVBDate: '',
	ranges: [],
	startDate: '',
	endDate: '',
	data: [],
};

export default DailyVisits;
