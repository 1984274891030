import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';

const CustomLoader = (props) => {
	const classes = useStyles(props);
	const { withText = false } = props;
	return (
		<div className={classes.loaderWrapper} data-testid='custom-loader'>
			<div className={classes.loader} />
			{withText && <h3>Loading...</h3>}
		</div>
	);
};

CustomLoader.propTypes = {
	withText: PropTypes.bool,
};

CustomLoader.defaultProps = {
	withText: false,
};

export default CustomLoader;
