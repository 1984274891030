import { Map, fromJS, List } from 'immutable';
import * as ACTION_TYPES from '../../constants/ActionTypes';

export const initState = new Map({
	DemographicsLoader: false,
	errorMessageDemographics: '',
	demographics: new List(),

	RVBDayLoader: false,
	errorMessageRVBDay: '',
	currentSiteReportVisitsByDay: new List(),

	RVBDateLoader: false,
	errorMessageRVBDate: '',
	currentSiteReportVisitsByDate: new List(),

	RPBDayLoader: false,
	errorMessageRPBDay: '',
	currentSiteReportPopularityByDay: new List(),

	MonthlyStatsLoader: false,
	errorMessageMonthlyStats: '',
	monthlyStats: new List(),
});

export default function sitesReducer(state = initState, action) {
	switch (action.type) {
		case ACTION_TYPES.CENTRE_DASHBOARD_REPORT:
			return state
				.set('RVBDateLoader', true)
				.set('errorMessageRVBDate', '')
				.set('RVBDayLoader', true)
				.set('errorMessageRVBDay', '')
				.set('RPBDayLoader', true)
				.set('errorMessageRPBDay', '')
				.set('DemographicsLoader', true)
				.set('errorMessageDemographics', '')
				.set('MonthlyStatsLoader', true)
				.set('errorMessageMonthlyStats', '');

		case ACTION_TYPES.SITE_REPORT_MONTHLY_STATS_DONE:
			return state.set('monthlyStats', fromJS(action.data)).set('MonthlyStatsLoader', false);

		case ACTION_TYPES.SITE_REPORT_MONTHLY_STATS_FAILED:
			return state.set('monthlyStats', new List()).set('errorMessageRVBDate', action.error.message).set('MonthlyStatsLoader', false);

		case ACTION_TYPES.SITE_REPORT_VISITS_BY_DAY:
			return state.set('errorMessageRVBDay', '').set('RVBDayLoader', true);

		case ACTION_TYPES.SITE_REPORT_VISITS_BY_DAY_DONE:
			return state.set('currentSiteReportVisitsByDay', fromJS(action.data)).set('RVBDayLoader', false);

		case ACTION_TYPES.SITE_REPORT_VISITS_BY_DAY_FAILED:
			return state.set('currentSiteReportVisitsByDay', new List()).set('errorMessageRVBDay', action.error.message).set('RVBDayLoader', false);

		case ACTION_TYPES.SITE_REPORT_VISITS_BY_DATE:
			return state.set('RVBDateLoader', true).set('errorMessageRVBDate', '');

		case ACTION_TYPES.SITE_REPORT_VISITS_BY_DATE_DONE:
			return state.set('currentSiteReportVisitsByDate', fromJS(action.data)).set('RVBDateLoader', false);

		case ACTION_TYPES.SITE_REPORT_VISITS_BY_DATE_FAILED:
			return state.set('currentSiteReportVisitsByDate', new List()).set('errorMessageRVBDate', action.error.message).set('RVBDateLoader', false);

		case ACTION_TYPES.SITE_REPORT_POPULARITY_BY_DAY_DONE:
			return state.set('currentSiteReportPopularityByDay', fromJS(action.data)).set('RPBDayLoader', false);

		case ACTION_TYPES.SITE_REPORT_POPULARITY_BY_DAY_FAILED:
			return state.set('currentSiteReportPopularityByDay', new Map()).set('errorMessageRPBDay', action.error.message).set('RPBDayLoader', false);

		case ACTION_TYPES.SITE_REPORT_DEMOGRAPHICS_DONE:
			return state.set('demographics', fromJS(action.data)).set('DemographicsLoader', false);

		case ACTION_TYPES.SITE_REPORT_DEMOGRAPHICS_FAILED:
			return state.set('demographics', new List()).set('errorMessageDemographics', action.error.message).set('DemographicsLoader', false);
		default:
			return state;
	}
}
