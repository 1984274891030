import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';

const TextFieldHeader = (props) => {
	const classes = useStyles(props);
	const { title } = props;
	return (
		<div className={classes.textfieldHead} data-testid='text-field-header'>
			<p className={classes.textfieldTitle}>{title}</p>
			<div className={classes.wrapper}>
				<div className={classes.space} />
				<div className={classes.triangle} />
			</div>
		</div>
	);
};

TextFieldHeader.propTypes = {
	title: PropTypes.string,
};

TextFieldHeader.defaultProps = {
	title: '',
};

export default TextFieldHeader;
