import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
	root: {
		textTransform: 'none',
		padding: '0px 7px',
		height: 30,
		lineHeight: '28px',
		fontSize: 12,
		minWidth: 45,
		margin: (props) => props.margin || 0,
		color: (props) => props.textColor || '#2774AE',
		borderColor: (props) => props.backgroundColor || props.color || '#2774AE',
		backgroundColor: (props) => props.backgroundColor,
		'&:hover': {
			backgroundColor: (props) => (props.disableHover ? '#fff' : props.color || '#2774AE'),
			color: (props) => (props.disableHover ? (props.highlightHover ? props.textColor : 'inherit') : props.backgroundColor || '#fff'),
			fontWeight: (props) => props.highlightHover && 'bold',
		},
	},
}));
