import React from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import WifiIcon from '@material-ui/icons/Wifi';
import WifiOffIcon from '@material-ui/icons/WifiOff';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withStyles } from '@material-ui/core/styles';
import { textfieldStyle } from '../../constants/Theme';
import style01 from './style';

const style = { ...style01, ...textfieldStyle };

const SitesSelectorWithSearch = (props) => {
	const [inputValue, setInputValue] = React.useState('');

	const { classes, sites = [], selectedSiteId = '', siteKey } = props;

	const handleChange = (event, newValue) => {
		const { handleChangeSite, siteKey } = props;
		if (newValue && newValue[siteKey]) {
			handleChangeSite(newValue[siteKey], newValue);
		}
	};

	const handleChangeInput = (event, newInputValue) => {
		setInputValue(newInputValue);
	};

	const debounced = debounce(handleChangeInput, 150, {
		leading: true,
		trailing: false,
	});

	return (
		<FormControl className={classes.formControl_autoComplete} data-testid='sites-selector-with-search'>
			<Autocomplete
				classes={{
					root: classes.autocompleteRoot,
					popupIndicator: classes.popupIndicator,
					noOptions: classes.noOptions,
					listbox: classes.listbox,
					groupLabel: classes.groupLabel,
				}}
				size='small'
				disableClearable
				options={sites}
				getOptionDisabled={(site) => site.aps === 0}
				getOptionLabel={(site) => {
					return site.site_name || '';
				}}
				getOptionSelected={(site) => {
					return site[siteKey] === selectedSiteId;
				}}
				value={!selectedSiteId ? {} : sites.filter((site) => site[siteKey] === selectedSiteId)[0] || {}}
				onChange={handleChange}
				inputValue={inputValue}
				onInputChange={debounced}
				renderOption={(site) => (
					<React.Fragment>
						{site[siteKey] === 'all_sites' || site[siteKey] === 'all' ? (
							<WifiIcon className={classes.wifiIconBlue} data-testid='sites-selector-with-search-wifi-icon-blue' />
						) : site.free_wifi === 1 ? (
							<WifiIcon className={classes.wifiIcon} data-testid='sites-selector-with-search-wifi-icon' />
						) : (
							<WifiOffIcon className={classes.wifiOffIcon} data-testid='sites-selector-with-search-wifi-off-icon' />
						)}
						<span className={classes.siteName} data-testid='sites-selector-with-search-span'>
							{site.site_name}
						</span>
					</React.Fragment>
				)}
				renderInput={(params) => (
					<TextField
						{...params}
						id='sites-selector-with-search-text'
						variant='outlined'
						size='small'
						label='Centre'
						fullWidth
						className={classes.root}
						inputProps={{
							...params.inputProps,
							className: classes.inputSelect,
							id: 'sites-selector-with-search-text-input',
						}}
						InputProps={{
							...params.InputProps,
							classes: {
								root: classes.cssOutlinedInput,
								focused: classes.cssFocused,
								notchedOutline: classes.notchedOutline,
							},
						}}
						InputLabelProps={{
							...params.InputLabelProps,
							classes: {
								root: classes.inputLabel,
								marginDense: classes.labelDense,
								shrink: classes.labelShrink,
							},
							htmlFor: 'sites-selector-with-search-text-input',
							id: 'sites-selector-with-search-text-label',
						}}
						data-testid='sites-selector-with-search-text-field'
					/>
				)}
			/>
		</FormControl>
	);
};

SitesSelectorWithSearch.propTypes = {
	sites: PropTypes.array,
	selectedSiteId: PropTypes.string,
	handleChangeSite: PropTypes.func,
	siteKey: PropTypes.string,
};

SitesSelectorWithSearch.defaultProps = {
	sites: [],
	selectedSiteId: '',
	handleChangeSite: null,
	siteKey: 'id',
};

export default withStyles(style)(SitesSelectorWithSearch);
