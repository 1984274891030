import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import useStyles from './styles';

const TechnologyAdoption = (props) => {
	const { data } = props;

	const classes = useStyles(props);

	return (
		<div className={classes.techAdoptionContainer}>
			<svg width='100%' height='350px' viewBox='0 0 1789 540'>
				<title>chart</title>
				<g>
					<path
						d='M624.87,371.83V653.61H59.35V638.8c17.2.61,34.55.71,51.78-.94a575.7,575.7,0,0,0,98.48-18,736.07,736.07,0,0,0,85.07-28.77c23.09-9.5,45.89-19.65,68.07-31.1q43.54-22.49,84.82-48.93c25.71-16.47,50.59-34.09,74.94-52.44,14.56-11,28.76-22.46,42.72-34.24,16.91-14.27,33.78-28.56,49.86-43.78C618,377.83,621.13,375.11,624.87,371.83Z'
						transform='translate(-58 -193)'
						className={classNames(data.highlight.value === '1' ? classes.techAdoptionPathMax : classes.techAdoptionPath)}
					></path>
					<path
						d='M646,653.63V364.41c0-11.57,0-11.58,8.69-18.58,11.91-9.58,23.7-19.3,35.75-28.71,32.33-25.26,65.83-48.87,101.12-69.84a281.73,281.73,0,0,1,29.22-15.63v422Z'
						transform='translate(-58 -193)'
						className={classNames(data.highlight.value === '2' ? classes.techAdoptionPathMax : classes.techAdoptionPath)}
					></path>
					<path
						d='M942,193V653.63H828.56c-1-1.72-.54-3.59-.54-5.36q0-207.91-.07-415.82c0-3.2.3-5.41,3.79-6.9,13.56-5.81,26.81-12.32,40.7-17.33,16.19-5.83,32.48-11.14,49.66-13.47,3.58-.49,7.57.53,10.9-1.75Z'
						transform='translate(-58 -193)'
						className={classNames(data.highlight.value === '3' ? classes.techAdoptionPathMax : classes.techAdoptionPath)}
					></path>
					<path
						d='M973,193c5.9,2,12.15,1.69,18.19,2.9,17.73,3.55,34.73,9.26,51.5,15.92,10.53,4.18,20.64,9.26,31.1,13.56,2.71,1.12,4.34,2.39,4.33,6.07q-.21,209.16-.12,418.32v3.84H964V193Z'
						transform='translate(-58 -193)'
						className={classNames(data.highlight.value === '4' ? classes.techAdoptionPathMax : classes.techAdoptionPath)}
					></path>
					<path
						d='M1259.79,653.74H1085.38V231.54c28.29,14.34,55,31.18,81,49.3,22.34,15.61,44.2,31.84,65.24,49.19,8.49,7,17.11,13.85,25.69,20.76a7,7,0,0,1,2.79,6Q1260,503.7,1260,650.64C1260,651.59,1259.87,652.55,1259.79,653.74Z'
						transform='translate(-58 -193)'
						className={classNames(data.highlight.value === '5' ? classes.techAdoptionPathMax : classes.techAdoptionPath)}
					></path>
					<path
						d='M1847,654H1281.35V372.25c4.21,3.72,8.21,7.1,12,10.66,15.08,14.08,30.75,27.47,46.56,40.72q26.47,22.19,54.11,42.85c26.46,19.67,53.65,38.28,81.77,55.53,25.78,15.81,52.1,30.64,79.25,43.93,37.44,18.32,75.91,34.17,115.78,46.49a640.11,640.11,0,0,0,73.24,18.34c15.31,2.82,30.79,4.78,46.32,6.41,18.86,2,37.71,1.7,56.59,1.82Z'
						transform='translate(-58 -193)'
						className={classNames(data.highlight.value === '6' ? classes.techAdoptionPathMax : classes.techAdoptionPath)}
					></path>
					<path d='M58,676H624v57H58Z' transform='translate(-58 -130)' className={classNames(classes.techAdoptionPathSpace)}></path>
					<path d='M58,676H624v57H58Z' transform='translate(-58 -193)' className={classNames(classes.techAdoptionPathSpace)}></path>
					<path d='M1281,733V676.32h561V733Z' transform='translate(-58 -193)' className={classNames(classes.techAdoptionPathSpace)}></path>
					<path d='M1281,733V676.32h561V733Z' transform='translate(-58 -130)' className={classNames(classes.techAdoptionPathSpace)}></path>
					<path d='M962,733V676.33h297V733Z' transform='translate(-58 -193)' className={classNames(classes.techAdoptionPathSpace)}></path>
					<path d='M962,733V676.33h297V733Z' transform='translate(-58 -130)' className={classNames(classes.techAdoptionPathSpace)}></path>
					<path d='M647,733V676.33H941V733Z' transform='translate(-58 -193)' className={classNames(classes.techAdoptionPathSpace)}></path>
					<path d='M647,733V676.33H941V733Z' transform='translate(-58 -130)' className={classNames(classes.techAdoptionPathSpace)}></path>
					<text x='270' y='585' className={classNames(classes.techAdoptionCategory)}>
						Early Adopters
					</text>
					<text x='265' y='280' className={classNames(classes.techAdoptionLabel)}>
						Technology
					</text>
					<text x='240' y='325' className={classNames(classes.techAdoptionLabel)}>
						Early Adopters
					</text>
					<text x='735' y='585' className={classNames(classes.techAdoptionCategory)}>
						Early Majority
					</text>
					<text x='520' y='60' className={classNames(classes.techAdoptionLabel)}>
						Professional
					</text>
					<text x='435' y='105' className={classNames(classes.techAdoptionLabel)}>
						Technology Mainstream
					</text>
					<text x='760' y='0' className={classNames(classes.techAdoptionLabel)}>
						Digital Life
					</text>
					<text x='1050' y='585' className={classNames(classes.techAdoptionCategory)}>
						Late Majority
					</text>
					<text x='1150' y='0' className={classNames(classes.techAdoptionLabel)}>
						Older Tech Explorers
					</text>
					<text x='1247' y='60' className={classNames(classes.techAdoptionLabel)}>
						Technology
					</text>
					<text x='1265' y='105' className={classNames(classes.techAdoptionLabel)}>
						Traditionalists
					</text>
					<text x='1500' y='585' className={classNames(classes.techAdoptionCategory)}>
						Laggards
					</text>
					<text x='1500' y='270' className={classNames(classes.techAdoptionLabel)}>
						Technophobes
					</text>
					<text
						id='early-adopters'
						x='450'
						y='400'
						className={classNames(data.highlight.value === '1' ? classes.techAdoptionValueMax : classes.techAdoptionValue)}
					>
						{data.technologyAdoptionChart.earlyAdopters}%
					</text>
					<text
						id='early-majority-1'
						x='675'
						y='400'
						className={classNames(data.highlight.value === '2' ? classes.techAdoptionValueMax : classes.techAdoptionValue)}
					>
						{data.technologyAdoptionChart.earlyMajority1}%
					</text>
					<text
						id='early-majority-2'
						x='825'
						y='400'
						className={classNames(data.highlight.value === '3' ? classes.techAdoptionValueMax : classes.techAdoptionValue)}
					>
						{data.technologyAdoptionChart.earlyMajority2}%
					</text>
					<text
						id='late-majority-1'
						x='963'
						y='400'
						className={classNames(data.highlight.value === '4' ? classes.techAdoptionValueMax : classes.techAdoptionValue)}
					>
						{data.technologyAdoptionChart.lateMajority1}%
					</text>
					<text
						id='late-majority-2'
						x='1110'
						y='400'
						className={classNames(data.highlight.value === '5' ? classes.techAdoptionValueMax : classes.techAdoptionValue)}
					>
						{data.technologyAdoptionChart.lateMajority2}%
					</text>
					<text
						id='laggards'
						x='1330'
						y='400'
						className={classNames(data.highlight.value === '6' ? classes.techAdoptionValueMax : classes.techAdoptionValue)}
					>
						{data.technologyAdoptionChart.laggards}%
					</text>
					<text x='240' y='525' className={classNames(classes.techAdoptionCategory)}>
						Nationally:
					</text>
					<text id='early-adoptersxxxx' x='450' y='525' className={classNames(classes.techAdoptionCategoryValue)}>
						{data.nationallyTable.earlyAdopters}%
					</text>
					<text id='early-majority-1' x='675' y='525' className={classNames(classes.techAdoptionCategoryValue)}>
						{data.nationallyTable.earlyMajority1}%
					</text>
					<text id='early-majority-2' x='825' y='525' className={classNames(classes.techAdoptionCategoryValue)}>
						{data.nationallyTable.earlyMajority2}%
					</text>
					<text id='late-majority-1' x='963' y='525' className={classNames(classes.techAdoptionCategoryValue)}>
						{data.nationallyTable.lateMajority1}%
					</text>
					<text id='late-majority-2' x='1110' y='525' className={classNames(classes.techAdoptionCategoryValue)}>
						{data.nationallyTable.lateMajority2}%
					</text>
					<text id='laggards' x='1330' y='525' className={classNames(classes.techAdoptionCategoryValue)}>
						{data.nationallyTable.laggards}%
					</text>
				</g>
			</svg>
		</div>
	);
};

TechnologyAdoption.propTypes = {
	data: PropTypes.shape({
		technologyAdoptionChart: PropTypes.object,
		nationallyTable: PropTypes.object,
		highlight: PropTypes.object,
		colourTheme: PropTypes.object,
	}),
};

TechnologyAdoption.defaultProps = {
	data: {
		technologyAdoptionChart: null,
		nationallyTable: null,
		highlight: null,
	},
	colourTheme: null,
};

export default TechnologyAdoption;
