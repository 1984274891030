import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
	description: {
		padding: '40px',
		'& p': {
			color: '#ffffff',
			fontWeight: 'bold',
		},
	},
	knowMeImage: {
		width: '100% !important',
		height: '100%',
		padding: '0',
		margin: '0',
		objectFit: 'cover',
		objectPosition: 'right',
	},
	row: {
		display: 'flex',
		backgroundColor: (props) => props.colourTheme.defultColour || '#55cae5',
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column',
		},
	},
	imageContainer: {
		display: 'flex',
		flexBasis: '50%',
		[theme.breakpoints.down('md')]: {
			maxHeight: '400px',
		},
	},
	colParagraph: {
		flexBasis: '50%',
		margin: 'auto',
		alignItems: 'center',
		justifyContent: 'center',
		float: 'left',
	},
	colImage: {
		flexBasis: '50%',
	},
}));
