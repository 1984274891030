import React from 'react';
import PropTypes from 'prop-types';
import CustomLoader from '../../components/CustomLoader';
import CensusHeader from '../../components/CensusDashboard/CensusHeader';

import useStyles from './styles';
import CensusDashboardTemplate from '../censusDashboardTemplate/index.template';

const CensusDataPageTemplate = (props) => {
	const classes = useStyles(props);

	const { pageLoader, selectedSiteId, sites, handleChangeSite, siteKey, selectedSiteName, censusRadius, selectedCensusRadius, handleChangeRadius } = props;

	if (pageLoader) {
		return (
			<div className={classes.pageLoader} data-testid='census-data-page-template-loader'>
				<CustomLoader site={64} />
			</div>
		);
	} else {
		return (
			<div className={classes.censusDataPageWrapper} data-testid='census-data-page-template'>
				<div className={classes.censusDataPageLayout}>
					<div className={classes.censusDataPageHeader}>
						<CensusHeader
							selectedSiteId={selectedSiteId}
							sites={sites}
							label='Centre'
							handleChangeSite={handleChangeSite}
							siteKey={siteKey}
							selectedSiteName={selectedSiteName}
							censusRadius={censusRadius}
							selectedCensusRadius={selectedCensusRadius}
							handleChangeRadius={handleChangeRadius}
						/>
						<div className={classes.censusDataPageContainer}>
							<CensusDashboardTemplate {...props} />
						</div>
					</div>
				</div>
			</div>
		);
	}
};

CensusDataPageTemplate.propTypes = {
	pageLoader: PropTypes.bool,
	censusDataLoader: PropTypes.bool,
	sites: PropTypes.array,
	selectedSiteId: PropTypes.string,
	handleChangeSite: PropTypes.func,
	siteKey: PropTypes.string,
	selectedSite: PropTypes.object,
	censusRadius: PropTypes.array,
	selectedCensusRadius: PropTypes.string,
	handleChangeRadius: PropTypes.func,
};

CensusDataPageTemplate.defaultProps = {
	pageLoader: false,
	censusDataLoader: false,
	sites: [],
	selectedSiteId: '',
	handleChangeSite: null,
	siteKey: 'id',
	selectedSite: {},
	censusRadius: [],
	selectedCensusRadius: null,
	handleChangeRadius: null,
};

export default CensusDataPageTemplate;
