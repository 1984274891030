import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import CustomLoader from '../../CustomLoader';
import ErrorMessageSpan from '../../TextFields/ErrorMessageSpan';
import DetailedColumnBarChart from '../../Charts/DetailedColumnBarChart';
import SiteDashboardHeader from '../../Headers/SiteDashboardHeader';
import CustomCard from '../../CustomCard';
import { Colors } from '../../../constants/Constants.json';
import images from '../../Icons';

import useStyles from './styles';

const WorkOccupationChart = (props) => {
	const classes = useStyles(props);

	const { workOccupationDataLoader, workOccupationDataErrorMessage, workOccupationData } = props;

	return (
		<CustomCard isCentrePage={true}>
			<SiteDashboardHeader title='WORK OCCUPATION' color={Colors[16]} textColor={'#000'} isCentrePage={true} icon={images.topApps} />
			<div className={classes.paperContainer}>
				{workOccupationDataLoader ? (
					<CustomLoader size={40} />
				) : workOccupationDataErrorMessage ? (
					<ErrorMessageSpan text={workOccupationDataErrorMessage} />
				) : (
					<Grid container spacing={2} justifyContent='center'>
						<DetailedColumnBarChart
							id='work_occupation_chart'
							data={workOccupationData}
							category='label'
							paddingTop={20}
							paddingRight={20}
							paddingBottom={0}
							barWidth={60}
							isCentrePage={true}
							valueY='count'
							color={Colors[12]}
							tooltipText='{categoryX}: [bold]{valueY}[/]'
							rotation={0}
							isBullet={true}
							labelMaxWidth={100}
						/>
					</Grid>
				)}
			</div>
		</CustomCard>
	);
};

WorkOccupationChart.propTypes = {
	workOccupationDataLoader: PropTypes.bool,
	workOccupationDataErrorMessage: PropTypes.string,
	workOccupationData: PropTypes.array,
};

WorkOccupationChart.defaultProps = {
	workOccupationDataLoader: false,
	workOccupationDataErrorMessage: '',
	workOccupationData: [],
};

export default WorkOccupationChart;
