import axios from 'axios';
import { getToken, getCurrentUserInfo, getRefreshToken, saveToken, clearToken } from '../../helpers/utility';
import history from '../../history';

const internalAxiosInstance = axios.create({
	baseURL: process.env.REACT_APP_INTERNAL_API_GATEWAY_URL,
	timeout: 20000,
});

const internalAxiosInstanceRefreshToken = axios.create({
	baseURL: process.env.REACT_APP_INTERNAL_API_GATEWAY_URL,
	timeout: 20000,
});

axios.defaults.timeout = 20000;

let isRefreshing = false;
let subscribers = [];

// alter config after instance has been created
internalAxiosInstance.defaults.headers.post['Content-Type'] = 'application/json';
internalAxiosInstanceRefreshToken.defaults.headers.post['Content-Type'] = 'application/json';

internalAxiosInstance.interceptors.request.use(
	(config) => {
		// Do something before request is sent
		const token = getToken();
		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}
		return config;
	},
	(err) => Promise.reject(err)
);

function subscribeTokenRefresh(cb) {
	subscribers.push(cb);
}

function onRrefreshed(token) {
	subscribers.map((cb) => cb(token));
}

internalAxiosInstance.interceptors.response.use(
	(response) => Promise.resolve(response),
	(error) => {
		const originalRequest = error.config;
		if (error.response === undefined) {
			// fnetwork error
			if (window.navigator.onLine) {
				while (!originalRequest._retry || originalRequest._retry < 3) {
					if (!originalRequest._retry) {
						originalRequest._retry = 1;
					} else {
						originalRequest._retry++;
					}
					return internalAxiosInstance(originalRequest);
				}
			}
			return Promise.reject(error);
		}
		console.log(error.response);
		if (error.response.status === 403) {
			if (error.response.data && (error.response.data.errorType === 'EXPIRED_TOKEN' || error.response.data.errorType === 'INVALID_TOKEN')) {
				if (!originalRequest.isExpired) {
					originalRequest.isExpired = true;
					if (!isRefreshing) {
						isRefreshing = true;
						const refreshToken = getRefreshToken();
						const { username } = getCurrentUserInfo();
						return internalAxiosInstanceRefreshToken
							.post('/auth/refreshtoken', {
								refreshToken,
								username,
							})
							.then((res) => {
								const { data, status } = res;
								if (status === 200) {
									isRefreshing = false;
									// 1) put token to LocalStorage
									saveToken(data.token, null, data.refreshToken);

									const firstRequest = new Promise((resolve) => {
										subscribeTokenRefresh((token) => {
											// 2) Change Authorization header
											originalRequest.headers.Authorization = `Bearer ${token}`;
											resolve(internalAxiosInstance(originalRequest));
										});
									});

									onRrefreshed(data.token);
									subscribers = [];

									return firstRequest;
								}
								return Promise.reject(res);
							})
							.catch((err) => {
								// refresh token error
								console.log('===refresh token error===');
								clearToken();
								history.push('/sign_in');
								return Promise.reject(err);
							});
					}

					const requestSubscribers = new Promise((resolve) => {
						subscribeTokenRefresh((token) => {
							originalRequest.headers.Authorization = `Bearer ${token}`;
							resolve(internalAxiosInstance(originalRequest));
						});
					});
					return requestSubscribers;
				}
			} else {
				// 'Insufficient Privilege' -- retry
				// retry logic
				while (!originalRequest._retry || originalRequest._retry < 3) {
					if (!originalRequest._retry) {
						originalRequest._retry = 1;
					} else {
						originalRequest.headers._retry++;
					}
					return internalAxiosInstance(originalRequest);
				}

				console.log('==403 Insufficient Privilege==');
				console.log(error);
				return Promise.reject(error);
			}
		} else {
			console.log('==other error==');
			console.log(error);
			return Promise.reject(error);
		}
	}
);

export default internalAxiosInstance;
