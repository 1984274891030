import React from 'react';
import ReactDOM from 'react-dom';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import configureStore from './redux/store';
import App from './App';

const store = configureStore();

const THEME = createTheme({
	typography: {
		fontFamily: '"Arial", "Helvetica", sans-serif',
		color: '#000',
		fontSize: 14,
		fontWeightLight: 300,
		fontWeightRegular: 400,
		fontWeightMedium: 500,
	},
});

if (process.env.NODE_ENV !== 'development') {
	console.log = console.warn = console.error = () => {};
}

ReactDOM.render(
	<ThemeProvider theme={THEME}>
		<Provider store={store}>
			<App />
		</Provider>
	</ThemeProvider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
