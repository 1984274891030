import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
	isCollapsed: {
		position: 'absolute',
		right: 0,
	},
	deg90: {
		transform: 'rotate(90deg)',
	},
	expandIcon: {
		fontSize: 20,
		fill: '#fff',
		float: 'right',
		margin: 2,
	},
	lockIcon: {
		fontSize: 14,
		fill: 'inherit',
		float: 'right',
		margin: 5,
	},
	menuItemIcon: {
		marginRight: (props) => (props.isMainMenuCollapsed ? 0 : 10),
		height: 24,
		width: 24,
		float: 'left',
		'& div': {
			height: '100%',
			width: '100%',
			'& svg': {
				height: 20,
				width: 20,
				fill: 'inherit',
				margin: 2,
			},
		},
	},
	summaryText: {
		color: 'inherit',
		lineHeight: '24px',
	},
	tooltipWrapper: {
		display: 'block',
		height: 'auto',
		width: '100%',
		cursor: 'pointer',
		// lineHeight: '20px',
	},
}));
