import React from 'react';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import VisitationsByDay from '../../components/CentreDashboard/VisitationsByDay';
import UserDemographics from '../../components/CentreDashboard/UserDemographics';
import AveragePeakTimesByDay from '../../components/CentreDashboard/AveragePeakTimesByDay';
import MonthlyVisitation from '../../components/CentreDashboard/MonthlyVisitation';
import CentrePageSummary from '../../components/CentreDashboard/PageSummary';
import { concatCSV, convertToCSVDataWithKeys, getDataObj } from '../../helpers/utility';
import { Colors } from '../../constants/Constants.json';
import styles from './styles.scss';
import DailyVisits from '../../components/CentreDashboard/DailyVisits';

const cx = classNames.bind(styles);
class CentrePageTemplate extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
		};
	}

	componentDidMount() {
		const { currentSiteReportVisitsByDate } = this.props;
		if (currentSiteReportVisitsByDate && currentSiteReportVisitsByDate.size > 0 && currentSiteReportVisitsByDate.toJS().length > 0) {
			const tmp = getDataObj(currentSiteReportVisitsByDate);
			tmp.length > 0 && this.setState({ data: tmp });
		}
	}

	componentDidUpdate(prevProps) {
		const { currentSiteReportVisitsByDate, exportSiteName, ranges, handleCsv } = this.props;
		if (!isEqual(currentSiteReportVisitsByDate, prevProps.currentSiteReportVisitsByDate)) {
			const tmp = getDataObj(currentSiteReportVisitsByDate);
			tmp.length > 0 && this.setState({ data: tmp });
			const tmp_data = convertToCSVDataWithKeys(tmp, ['date', 'Substitute'], false);
			const tmp_csv = concatCSV(exportSiteName, ranges, tmp_data);
			tmp_data.length > 0 && handleCsv(tmp_csv);
		}
	}

	render() {
		const { RVBDateLoader, errorMessageRVBDate, ranges, startDate, endDate, clientWidth, demographics, errorMessageDemographics, DemographicsLoader } =
			this.props;
		const { data } = this.state;

		return (
			<>
				<div className={cx('centrePageSummary')}>
					<CentrePageSummary
						visitorsData={data}
						RVBDateLoader={RVBDateLoader}
						errorMessageRVBDate={errorMessageRVBDate}
						demographics={demographics}
						errorMessageDemographics={errorMessageDemographics}
						DemographicsLoader={DemographicsLoader}
					/>
				</div>

				{/* DAILY VISITS */}
				<DailyVisits
					data={data}
					RVBDateLoader={RVBDateLoader}
					errorMessageRVBDate={errorMessageRVBDate}
					startDateProps={startDate}
					endDateProps={endDate}
					ranges={ranges}
				/>

				<div className={clientWidth > 900 ? cx('centrePageLayer') : cx('')}>
					{/* MONTHLY VISITS */}
					<div className={clientWidth > 900 ? cx('centrePageLeft', 'centrePageHalfPage') : cx('centrePageFull')}>
						<MonthlyVisitation isBullet={clientWidth >= 1100} ranges={ranges} timeRange={7} />
					</div>

					{/* AVERAGE DAILY VISITS */}
					<div className={clientWidth > 900 ? cx('centrePageRight', 'centrePageHalfPage') : cx('centrePageFull')}>
						<VisitationsByDay
							isBullet={clientWidth >= 1100}
							startDate={startDate}
							endDate={endDate}
							ranges={ranges}
							timeRange={7}
							title={'AVERAGE DAILY VISITS'}
							color={Colors[14]}
							isCentrePage={true}
						/>
					</div>
				</div>

				<div className={clientWidth > 900 ? cx('centrePageLayer') : cx('')}>
					{/* WI-FI CONSUMER DEMOGRAPHICS */}
					<div className={clientWidth > 900 ? cx('centrePageLeft', 'centrePageHalfPage') : cx('centrePageFull')}>
						<UserDemographics
							startDate={startDate}
							endDate={endDate}
							ranges={ranges}
							timeRange={7}
							color={'#db5856'}
							color01={Colors[12]}
							paddingTop={20}
							title={'WI-FI CONSUMER DEMOGRAPHICS'}
							isCentrePage={true}
							numberFormat={'#,###.#####s'}
						/>
					</div>

					{/* PEAK TIMES BY DAY */}
					<div className={clientWidth > 900 ? cx('centrePageRight', 'centrePageHalfPage') : cx('centrePageFull')}>
						<AveragePeakTimesByDay
							startDate={startDate}
							endDate={endDate}
							ranges={ranges}
							timeRange={7}
							title={'PEAK TIMES BY DAY'}
							isCentrePage={true}
							colorHeader={Colors[12]}
						/>
					</div>
				</div>
			</>
		);
	}
}

CentrePageTemplate.propTypes = {
	startDate: PropTypes.string,
	endDate: PropTypes.string,
	exportSiteName: PropTypes.string,
	clientWidth: PropTypes.number,
	handleCsv: PropTypes.func,
	currentSiteReportVisitsByDate: PropTypes.object,
	RVBDateLoader: PropTypes.bool,
	errorMessageRVBDate: PropTypes.string,
	demographics: PropTypes.object,
	errorMessageDemographics: PropTypes.string,
	DemographicsLoader: PropTypes.bool,
	ranges: PropTypes.array,
};

CentrePageTemplate.defaultProps = {
	startDate: '',
	endDate: '',
	exportSiteName: '',
	clientWidth: null,
	handleCsv: null,
	currentSiteReportVisitsByDate: {},
	RVBDateLoader: false,
	errorMessageRVBDate: '',
	demographics: {},
	errorMessageDemographics: '',
	DemographicsLoader: false,
	ranges: [],
};

export default CentrePageTemplate;
