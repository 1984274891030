import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Grid } from '@material-ui/core';
import HeaderImages from './HeaderImages';

import useStyles from './styles';

const PersonasHeader = (props) => {
	const { data } = props;

	const classes = useStyles(props);

	return (
		<>
			<Grid item container direction='row' xs={12}>
				<HeaderImages headerImages={data.images} />
			</Grid>
			<Grid item xs={12}>
				<div className={classNames(classes.header)}>
					<h1 className={classNames(classes.headerText)}>{data.title}</h1>
				</div>
			</Grid>
		</>
	);
};

PersonasHeader.propTypes = {
	data: PropTypes.shape({
		images: PropTypes.array,
		title: PropTypes.string,
	}),
};

PersonasHeader.defaultProps = {
	data: {
		images: [],
		title: '',
	},
};

export default PersonasHeader;
