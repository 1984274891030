import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { getAvailableSites, getFirstDayPreviousMonth, getLastDayPreviousMonth, getRangedDate, getSitesIdsString } from '../../helpers/utility';
import CentreDashboardTemplate from '../../templates/centreDashboardTemplate/index.template';
import { getCentreDashboardReport } from '../../redux/actions/sitesActions';
import { signOut } from '../../redux/actions/authActions';
import { fetchContactSiteList, selectSite } from '../../redux/actions/contactsActions';
import { clickMainMenuItem } from '../../redux/actions/appActions';

class CentreDashboardPageContainer extends React.Component {
	constructor(props) {
		super(props);
		this.wrapperRef = React.createRef();
		this.state = {
			selectedSiteId: 'all',
			exportSiteName: 'all',
			availableSites: [],
			openDatePicker: false,
			startDate: getFirstDayPreviousMonth(),
			endDate: getLastDayPreviousMonth(),
			ranges: [
				{
					startDate: new Date(getFirstDayPreviousMonth()),
					endDate: new Date(getLastDayPreviousMonth()),
					key: 'selection',
				},
			],
			clientWidth: window.innerWidth,
			csv: [
				['DAILY VISITATION REPORT'],
				['Centres', 'all'],
				['Report Generated', moment(new Date()).tz('Australia/Sydney').format('DD/MM/YYYY')],
				['Start Date', props.startDate],
				['End Date', props.endDate],
				['Date', 'Visitations'],
			],
		};
	}

	componentDidMount() {
		const { fetchContactSiteList, userInfo, contactSites, clickMainMenuItem } = this.props;

		if (userInfo.size !== 0 && userInfo.get('contact_id') !== undefined && userInfo.get('contact_id') !== null) {
			fetchContactSiteList(userInfo.get('contact_id'));
		}
		if (contactSites.size > 0) {
			const availableSites = getAvailableSites(contactSites, '_mist_site_id');
			if (availableSites.length > 0) {
				this.setState({
					availableSites,
				});
			}
		}

		document.addEventListener('mousedown', this.closeDateRangePicker);
		window.addEventListener('resize', this.updateDimensions);
		clickMainMenuItem(true, 'dashboard');
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.closeDateRangePicker);
		window.removeEventListener('resize', this.updateDimensions);
	}

	componentDidUpdate(prevProps) {
		const { getCentreDashboardReport, contactSites } = this.props;
		const { ranges } = this.state;
		if (prevProps.contactSites !== contactSites) {
			const availableSites = getAvailableSites(contactSites, '_mist_site_id');
			let selectedSiteId;
			if (availableSites.length > 0) {
				const startDate = getRangedDate(ranges, 'startDate');
				const endDate = getRangedDate(ranges, 'endDate');
				if (availableSites.length === 1) {
					selectedSiteId = availableSites[0]._mist_site_id;
				}
				this.setState({
					selectedSiteId: selectedSiteId || 'all',
					availableSites,
				});
				getCentreDashboardReport(getSitesIdsString(availableSites, '_mist_site_id'), startDate, endDate);
			}
		}
	}

	updateDimensions = () => {
		this.setState({
			clientWidth: window.innerWidth,
		});
	};

	handleSelectDateRange = (item) => {
		this.setState({
			ranges: [item.selection],
		});
	};

	handleChangeSite = (value, site) => {
		const { selectSite } = this.props;
		if (value) {
			if (value === 'all') {
				this.setState({
					selectedSiteId: 'all',
					labelSiteId: 'all',
				});
			} else {
				this.setState({
					selectedSiteId: value,
					labelSiteId: value,
				});
				selectSite(site);
			}
		}
	};

	toggleDateRangePicker = () => {
		this.setState((prevState) => ({
			openDatePicker: !prevState.openDatePicker,
		}));
	};

	closeDateRangePicker = (event) => {
		const { openDatePicker } = this.state;
		if (openDatePicker && this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
			this.setState({
				openDatePicker: false,
			});
		}
	};

	handleCsv = (csv) => {
		this.setState({
			csv: csv,
		});
	};

	handleClickRun = () => {
		const { getCentreDashboardReport } = this.props;
		const { selectedSiteId, availableSites } = this.state;
		const { ranges } = this.state;
		const startDate = getRangedDate(ranges, 'startDate');
		const endDate = getRangedDate(ranges, 'endDate');
		let currentSiteName = '';

		if (selectedSiteId && availableSites.length > 0) {
			if (selectedSiteId !== 'all') {
				currentSiteName = availableSites.find((site) => site._mist_site_id === selectedSiteId).site_name;
			}
			getCentreDashboardReport(selectedSiteId === 'all' ? getSitesIdsString(availableSites) : selectedSiteId, startDate, endDate);
			this.setState({
				startDate,
				endDate,
				exportSiteName: currentSiteName,
			});
		}
	};

	render() {
		const {
			history,
			signOut,
			fetchContactSitesLoader,
			userInfo,
			currentSiteReportVisitsByDate,
			RVBDateLoader,
			errorMessageRVBDate,
			demographics,
			errorMessageDemographics,
			DemographicsLoader,
		} = this.props;
		const { availableSites, openDatePicker, ranges, startDate, endDate, exportSiteName, clientWidth, selectedSiteId, csv } = this.state;

		return (
			<CentreDashboardTemplate
				history={history}
				signOut={signOut}
				userInfo={userInfo}
				sitesLoader={fetchContactSitesLoader}
				sites={availableSites}
				selectedSiteId={selectedSiteId}
				siteKey='_mist_site_id'
				openDatePicker={openDatePicker}
				ranges={ranges}
				startDate={startDate}
				endDate={endDate}
				exportSiteName={exportSiteName}
				clientWidth={clientWidth}
				csv={csv}
				wrapperRef={this.wrapperRef}
				toggleDateRangePicker={this.toggleDateRangePicker}
				handleClickRun={this.handleClickRun}
				handleSelectDateRange={this.handleSelectDateRange}
				handleChangeSite={this.handleChangeSite}
				handleCsv={this.handleCsv}
				currentSiteReportVisitsByDate={currentSiteReportVisitsByDate}
				RVBDateLoader={RVBDateLoader}
				errorMessageRVBDate={errorMessageRVBDate}
				demographics={demographics}
				errorMessageDemographics={errorMessageDemographics}
				DemographicsLoader={DemographicsLoader}
			/>
		);
	}
}

const mapStateToProps = (state) => ({
	contactSites: state.contactsReducer.get('contactSites'),
	fetchContactSitesLoader: state.contactsReducer.get('fetchContactSitesLoader'),

	userInfo: state.authReducer.get('userInfo'),

	currentSiteReportVisitsByDate: state.sitesReducer.get('currentSiteReportVisitsByDate'),
	RVBDateLoader: state.sitesReducer.get('RVBDateLoader'),
	errorMessageRVBDate: state.sitesReducer.get('errorMessageRVBDate'),

	demographics: state.sitesReducer.get('demographics'),
	errorMessageDemographics: state.sitesReducer.get('errorMessageDemographics'),
	DemographicsLoader: state.sitesReducer.get('DemographicsLoader'),
});

export const mapDispatchToProps = (dispatch) => ({
	fetchContactSiteList: (contactId) => dispatch(fetchContactSiteList(contactId)),
	getCentreDashboardReport: (siteIds, startDate, endDate) => dispatch(getCentreDashboardReport(siteIds, startDate, endDate)),
	clickMainMenuItem: (bools, identifier) => dispatch(clickMainMenuItem(bools, identifier)),
	signOut: () => dispatch(signOut()),
	selectSite: (selectedSite) => dispatch(selectSite(selectedSite)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CentreDashboardPageContainer);
