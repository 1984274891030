import React from 'react';
import { connect } from 'react-redux';
import {
	fetchOwnerUserAction,
	toggleResetPasswordModal,
	closeSnackbarModal,
	inputUserNewPasswordAgain,
	inputUserNewPassword,
	resetUserPassword,
	resetUserPasswordFailed,
} from '../../redux/actions/authActions';
import UserPreferenceTemplate from '../../templates/userPreferenceTemplate/index.template';

class UserPreferencePageContainer extends React.Component {
	state = {
		hasUserResetPassword: false,
		isExternalUser: false,
	};

	componentDidMount() {
		const { firstTimeFetch, fetchOwnerUserAction, userPreference, userInfo } = this.props;
		if (userInfo) {
			const hasExternalUserResetPassword = userInfo.get('reset_password') === null ? true : false;
			this.setState({ hasUserResetPassword: hasExternalUserResetPassword });
			if (!hasExternalUserResetPassword) {
				this.handleToggle();
			}
		}
		if (firstTimeFetch && userPreference.size === 0) {
			fetchOwnerUserAction();
		}
	}
	render() {
		const {
			userName,
			userLastName,
			userFirstName,
			userPassword,
			userEmail,
			userAyudaName,
			toggleResetPswModal,
			userNewPassword,
			userNewPasswordAgain,
			inputUserNewPasswordAgain,
			inputUserNewPassword,
			resetUserPasswordFailed,
			resetLoader,
			preferenceErrorMessage,
			snackbarOpen,
		} = this.props;

		return (
			<UserPreferenceTemplate
				userName={userName}
				userLastName={userLastName}
				userFirstName={userFirstName}
				userPassword={userPassword}
				userEmail={userEmail}
				userAyudaName={userAyudaName}
				handleToggle={this.handleToggle}
				handleResetPassword={this.handleResetPassword}
				toggleResetPswModal={toggleResetPswModal}
				userNewPassword={userNewPassword}
				userNewPasswordAgain={userNewPasswordAgain}
				inputUserNewPasswordAgain={inputUserNewPasswordAgain}
				inputUserNewPassword={inputUserNewPassword}
				resetUserPasswordFailed={resetUserPasswordFailed}
				resetLoader={resetLoader}
				preferenceErrorMessage={preferenceErrorMessage}
				handleSave={this.handleOnSaveResetPassword}
				handleCancel={this.handleOnCancelResetPassword}
				snackbarOpen={snackbarOpen}
				handleCloseAction={this.handleCloseAction}
			/>
		);
	}

	handleResetPassword = () => {
		this.setState({ hasUserResetPassword: true });
	};

	handleToggle = () => {
		const { toggleResetPasswordModal } = this.props;
		toggleResetPasswordModal(true);
	};

	handleCloseAction = () => {
		const { closeSnackbarModal } = this.props;
		closeSnackbarModal();
	};

	handleOnSaveResetPassword = () => {
		const { resetUserPassword, userId, userNewPassword, userNewPasswordAgain, resetUserPasswordFailed } = this.props;

		if (userNewPassword.length >= 8 && userNewPassword === userNewPasswordAgain) {
			resetUserPassword(userId, userNewPassword);
			this.handleResetPassword();
		} else {
			resetUserPasswordFailed({ message: 'Invalid request body' });
		}
	};

	handleOnCancelResetPassword = () => {
		const { toggleResetPasswordModal } = this.props;
		toggleResetPasswordModal(false);
	};
}

const mapStateToProps = (state) => {
	return {
		userInfo: state.authReducer.get('userInfo'),
		userPreference: state.authReducer.get('userPreference'),
		userId: state.authReducer.get('userId'),
		userName: state.authReducer.get('userName'),
		userLastName: state.authReducer.get('userLastName'),
		userFirstName: state.authReducer.get('userFirstName'),
		userEmail: state.authReducer.get('userEmail'),
		userAyudaName: state.authReducer.get('userAyudaName'),

		toggleResetPswModal: state.authReducer.get('toggleResetPswModal'),
		userNewPassword: state.authReducer.get('userNewPassword'),
		userNewPasswordAgain: state.authReducer.get('userNewPasswordAgain'),
		resetLoader: state.authReducer.get('resetLoader'),
		preferenceErrorMessage: state.authReducer.get('preferenceErrorMessage'),
		firstTimeFetch: state.authReducer.get('firstTimeFetch'),

		snackbarOpen: state.authReducer.get('snackbarOpen'),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchOwnerUserAction: () => dispatch(fetchOwnerUserAction()),

		toggleResetPasswordModal: (bools) => dispatch(toggleResetPasswordModal(bools)),
		inputUserNewPasswordAgain: (value) => dispatch(inputUserNewPasswordAgain(value)),
		inputUserNewPassword: (value) => dispatch(inputUserNewPassword(value)),
		resetUserPassword: (userId, newPassword) => dispatch(resetUserPassword(userId, newPassword)),
		resetUserPasswordFailed: (error) => dispatch(resetUserPasswordFailed(error)),

		closeSnackbarModal: () => dispatch(closeSnackbarModal()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UserPreferencePageContainer);
