import React from 'react';
import PersonaPageContainer from '../../../containers/royMorgan/persona/personaPageContainer';

class Persona extends React.Component {
	render() {
		return <PersonaPageContainer {...this.props} />;
	}
}

export default Persona;
