import internalAxiosInstance from '../axios/internalAxiosInstance';
import { responseHandler, errorHandler } from './utility';

export function getPopulationData(siteId, censusRadius) {
	return internalAxiosInstance
		.get(`/sites/${siteId}/reports/census/populationdata?radius=${censusRadius}`)
		.then((response) => responseHandler(response))
		.catch((err) => {
			console.log(err);
			return errorHandler(err);
		});
}

export function getAgeGroupsData(siteId, censusRadius) {
	return internalAxiosInstance
		.get(`/sites/${siteId}/reports/census/agegroupsdata?radius=${censusRadius}`)
		.then((response) => responseHandler(response))
		.catch((err) => {
			console.log(err);
			return errorHandler(err);
		});
}

export function getWorkOccupationData(siteId, censusRadius) {
	return internalAxiosInstance
		.get(`/sites/${siteId}/reports/census/workoccupationdata?radius=${censusRadius}`)
		.then((response) => responseHandler(response))
		.catch((err) => {
			console.log(err);
			return errorHandler(err);
		});
}

export function getEthnicityAndLanguagesData(siteId, censusRadius) {
	return internalAxiosInstance
		.get(`/sites/${siteId}/reports/census/ethnicityandlanguagesdata?radius=${censusRadius}`)
		.then((response) => responseHandler(response))
		.catch((err) => {
			console.log(err);
			return errorHandler(err);
		});
}

export function getMaritalStatusAndFamilytypesData(siteId, censusRadius) {
	return internalAxiosInstance
		.get(`/sites/${siteId}/reports/census/maritalstatusandfamilytypesdata?radius=${censusRadius}`)
		.then((response) => responseHandler(response))
		.catch((err) => {
			console.log(err);
			return errorHandler(err);
		});
}

export function getPropertyOwnerShipData(siteId, censusRadius) {
	return internalAxiosInstance
		.get(`/sites/${siteId}/reports/census/propertyownershipdata?radius=${censusRadius}`)
		.then((response) => responseHandler(response))
		.catch((err) => {
			console.log(err);
			return errorHandler(err);
		});
}
