import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
	section: {
		padding: '0px 50px 50px 20px',
		backgroundColor: (props) => props.backgroundColor,
		color: (props) => props.fontColor,
	},
	statTitle: {
		fontSize: '16px',
		fontWeight: '600',
		textTransform: 'uppercase',
		color: (props) => props.fontColor,
		marginBottom: '20px',
	},
	customIcon: {
		fill: (props) => props.iconColor,
		'& div': {
			'& svg': {
				width: 50,
				height: 50,
			},
		},
		stroke: (props) => props.iconColor,
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'left',
		textAlign: 'left',
		marginTop: '40px',
	},
	icon: {
		padding: '0px 10px 0px 0px',
		justifyContent: 'center',
		textAlign: 'center',
	},
}));
