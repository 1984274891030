import { Map } from 'immutable';
import * as ACTION_TYPES from '../../constants/ActionTypes';

export const initState = new Map({
	communitiesLoader: false,
	communitiesErrorMessage: '',
	communities: {},
});

const communitiesReducer = (state = initState, action) => {
	switch (action.type) {
		case ACTION_TYPES.FETCH_COMMUNITIES:
			return state.set('communitiesLoader', true).set('communitiesErrorMessage', '');

		case ACTION_TYPES.FETCH_COMMUNITIES_DONE:
			return state.set('communities', action.data.data).set('communitiesLoader', false);

		case ACTION_TYPES.FETCH_COMMUNITIES_FAILED:
			return state.set('communitiesLoader', false).set('communities', {}).set('communitiesErrorMessage', action.error.message);

		default:
			return state;
	}
};

export default communitiesReducer;
