import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames/bind';
import LogoHeader from '../../components/Headers/LogoHeader';
import css from './styles.js';
import styles from './styles.scss';

const cx = classNames.bind(styles);

class SignInTemplate extends React.Component {
	render() {
		const { classes, loginUser, loginPassword, inputLoginPassword, inputLoginUser, loginLoader, loginErrorMessage, loginFailure, handleSignIn } =
			this.props;
		return (
			<div className={cx('signInWrapper')} data-testid='sign-in-template'>
				<div className={cx('loginRight')}>
					<header className={cx('loginHeader')}>
						<LogoHeader />
					</header>
					{loginErrorMessage && (
						<div className={cx('errorMessage')} data-testid='sign-in-template-error'>
							<span data-testid='sign-in-template-error-message'>{loginErrorMessage}</span>
							<CloseIcon
								className={cx('closeErrorMessageIcon')}
								onClick={() => loginFailure({ message: '' })}
								data-testid='sign-in-template-error-icon'
							/>
						</div>
					)}

					<div className={cx('inputWrapperHeight')} data-testid='sign-in-template-username'>
						<FormControl className={cx('inputWrapperButton')}>
							<TextField
								variant='outlined'
								value={loginUser}
								placeholder='Username'
								className={classes.textField}
								onChange={(event) => inputLoginUser(event.target.value)}
								InputProps={{
									classes: {
										root: classes.cssOutlinedInput1,
										focused: classes.cssFocused,
										notchedOutline: classes.notchedOutline1,
										input: cx('inputtest'),
									},
								}}
							/>
						</FormControl>
					</div>
					<div className={cx('inputWrapperHeight')} data-testid='sign-in-template-password'>
						<FormControl className={cx('inputWrapperButton')}>
							<TextField
								variant='outlined'
								type='password'
								value={loginPassword}
								placeholder={'Password'}
								className={classes.textField1}
								onKeyPress={(event) => {
									if (event.charCode === 13) {
										handleSignIn();
									}
								}}
								onChange={(event) => inputLoginPassword(event.target.value)}
								InputProps={{
									classes: {
										root: classes.cssOutlinedInput1,
										focused: classes.cssFocused,
										notchedOutline: classes.notchedOutline1,
										input: cx('inputtest'),
									},
								}}
							/>
							<Button
								variant='outlined'
								type='submit'
								className={cx('constantButton')}
								onClick={handleSignIn}
								data-testid='sign-in-template-button'
							>
								{loginLoader ? <div className={cx('loading')} data-testid='sign-in-template-loading' /> : `Go`}
							</Button>
						</FormControl>
					</div>
				</div>
			</div>
		);
	}
}

SignInTemplate.propTypes = {
	loginUser: PropTypes.string,
	loginPassword: PropTypes.string,
	inputLoginPassword: PropTypes.func,
	inputLoginUser: PropTypes.func,
	loginLoader: PropTypes.bool,
	loginErrorMessage: PropTypes.string,
	loginFailure: PropTypes.func,
	handleSignIn: PropTypes.func,
};

SignInTemplate.defaultProps = {
	loginUser: '',
	loginPassword: '',
	inputLoginPassword: null,
	inputLoginUser: null,
	loginLoader: false,
	loginErrorMessage: '',
	loginFailure: null,
	handleSignIn: null,
};

export default withStyles(css)(SignInTemplate);
