import React from 'react';
import PropTypes from 'prop-types';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

am4core.useTheme(am4themes_animated);

let timeout = null;

class RadarChart extends React.Component {
	componentDidMount() {
		const { id, data, fillColor } = this.props;

		var chart = am4core.create(`radar-chart-${id}`, am4charts.RadarChart);

		chart.data = data;

		var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
		categoryAxis.dataFields.category = 'label';

		var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
		valueAxis.renderer.axisFills.template.fill = '#000';
		valueAxis.renderer.axisFills.template.fillOpacity = 0.05;
		valueAxis.renderer.grid.template.strokeDasharray = '3,3';
		valueAxis.renderer.grid.template.stroke = 'red';
		valueAxis.renderer.gridType = 'polygons';

		var series = chart.series.push(new am4charts.RadarSeries());
		series.dataFields.valueY = 'value';
		series.dataFields.categoryX = 'label';
		series.name = 'Sales';
		series.strokeWidth = 1;

		series.fill = am4core.color(fillColor);
		series.fillOpacity = 0.67;
	}

	componentWillUnmount() {
		if (this.chart) {
			this.chart.dispose();
			clearTimeout(timeout);
		}
	}

	render() {
		const { id } = this.props;
		return <div id={`radar-chart-${id}`} style={{ width: '100%', height: '100%' }} />;
	}
}

RadarChart.propTypes = {
	id: PropTypes.string,
	data: PropTypes.array,
	fillColor: PropTypes.string,
};

RadarChart.defaultProps = {
	id: '',
	data: [],
	fillColor: '#55cae5',
};

export default RadarChart;
