import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import ReactSVG from 'react-svg';

import useStyles from './styles';

const SingleListComponent = (props) => {
	const classes = useStyles(props);

	const { list } = props;

	return (
		<div className={classes.section}>
			<Grid item xs={12}>
				<h3 className={classes.statTitle}>{list.headingText}</h3>
			</Grid>
			<Grid item container xs={12}>
				{list.data.map((listItem, index) => {
					return (
						<Grid item xs={12} sm={6} md={4} key={`list-item-${index}`}>
							<div className={classes.row}>
								<div className={classes.icon}>
									<ReactSVG src={require(`../../Icons/personas/${listItem.filename}`)} className={classes.customIcon} />
								</div>
								<div className={classes.text}>
									<b>{listItem.shortText}</b>
									<br />
									<span dangerouslySetInnerHTML={{ __html: listItem.longText }} />
								</div>
							</div>
						</Grid>
					);
				})}
			</Grid>
		</div>
	);
};

SingleListComponent.propTypes = {
	list: PropTypes.shape({
		headingText: PropTypes.string,
		data: PropTypes.array,
	}),
};

SingleListComponent.defaultProps = {
	list: {
		headingText: '',
		data: [],
	},
};

export default SingleListComponent;
