import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Grid } from '@material-ui/core';
import SingleListComponent from '../SingleListComponent';

import useStyles from './styles';

const ReachMeSection = (props) => {
	const classes = useStyles(props);

	const { data } = props;

	return (
		<>
			<Grid item xs={12}>
				<div className={classNames(classes.sectionHeader)}>
					<h1 className={classes.sectionHeaderText}>{data.headingText}</h1>
				</div>
			</Grid>
			<Grid item xs={12}>
				<SingleListComponent list={data.mediaChannelsBias} iconColor='#ffffff' />
			</Grid>
		</>
	);
};

ReachMeSection.propTypes = {
	data: PropTypes.shape({
		headingText: PropTypes.string,
		mediaChannelsBias: PropTypes.object,
	}),
};

ReachMeSection.defaultProps = {
	data: {
		headingText: '',
		mediaChannelsBias: null,
	},
};

export default ReachMeSection;
