import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
	textWrapper: {
		height: '100%',
		width: '100%',
		display: 'flex',
	},
	text: {
		fontSize: 13,
		color: '#999',
		margin: 'auto',
	},
}));
