import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { amchartsStyle } from '../../../constants/Theme';

am4core.useTheme(am4themes_animated);

let timeout = null;

class SingleBarChartBasedOnDate extends React.Component {
	componentDidMount() {
		const {
			id,
			data,
			valueY,
			valueYText,
			category = 'date',
			color = '#2774AE',
			inputDateFormat = 'dd-MM-yyyy',
			rotation = 0,
			minGridDistance = 30,
			isBullet = false,
			isLegend = false,
			paddingBottom = 10,
			paddingRight = 20,
			paddingLeft = 20,
			paddingTop = 20,
			strictMinMax = true,
			min = 0,
			isCentrePage = false,
			barWidth = 60,
			retrieveImageData,
		} = this.props;

		const chart = am4core.create(`chart-${id}`, am4charts.XYChart);

		chart.paddingRight = paddingRight;
		chart.paddingLeft = paddingLeft;
		chart.paddingTop = paddingTop;
		chart.paddingBottom = paddingBottom;

		// Add data
		chart.data = data;
		chart.dateFormatter.inputDateFormat = inputDateFormat;

		// Create axes
		const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
		dateAxis.renderer.labels.template.fontSize = amchartsStyle.labelsFontSize;
		dateAxis.renderer.grid.template.location = 0;
		dateAxis.renderer.minGridDistance = minGridDistance;
		dateAxis.renderer.labels.template.rotation = rotation;
		dateAxis.renderer.labels.template.horizontalCenter = 'center';
		dateAxis.renderer.labels.template.verticalCenter = 'middle';
		dateAxis.tooltip.disabled = true;

		const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
		valueAxis.title.text = valueYText;
		valueAxis.title.fontSize = amchartsStyle.titleFontSize;
		valueAxis.title.fill = '#6a6564';
		valueAxis.renderer.labels.template.fontSize = amchartsStyle.labelsFontSize;
		valueAxis.min = min;
		valueAxis.strictMinMax = strictMinMax;
		valueAxis.tooltip.fontSize = amchartsStyle.tooltipFontSize;

		// Create series
		const series = chart.series.push(new am4charts.ColumnSeries());
		series.dataFields.valueY = valueY;
		series.dataFields.dateX = category;
		series.clustered = false;
		series.name = valueYText;
		series.tooltip.fontSize = amchartsStyle.tooltipFontSize;
		series.columns.template.width = am4core.percent(barWidth);
		series.columns.template.strokeWidth = 0;
		series.columns.template.fill = color;
		series.columns.template.tooltipText = "{dateX.formatDate('EEE, dd/MM/yyyy')}: [bold]{valueY}[/]";

		if (isCentrePage) {
			// changes axes colour
			dateAxis.renderer.labels.template.fill = valueAxis.renderer.labels.template.fill = '#716E6A';

			// centres lable to middle
			dateAxis.renderer.labels.template.horizontalCenter = 'middle';

			// disables vertical grid lines
			dateAxis.renderer.grid.template.disabled = true;

			// adds border radius to bars
			series.columns.template.column.cornerRadiusTopLeft = 6;
			series.columns.template.column.cornerRadiusTopRight = 6;
		}

		if (isBullet) {
			const bullet = series.bullets.push(new am4charts.LabelBullet());
			bullet.interactionsEnabled = false;
			bullet.locationY = 0.5;
			bullet.label.text = '{valueY}';
			bullet.label.fontSize = amchartsStyle.labelsFontSize;
		}

		if (isLegend) {
			chart.legend = new am4charts.Legend();
			chart.legend.fontSize = amchartsStyle.legendFontSize;
			const markerTemplate = chart.legend.markers.template;
			markerTemplate.width = 8;
			markerTemplate.height = 8;
		}

		chart.cursor = new am4charts.XYCursor();
		chart.cursor.lineX.opacity = 0;

		this.chart = chart;

		if (typeof retrieveImageData === 'function') {
			chart.events.on('ready', () => {
				timeout = setTimeout(() => {
					this.exportPNG();
				}, 500);
			});
		}
	}

	componentDidUpdate(prevProps) {
		const { data } = this.props;
		if (data.length > 0 && !isEqual(prevProps.data, data)) {
			this.chart.data = data;
		}
	}

	componentWillUnmount() {
		if (this.chart) {
			this.chart.dispose();
			clearTimeout(timeout);
		}
	}

	exportPNG = () => {
		const { retrieveImageData } = this.props;
		this.chart.exporting.getImage('png').then((imgData) => {
			retrieveImageData(imgData);
		});
	};

	render() {
		const { id } = this.props;
		return <div id={`chart-${id}`} style={{ width: '100%', height: '100%' }} data-testid='single-bar-chart-based-on-date' />;
	}
}

SingleBarChartBasedOnDate.propTypes = {
	id: PropTypes.string,
	data: PropTypes.array,
	valueY: PropTypes.string,
	valueYText: PropTypes.string,
	category: PropTypes.string,
	color: PropTypes.string,
	inputDateFormat: PropTypes.string,
	rotation: PropTypes.number,
	minGridDistance: PropTypes.number,
	isBullet: PropTypes.bool,
	isLegend: PropTypes.bool,
	paddingBottom: PropTypes.number,
	paddingRight: PropTypes.number,
	paddingLeft: PropTypes.number,
	paddingTop: PropTypes.number,
	strictMinMax: PropTypes.bool,
	min: PropTypes.number,
	isCentrePage: PropTypes.bool,
	barWidth: PropTypes.number,
	retrieveImageData: PropTypes.func,
};

SingleBarChartBasedOnDate.defaultProps = {
	id: '',
	data: [],
	valueY: '',
	valueYText: '',
	category: 'date',
	color: '#2774AE',
	inputDateFormat: 'dd-MM-yyyy',
	rotation: 0,
	minGridDistance: 30,
	isBullet: false,
	isLegend: false,
	paddingBottom: 10,
	paddingRight: 20,
	paddingLeft: 20,
	paddingTop: 20,
	strictMinMax: true,
	min: 0,
	isCentrePage: false,
	barWidth: 60,
	retrieveImageData: null,
};

export default SingleBarChartBasedOnDate;
