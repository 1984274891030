import React from 'react';
import Modal from '@material-ui/core/Modal';
import useStyles from './styles';

const UnauthorizedTemplate = (props) => {
	const classes = useStyles(props);

	return (
		<div className={classes.page}>
			<Modal open={true} className={classes.fullscreen} aria-labelledby='simple-modal-title' aria-describedby='simple-modal-description' data-testid='unauthorized-template-model'>
				<div className={classes.modalWrapper}>
					<div className={classes.modalHead} data-testid='unauthorized-template-span'>
						<span>Unauthorized</span>
					</div>
					<div className={classes.modalContent} data-testid='unauthorized-template-content'>
						<p style={{ marginBottom: 0 }}>Looks like you do not have access to this resource.</p>
					</div>
					<div className={classes.modalFooter} data-testid='unauthorized-template-a'>
						<a className={classes.link} href='/sign_in'>
							OK
						</a>
					</div>
				</div>
			</Modal>
		</div>
	);
};

export default UnauthorizedTemplate;
