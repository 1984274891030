import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
	statComponent: {
		margin: '0px 20px 50px 20px',
	},
	statTitle: {
		marginBottom: '20px',
		fontSize: '16px',
		fontWeight: '600',
		textTransform: 'uppercase',
	},
	svgContainer: {
		textAlign: 'center',
	},
	progChartContainer: {
		width: '100%',
	},
	progChart: {
		padding: '15px',
		margin: '0 auto',
	},
	progChartScale: {
		backgroundColor: (props) => props.colourTheme.defultColour || '#31adce',
		height: '10px',
		position: 'relative',
		margin: '30px',
		opacity: '0.67',
	},
	progChartMarker: {
		lineHeight: '.8em',
		fontSize: '2em',
		textTransform: 'uppercase',
		textAlign: 'cente',
		fontWeight: '700',
		display: 'block',
		width: '20px',
		height: '37px',
		background: 'linear-gradient(180deg,#eee 0,#aaa)',
		color: '#fff',
		top: '-55px',
		position: 'relative',
		left: (props) => (props.data.data.midpoint ? props.data.data.midpoint + '%' : 0),
		backgroundColor: (props) => props.colourTheme.defultColour || '#31adce',
	},
	progChartLabels: {
		paddingTop: '0',
		marginTop: '-60px',
		display: 'flex',
		flexFlow: 'row nowrap',
		justifyContent: 'space-between',
		fontSize: '12px',
		fontFamily: `"Proxima Nova",sans-serif`,
		fontWeight: '700',
	},
}));
