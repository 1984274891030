import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { textfieldStyle } from '../../constants/Theme';
import css from './styles';
import ExportButton from './ExportButton';
import SearchButton from './SearchButton';
import DataRangePickerButton from './DataRangePickerButton';

const style = { ...textfieldStyle, ...css };

class SiteDashboard extends React.PureComponent {
	render() {
		const { classes, ranges, toggleDateRangePicker, handleClickRun, textColor, color, backgroundColor, csv, highlightHover, disableHover } = this.props;

		return (
			<div className={classes.filtersRight}>
				<DataRangePickerButton ranges={ranges} toggleDateRangePicker={toggleDateRangePicker} />
				<SearchButton
					handleClickAction={handleClickRun}
					textColor={textColor}
					color={color}
					backgroundColor={backgroundColor}
					highlightHover={highlightHover}
					disableHover={disableHover}
				/>
				<ExportButton data={csv} color={color} textColor={textColor} highlightHover={highlightHover} disableHover={disableHover} />
			</div>
		);
	}
}

SiteDashboard.propTypes = {
	ranges: PropTypes.array,
	toggleDateRangePicker: PropTypes.func,
	textColor: PropTypes.string,
	color: PropTypes.string,
	csv: PropTypes.array,
	highlightHover: PropTypes.bool,
	disableHover: PropTypes.bool,
	handleClickRun: PropTypes.func,
	backgroundColor: PropTypes.string,
};

SiteDashboard.defaultProps = {
	ranges: [],
	toggleDateRangePicker: null,
	textColor: '',
	color: '',
	csv: [],
	highlightHover: false,
	disableHover: false,
	handleClickRun: null,
	backgroundColor: '',
};

export default withStyles(style)(SiteDashboard);
