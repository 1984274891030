import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Grid } from '@material-ui/core';
import ValuesSegments from './ValuesSegments';
import TechnologyAdoptionSegments from './TechnologyAdoptionSegments';
import Progressiveness from '../../Progressiveness';
import MainGoal from '../../MainGoal';

import useStyles from './styles';

const WalkWithMeSection = (props) => {
	const { data, colourTheme } = props;

	const classes = useStyles(props);

	return (
		<>
			<Grid item xs={12}>
				<div className={classNames(classes.sectionHeader)}>
					<h1 className={classes.sectionHeaderText}>{data.headingText}</h1>
				</div>
			</Grid>
			<Grid item container xs={12}>
				<Grid item xs={12} lg={6}>
					<ValuesSegments data={data.valuesSegments} colourTheme={colourTheme} />
				</Grid>
				<Grid item xs={12} lg={6}>
					<TechnologyAdoptionSegments data={data.technologyAdoptionSegments} colourTheme={colourTheme} />
				</Grid>
			</Grid>
			<Grid item container xs={12}>
				<Grid item xs={12} lg={6}>
					<Progressiveness data={data.progressiveness} colourTheme={colourTheme} />
				</Grid>
				<Grid item xs={12} lg={6}>
					<MainGoal data={data.goals} colourTheme={colourTheme} />
				</Grid>
			</Grid>
		</>
	);
};

WalkWithMeSection.propTypes = {
	data: PropTypes.shape({
		headingText: PropTypes.string,
		valuesSegments: PropTypes.object,
		technologyAdoptionSegments: PropTypes.object,
		progressiveness: PropTypes.object,
		goals: PropTypes.object,
	}),
	colourTheme: PropTypes.object,
};

WalkWithMeSection.defaultProps = {
	data: {
		headingText: '',
		valuesSegments: null,
		technologyAdoptionSegments: null,
		progressiveness: null,
		goals: null,
	},
	colourTheme: null,
};

export default WalkWithMeSection;
