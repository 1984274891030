import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
	textfieldHead: {
		width: '100%',
		lineHeight: '20px',
		marginBottom: (props) => (props.marginBottom === undefined || props.marginBottom === null ? 20 : props.marginBottom),
	},
	textfieldTitle: {
		fontSize: 16,
		margin: 0,
		textTransform: 'capitalize',
		fontWeight: 300,
		lineHeight: '29px',
	},
	wrapper: {
		display: 'flex',
		width: '100%',
		height: 1,
	},
	space: {
		height: 1,
		width: 'calc(100% - 10px)',
		backgroundColor: '#2774AE',
	},
	triangle: {
		width: 0,
		height: 0,
		borderBottom: '1px solid transparent',
		borderLeft: '10px solid #2774AE',
	},
}));
