import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import AveragePeakTimeLineChart from '../../Charts/AveragePeakTimeLineChart';
import SiteDashboardHeader from '../../Headers/SiteDashboardHeader';
import CustomLoader from '../../CustomLoader';
import ErrorMessageSpan from '../../TextFields/ErrorMessageSpan';
import HyveCard from '../../CustomCard';
import { Colors } from '../../../constants/Constants.json';
import images from '../../../components/Icons';
import { getSitePopularityByDayReportData } from '../../../helpers/utility';

class AveragePeakTimesByDay extends React.Component {
	state = {
		data: [],
	};

	componentDidMount() {
		const { currentSiteReportPopularityByDay } = this.props;
		if (currentSiteReportPopularityByDay.size > 0) {
			this.setState({
				data: getSitePopularityByDayReportData(currentSiteReportPopularityByDay),
			});
		}
	}

	componentDidUpdate(prevProps) {
		const { currentSiteReportPopularityByDay } = this.props;
		if (currentSiteReportPopularityByDay.size > 0 && !isEqual(prevProps.currentSiteReportPopularityByDay, currentSiteReportPopularityByDay)) {
			this.setState({
				data: getSitePopularityByDayReportData(currentSiteReportPopularityByDay),
			});
		}
	}

	render() {
		const { startDate, endDate, ranges, timeRange, title, colorHeader, isCentrePage, errorMessageRPBDay, RPBDayLoader } = this.props;
		const { data } = this.state;

		return (
			<div style={{ height: '100%', width: '100%' }} data-testid='average-peak-times-by-day'>
				<HyveCard isCentrePage={isCentrePage}>
					<SiteDashboardHeader
						title={title}
						icon={images.peak}
						timeRange={timeRange}
						startDateProps={startDate}
						endDateProps={endDate}
						ranges={ranges}
						color={isCentrePage ? Colors[16] : colorHeader}
						textColor={isCentrePage ? '#000' : null}
						fontSize={isCentrePage ? '14px' : null}
						border={isCentrePage ? 'none' : null}
						isCentrePage={isCentrePage}
					/>
					<div style={{ width: '100%', height: 'calc(100% - 60px' }} data-testid='average-peak-times-by-day-chart'>
						{RPBDayLoader ? (
							<CustomLoader size={40} />
						) : errorMessageRPBDay ? (
							<ErrorMessageSpan text={errorMessageRPBDay} />
						) : (
							data.length > 0 && (
								<AveragePeakTimeLineChart
									data={data}
									category='timerange'
									id='average_peak'
									paddingTop={isCentrePage ? 20 : 30}
									isCentrePage={isCentrePage}
								/>
							)
						)}
					</div>
				</HyveCard>
			</div>
		);
	}
}

AveragePeakTimeLineChart.propTypes = {
	startDate: PropTypes.string,
	endDate: PropTypes.string,
	ranges: PropTypes.array,
	timeRange: PropTypes.array,
	title: PropTypes.string,
	colorHeader: PropTypes.string,
	isCentrePage: PropTypes.bool,
};

AveragePeakTimeLineChart.defaultProps = {
	startDate: '',
	endDate: '',
	ranges: [],
	timeRange: [],
	title: 'Average Peak Times by Day of Week',
	colorHeader: '#f1a8a6',
	isCentrePage: false,
};

const mapStateToProps = (state) => ({
	currentSiteReportPopularityByDay: state.sitesReducer.get('currentSiteReportPopularityByDay'),
	errorMessageRPBDay: state.sitesReducer.get('errorMessageRPBDay'),
	RPBDayLoader: state.sitesReducer.get('RPBDayLoader'),
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AveragePeakTimesByDay);
