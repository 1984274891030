import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import SocioEconomics from '../SocioEconomics';
import Likes from '../Likes';

import useStyles from './styles';

const LikesAndSocioEconomic = (props) => {
	const classes = useStyles(props);

	const { likes, socioEconomic, colourTheme } = props;

	return (
		<div className={classNames(classes.likesAndSocioEconomic)}>
			<Likes likes={likes} colourTheme={colourTheme} />
			<SocioEconomics socioEconomic={socioEconomic} colourTheme={colourTheme} />
		</div>
	);
};

LikesAndSocioEconomic.propTypes = {
	likes: PropTypes.object,
	socioEconomic: PropTypes.object,
	colourTheme: PropTypes.object,
};

LikesAndSocioEconomic.defaultProps = {
	likes: null,
	socioEconomic: null,
	colourTheme: null,
};

export default LikesAndSocioEconomic;
