import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
	description: {
		display: 'flex',
		flexDirection: 'column',
		padding: '40px',
		'& p': {
			color: '#ffffff',
			fontWeight: 'bold',
		},
	},
	colParagraph: {
		flexBasis: '50%',
		margin: 'auto',
		alignItems: 'center',
		justifyContent: 'center',
		float: 'left',
		height: '100%',
	},
}));
