import { makeStyles } from '@material-ui/styles';

export default makeStyles({
	likes: {
		padding: '20px 0px 20px 20px',
		backgroundColor: '#000000',
		color: '#ffffff !important',
	},
	statTitle: {
		fontSize: '16px',
		fontWeight: '600',
		textTransform: 'uppercase',
		color: '#ffffff',
		marginBottom: '20px',
	},
});
