import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import useStyles from './styles';

const CustomButton = (props) => {
	const { children, disabled = false, handleClickAction, variant = 'outlined' } = props;

	const classes = useStyles(props);
	return (
		<Button
			className={classes.root}
			disabled={disabled}
			variant={variant}
			size='small'
			onClick={typeof handleClickAction === 'function' ? () => handleClickAction() : null}
			data-testid='custom-button'
		>
			{children}
		</Button>
	);
};

CustomButton.propTypes = {
	disabled: PropTypes.bool,
	handleClickAction: PropTypes.func,
	variant: PropTypes.string,
};

CustomButton.defaultProps = {
	disabled: false,
	handleClickAction: null,
	variant:'outlined'
};

export default CustomButton;
