import React from 'react';
import { connect } from 'react-redux';
import { censusRadius, getAvailableSites } from '../../helpers/utility';
import { fetchContactSiteList, selectRadius, selectSite } from '../../redux/actions/contactsActions';
import { fetchCensusDashboardData } from '../../redux/actions/censusDataActions';
import CensusDataPageTemplate from '../../templates/censusDataPageTemplate/index.template';

class CensusDataPageContainer extends React.Component {
	constructor(props) {
		super(props);
		this.wrapperRef = React.createRef();
		this.state = {
			selectedSiteId: '',
			selectedSiteName: '',
			selectedSite: {},
			sites: [],
			selectedRadius: censusRadius[0],
		};
	}

	componentDidMount() {
		const { userInfo, fetchContactSiteList, contactSites, selectedSite, selectedRadius } = this.props;

		if (userInfo.size !== 0 && userInfo.get('contact_id') !== undefined && userInfo.get('contact_id') !== null) {
			fetchContactSiteList(userInfo.get('contact_id'));
		}
		if (contactSites.size > 0) {
			const sites = getAvailableSites(contactSites, 'id', true);
			const selectedContactSite = selectedSite || sites[0];
			const selectedRadiusValue = selectedRadius || censusRadius[0];
			sites.length > 0 &&
				this.setState({
					sites,
					selectedSite: selectedContactSite,
					selectedSiteId: selectedContactSite.id,
					selectedSiteName: selectedContactSite.site_name,
					selectedRadius: selectedRadiusValue,
				});
		}
	}

	componentDidUpdate(prevProps) {
		const { contactSites, fetchCensusDashboardData, selectedSite, selectedRadius } = this.props;

		if (prevProps.contactSites !== contactSites) {
			if (contactSites.size > 0) {
				const sites = getAvailableSites(contactSites, 'id', true);
				const selectedContactSite = selectedSite || sites[0];
				const selectedRadiusValue = selectedRadius || censusRadius[0];
				if (sites.length) {
					this.setState({
						sites,
						selectedSite: selectedContactSite,
						selectedSiteId: selectedContactSite.id,
						selectedSiteName: selectedContactSite.site_name,
						selectedRadius: selectedRadiusValue,
					});
					fetchCensusDashboardData(selectedContactSite.id, selectedRadiusValue.value);
				}
			}
		}
	}

	handleChangeSite = (value, site) => {
		const { fetchCensusDashboardData, selectSite } = this.props;
		const { selectedRadius } = this.state;
		if (value) {
			this.setState({ selectedSiteId: value, selectedSite: site, selectedSiteName: site.site_name });
			selectSite(site);
			fetchCensusDashboardData(value, selectedRadius.value);
		}
	};

	handleChangeRadius = (value, radius) => {
		const { fetchCensusDashboardData, selectRadius } = this.props;
		const { selectedSiteId } = this.state;
		if (value) {
			this.setState({ selectedRadius: radius });
			selectRadius(radius);
			fetchCensusDashboardData(selectedSiteId, value);
		}
	};

	render() {
		const { sites, selectedSiteId, selectedSiteName, selectedSite, selectedRadius } = this.state;
		const {
			pageLoader,
			// Population Data Props
			populationDataLoader,
			populationDataErrorMessage,
			genderPopulation,
			agePopulation,
			// Age Groups Data Props
			ageGroupsDataLoader,
			ageGroupsDataErrorMessage,
			ageGroupsData,
			// Work Occupation Data Props
			workOccupationDataLoader,
			workOccupationDataErrorMessage,
			workOccupationData,
			// Ethnicity And Languages Data Props
			ethnicityAndLanguagesDataLoader,
			ethnicityAndLanguagesDataErrorMessage,
			ethnicityData,
			languagesData,
			// Marital Status And Family Types Data Props
			maritalStatusAndFamilyTypesDataLoader,
			maritalStatusAndFamilyTypesDataErrorMessage,
			maritalStatusData,
			familyTypesData,
			// Property Ownership Data Props
			propertyOwnershipDataLoader,
			propertyOwnershipDataErrorMessage,
			propertyOwnershipData,
			monthlyMortgageRepaymentsData,
		} = this.props;

		return (
			<CensusDataPageTemplate
				pageLoader={pageLoader}
				selectedSiteId={selectedSiteId}
				selectedSiteName={selectedSiteName}
				sites={sites}
				handleChangeSite={this.handleChangeSite}
				siteKey='id'
				selectedSite={selectedSite}
				censusRadius={censusRadius}
				selectedCensusRadius={selectedRadius.value}
				handleChangeRadius={this.handleChangeRadius}
				// Population Data Props
				populationDataLoader={populationDataLoader}
				populationDataErrorMessage={populationDataErrorMessage}
				genderPopulation={genderPopulation}
				agePopulation={agePopulation}
				// Age Groups Data Props
				ageGroupsDataLoader={ageGroupsDataLoader}
				ageGroupsDataErrorMessage={ageGroupsDataErrorMessage}
				ageGroupsData={ageGroupsData}
				// Work Occupation Data Props
				workOccupationDataLoader={workOccupationDataLoader}
				workOccupationDataErrorMessage={workOccupationDataErrorMessage}
				workOccupationData={workOccupationData}
				// Ethnicity And Languages Data Props
				ethnicityAndLanguagesDataLoader={ethnicityAndLanguagesDataLoader}
				ethnicityAndLanguagesDataErrorMessage={ethnicityAndLanguagesDataErrorMessage}
				ethnicityData={ethnicityData}
				languagesData={languagesData}
				// Marital Status And Family Types Data Props
				maritalStatusAndFamilyTypesDataLoader={maritalStatusAndFamilyTypesDataLoader}
				maritalStatusAndFamilyTypesDataErrorMessage={maritalStatusAndFamilyTypesDataErrorMessage}
				maritalStatusData={maritalStatusData}
				familyTypesData={familyTypesData}
				// Property Ownership Data Props
				propertyOwnershipDataLoader={propertyOwnershipDataLoader}
				propertyOwnershipDataErrorMessage={propertyOwnershipDataErrorMessage}
				propertyOwnershipData={propertyOwnershipData}
				monthlyMortgageRepaymentsData={monthlyMortgageRepaymentsData}
			/>
		);
	}
}

const mapStateToProps = (state) => ({
	userInfo: state.authReducer.get('userInfo'),
	pageLoader: state.contactsReducer.get('fetchContactSitesLoader'),
	contactSites: state.contactsReducer.get('contactSites'),
	selectedSite: state.contactsReducer.get('selectedSite'),
	selectedRadius: state.contactsReducer.get('selectedRadius'),

	// Population Data Props
	populationDataLoader: state.censusDataReducer.get('populationDataLoader'),
	populationDataErrorMessage: state.censusDataReducer.get('populationDataErrorMessage'),
	genderPopulation: state.censusDataReducer.get('genderPopulation'),
	agePopulation: state.censusDataReducer.get('agePopulation'),

	// Age Groups Data Props
	ageGroupsDataLoader: state.censusDataReducer.get('ageGroupsDataLoader'),
	ageGroupsDataErrorMessage: state.censusDataReducer.get('ageGroupsDataErrorMessage'),
	ageGroupsData: state.censusDataReducer.get('ageGroupsData'),

	// Work Occupation Data Props
	workOccupationDataLoader: state.censusDataReducer.get('workOccupationDataLoader'),
	workOccupationDataErrorMessage: state.censusDataReducer.get('workOccupationDataErrorMessage'),
	workOccupationData: state.censusDataReducer.get('workOccupationData'),

	// Ethnicity And Languages Data Props
	ethnicityAndLanguagesDataLoader: state.censusDataReducer.get('ethnicityAndLanguagesDataLoader'),
	ethnicityAndLanguagesDataErrorMessage: state.censusDataReducer.get('ethnicityAndLanguagesDataErrorMessage'),
	ethnicityData: state.censusDataReducer.get('ethnicityData'),
	languagesData: state.censusDataReducer.get('languagesData'),

	// Marital Status And Family Types Data Props
	maritalStatusAndFamilyTypesDataLoader: state.censusDataReducer.get('maritalStatusAndFamilyTypesDataLoader'),
	maritalStatusAndFamilyTypesDataErrorMessage: state.censusDataReducer.get('maritalStatusAndFamilyTypesDataErrorMessage'),
	maritalStatusData: state.censusDataReducer.get('maritalStatusData'),
	familyTypesData: state.censusDataReducer.get('familyTypesData'),

	// Property Ownership Data Props
	propertyOwnershipDataLoader: state.censusDataReducer.get('propertyOwnershipDataLoader'),
	propertyOwnershipDataErrorMessage: state.censusDataReducer.get('propertyOwnershipDataErrorMessage'),
	propertyOwnershipData: state.censusDataReducer.get('propertyOwnershipData'),
	monthlyMortgageRepaymentsData: state.censusDataReducer.get('monthlyMortgageRepaymentsData'),
});

export const mapDispatchToProps = (dispatch) => ({
	fetchContactSiteList: (contactId) => dispatch(fetchContactSiteList(contactId)),
	fetchCensusDashboardData: (siteId, censusRadius) => dispatch(fetchCensusDashboardData(siteId, censusRadius)),
	selectSite: (selectedSite) => dispatch(selectSite(selectedSite)),
	selectRadius: (selectedRadius) => dispatch(selectRadius(selectedRadius)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CensusDataPageContainer);
