import { makeStyles } from '@material-ui/styles';

export default makeStyles({
	likesItem: {
		textAlign: 'center',
		display: 'flex',
		flexFlow: 'column nowrap',
		alignItems: 'center',
		padding: '20px',
	},
	likesItemIcon: {
		lineHeight: '30px',
		float: 'left',
		fill: (props) => props.color || '#ffffff',
		'& div': {
			'& svg': {
				width: 60,
				height: 60,
			},
		},
		stroke: (props) => props.color || '#ffffff',
	},
	likeLabel: {
		color: (props) => props.color || '#ffffff',
	},
});
