import React from 'react';
import PropTypes from 'prop-types';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames/bind';
import useStyles, { styles } from './styles';

const CustomExpansionPanelSummary = withStyles(styles)(AccordionSummary);

const ExpansionPanelSummary = (props) => {
	const { expandIcon, children } = props;
	const classes = useStyles(props);
	return (
		<CustomExpansionPanelSummary
			aria-controls='panel1bh-content'
			expandIcon={expandIcon}
			className={classNames(classes.root, classes.expanded)}
			data-testid='expansion-panel-summary'
		>
			{children}
		</CustomExpansionPanelSummary>
	);
};

ExpansionPanelSummary.propTypes = {
	expandIcon: PropTypes.element,
};

ExpansionPanelSummary.defaultProps = {
	expandIcon: null,
};

export default ExpansionPanelSummary;
