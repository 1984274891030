export const responseHandler = (response) => {
	switch (response.status) {
		case 201:
		case 200:
			return Promise.resolve(response.data || { message: 'Success' });

		case 204:
			return Promise.reject({ response });

		default:
			return Promise.resolve({ message: 'Success' });
	}
};

export const errorHandler = (error) => {
	console.log(error.response);
	if (error.response && error.response.status) {
		switch (error.response.status) {
			case 403:
				return Promise.reject({ message: error.response.data.message || 'Expired token' });

			case 422:
				return Promise.reject({ message: error.response.data.message || 'Unprocessable entity' });

			case 401:
				return Promise.reject({ message: error.response.data.message || 'Missing Authentication Token' });

			case 404:
				return Promise.reject({ message: error.response.data.message || 'No data found' });

			case 400:
			case 409:
				return Promise.reject({ message: error.response.data.message || 'Invalid request body' });

			case 204:
				return Promise.reject({ status: 204, message: 'No data found' });

			default:
				return Promise.reject({ message: 'Invalid request body' });
		}
	} else {
		return Promise.reject({ message: 'Network error' });
	}
};

