import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import DetailedSinglePieChart from '../../Charts/DetailedSinglePieChart';
import CustomLoader from '../../CustomLoader';
import ErrorMessageSpan from '../../TextFields/ErrorMessageSpan';
import CustomCard from '../../CustomCard';
import SiteDashboardHeader from '../../Headers/SiteDashboardHeader';
import { Colors } from '../../../constants/Constants.json';
import images from '../../Icons';

import useStyles from './styles';

const EthnicityAndLanguagesChart = (props) => {
	const classes = useStyles(props);

	const { ethnicityAndLanguagesDataLoader, ethnicityAndLanguagesDataErrorMessage, ethnicityData, languagesData } = props;

	return (
		<CustomCard isCentrePage={true}>
			<SiteDashboardHeader title='ETHNICITY AND LANGUAGES' color={Colors[16]} textColor={'#000'} isCentrePage={true} icon={images.topApps} />
			<div className={classes.paperContainer}>
				{ethnicityAndLanguagesDataLoader ? (
					<CustomLoader size={40} />
				) : ethnicityAndLanguagesDataErrorMessage ? (
					<ErrorMessageSpan text={ethnicityAndLanguagesDataErrorMessage} />
				) : (
					<Grid container spacing={2} justifyContent='center'>
						<Grid item xs={6}>
							<DetailedSinglePieChart
								id='ethnicity_chart'
								data={ethnicityData}
								categoryX='label'
								valueY='count'
								isLegend={true}
								position='right'
								valign='middle'
								contentAlign='center'
								width={120}
								paddingBottom={10}
								paddingLeft={10}
								paddingTop={10}
								legendPaddingLeft={10}
								colors={['#2774AE', '#00C2A2', '#FFC915', '#DF4661', '#7FA0AC', '#A7E6D7', '#F1EB9C', '#56B7E6']}
								footerText='Ethnicity'
								isPercent={true}
								showLabelsInside={true}
								showLabels={false}
							/>
						</Grid>
						<Grid item xs={6}>
							<DetailedSinglePieChart
								id='languages_chart'
								data={languagesData}
								categoryX='label'
								valueY='count'
								isLegend={true}
								position='right'
								valign='middle'
								contentAlign='center'
								width={120}
								paddingBottom={10}
								paddingLeft={10}
								paddingTop={10}
								legendPaddingLeft={10}
								colors={['#2774AE', '#00C2A2', '#FFC915', '#DF4661', '#7FA0AC', '#A7E6D7', '#F1EB9C', '#56B7E6']}
								footerText='Languages'
								isPercent={true}
								showLabelsInside={true}
								showLabels={false}
							/>
						</Grid>
					</Grid>
				)}
			</div>
		</CustomCard>
	);
};

EthnicityAndLanguagesChart.propTypes = {
	ethnicityAndLanguagesDataLoader: PropTypes.bool,
	ethnicityAndLanguagesDataErrorMessage: PropTypes.string,
	ethnicityData: PropTypes.array,
	languagesData: PropTypes.array,
};

EthnicityAndLanguagesChart.defaultProps = {
	ethnicityAndLanguagesDataLoader: false,
	ethnicityAndLanguagesDataErrorMessage: '',
	ethnicityData: [],
	languagesData: [],
};

export default EthnicityAndLanguagesChart;
