import * as ACTION_TYPES from '../../constants/ActionTypes';

export function fetchPersonas(siteId, radius) {
	return {
		type: ACTION_TYPES.FETCH_PERSONAS,
		siteId,
		radius,
	};
}

export function fetchPersonasDone(data) {
	return {
		type: ACTION_TYPES.FETCH_PERSONAS_DONE,
		data,
	};
}

export function fetchPersonasFailed(error) {
	return {
		type: ACTION_TYPES.FETCH_PERSONAS_FAILED,
		error,
	};
}

