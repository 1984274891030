import React from 'react';
import { unmountComponentAtNode } from 'react-dom';
import { render } from '../../../tests/test-utils';
import SingleMenuItemSummary from './index';
import images from '../../../components/Icons';

let container = null;

describe('SingleMenuItemSummary', () => {
	beforeEach(() => {
		// setup a DOM element as a render target
		container = document.createElement('div');
		document.body.appendChild(container);
	});

	afterEach(() => {
		// cleanup on exiting
		unmountComponentAtNode(container);
		container.remove();
		container = null;
	});

	it('should load SingleMenuItemSummary', () => {
		const { asFragment } = render(<SingleMenuItemSummary />, container);
		expect(asFragment()).toMatchSnapshot();
	});

	it('should load SingleMenuItemSummary with name', () => {
		const name = 'name';
		const isShowName = true;
		const { asFragment, getByTestId } = render(<SingleMenuItemSummary name={name} isShowName={isShowName} />, container);
		expect(asFragment()).toMatchSnapshot();
		expect(getByTestId('single-menu-item-summary').textContent).toEqual(name);
	});

	it('should load SingleMenuItemSummary with icon', () => {
		const { asFragment, getByTestId, queryByTestId } = render(<SingleMenuItemSummary icon={images.camera} />, container);
		expect(asFragment()).toMatchSnapshot();
		expect(getByTestId('single-menu-item-summary-svg')).toBeTruthy();
		expect(queryByTestId('single-menu-item-summary-lockedPath')).toBeNull();
		expect(queryByTestId('single-menu-item-summary-path')).toBeNull();
	});
});
