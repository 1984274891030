import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { tooltipStyle } from '../../constants/Theme';
import { rootStyle } from './styles';

const BlackOnWhiteTooltip = withStyles(rootStyle)(Tooltip);

const CustomTooltip = (props) => {
	const { classes, children, title, placement = 'top', arrow = true, head } = props;
	return (
		<BlackOnWhiteTooltip
			placement={placement}
			title={
				head ? (
					<>
						<h3 className={classes.head}>{head}</h3>
						<div className={classes.divider} />
						<h5 className={classes.description}>{title}</h5>
					</>
				) : (
					<h5 className={classes.description}>{title}</h5>
				)
			}
			arrow={arrow}
			data-testid='custom-tooltip'
		>
			{children}
		</BlackOnWhiteTooltip>
	);
};

CustomTooltip.propTypes = {
	title: PropTypes.string.isRequired,
	placement: PropTypes.string,
	arrow: PropTypes.bool,
	head: PropTypes.string
};

CustomTooltip.defaultProps = {
	title: '',
	placement: 'top',
	arrow: true,
	head: '',
};

export default withStyles(tooltipStyle)(CustomTooltip);
