import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
	textViewContainer: {
		padding: '10px',
		width: '100%',
		lineHeight: 1.6,
		'& h2': {
			fontWeight: 'bold',
			fontSize: '24px',
		},
		'& h3': {
			fontWeight: 'normal',
			fontSize: '12px',
			color: '#716e6a',
		},
	},
	secondaryTextContainer: {
		marginTop: '5px',
	},
}));
