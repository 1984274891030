import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CustomLoader from '../../../components/CustomLoader';
import KnowMeSection from '../../../components/RoyMorganDashboard/Personas/KnowMeSection';
import WalkWithMeSection from '../../../components/RoyMorganDashboard/Personas/WalkWithMeSection';
import ReachMeSection from '../../../components/RoyMorganDashboard/Personas/ReachMeSection';
import ShopWithMeSection from '../../../components/RoyMorganDashboard/Personas/ShopWithMeSection';
import RelaxWithMeSection from '../../../components/RoyMorganDashboard/Personas/RelaxWithMeSection';
import PersonasHeader from '../../../components/RoyMorganDashboard/Personas/Header';
import FilterComponent from '../../../components/RoyMorganDashboard/Personas/FilterComponent';

import useStyles from './styles';

const PersonaPageTemplate = (props) => {
	const { pageLoader, personasData, selectedSiteId, sites, handleChangeSite, siteKey, selectedSiteName, radius, selectedRadius, handleChangeRadius } = props;
	const classes = useStyles(props);

	if (pageLoader) {
		return (
			<div className={classes.pageLoader}>
				<CustomLoader site={64} />
			</div>
		);
	} else {
		return (
			<div className={classes.censusDataPageWrapper}>
				<div className={classes.censusDataPageLayout}>
					<Grid container>
						<Grid item container direction='row' xs={12}>
							<FilterComponent
								selectedSiteId={selectedSiteId}
								sites={sites}
								label='Centre'
								handleChangeSite={handleChangeSite}
								siteKey={siteKey}
								radius={radius}
								selectedRadius={selectedRadius}
								handleChangeRadius={handleChangeRadius}
							/>
						</Grid>
						{personasData['header'] === undefined ||
						personasData['knowMeSectionData'] === undefined ||
						personasData['walkWithMeSectionData'] === undefined ||
						personasData['reachMeSectionData'] === undefined ||
						personasData['shopWithMeSectionData'] === undefined ||
						personasData['relaxWithMeSectionData'] === undefined ||
						personasData['footer'] === undefined ||
						personasData['colourTheme'] === undefined ? (
							<div className={classes.noDataSection}>
								<div className={classes.noDataIcon}>
									<ErrorOutlineIcon fontSize='large' />
								</div>
								<div>
									<h2>No data found</h2>
								</div>
							</div>
						) : (
							<>
								<Grid item container direction='row' xs={12}>
									<PersonasHeader data={personasData.header} colourTheme={personasData.colourTheme} />
								</Grid>
								<Grid item container direction='row' xs={12}>
									<KnowMeSection
										data={personasData.knowMeSectionData}
										colourTheme={personasData.colourTheme}
										selectedSiteName={selectedSiteName}
									/>
								</Grid>
								<Grid item container direction='row' xs={12}>
									<WalkWithMeSection data={personasData.walkWithMeSectionData} colourTheme={personasData.colourTheme} />
								</Grid>
								<Grid item container direction='row' xs={12} className={classes.sectionBlack}>
									<ReachMeSection data={personasData.reachMeSectionData} colourTheme={personasData.colourTheme} />
								</Grid>
								<Grid item container direction='row' xs={12}>
									<ShopWithMeSection data={personasData.shopWithMeSectionData} colourTheme={personasData.colourTheme} />
								</Grid>
								<Grid item container direction='row' xs={12} className={classes.sectionBlack}>
									<RelaxWithMeSection
										data={personasData.relaxWithMeSectionData}
										footer={personasData.footer}
										colourTheme={personasData.colourTheme}
									/>
								</Grid>
							</>
						)}
					</Grid>
				</div>
			</div>
		);
	}
};

PersonaPageTemplate.propTypes = {
	pageLoader: PropTypes.bool,
	sites: PropTypes.array,
	selectedSiteId: PropTypes.string,
	handleChangeSite: PropTypes.func,
	siteKey: PropTypes.string,
	selectedSite: PropTypes.object,
	radius: PropTypes.array,
	selectedRadius: PropTypes.string,
	handleChangeRadius: PropTypes.func,
};

PersonaPageTemplate.defaultProps = {
	pageLoader: false,
	sites: [],
	selectedSiteId: '',
	handleChangeSite: null,
	siteKey: 'id',
	selectedSite: {},
	radius: [],
	selectedRadius: null,
	handleChangeRadius: null,
};

export default PersonaPageTemplate;
