import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import CustomLoader from '../../CustomLoader';
import ErrorMessageSpan from '../../TextFields/ErrorMessageSpan';
import DetailedColumnBarChart from '../../Charts/DetailedColumnBarChart';
import CustomCard from '../../CustomCard';
import SiteDashboardHeader from '../../Headers/SiteDashboardHeader';
import { Colors } from '../../../constants/Constants.json';
import images from '../../Icons';

import useStyles from './styles';

const AgeGroupsChart = (props) => {
	const classes = useStyles(props);

	const { ageGroupsDataLoader, ageGroupsDataErrorMessage, ageGroupsData } = props;

	return (
		<CustomCard isCentrePage={true}>
			<SiteDashboardHeader title='AGE GROUPS' color={Colors[16]} textColor={'#000'} isCentrePage={true} icon={images.audiencesGrey} />
			<div className={classes.paperContainer}>
				{ageGroupsDataLoader ? (
					<CustomLoader size={40} />
				) : ageGroupsDataErrorMessage ? (
					<ErrorMessageSpan text={ageGroupsDataErrorMessage} />
				) : (
					<Grid container spacing={2} justifyContent='center'>
						<DetailedColumnBarChart
							id='age_group_chart'
							data={ageGroupsData}
							category='label'
							paddingTop={20}
							paddingRight={20}
							paddingBottom={0}
							barWidth={80}
							isCentrePage={true}
							valueY='count'
							color={Colors[10]}
							tooltipText='Age_{categoryX}_yr_P: [bold]{valueY}[/]'
							isBullet={true}
						/>
					</Grid>
				)}
			</div>
		</CustomCard>
	);
};

AgeGroupsChart.propTypes = {
	ageGroupsDataLoader: PropTypes.bool,
	ageGroupsDataErrorMessage: PropTypes.string,
	ageGroupsData: PropTypes.array,
};

AgeGroupsChart.defaultProps = {
	ageGroupsDataLoader: false,
	ageGroupsDataErrorMessage: '',
	ageGroupsData: [],
};

export default AgeGroupsChart;
