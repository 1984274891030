import React from 'react';
import SignInPageContainer from '../../containers/signIn/signInPageContainer';

class SignInPage extends React.Component {
	render() {
		return <SignInPageContainer {...this.props} />;
	}
}

export default SignInPage;
