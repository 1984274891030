import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import useStyles from './styles';

const Description = (props) => {
	const classes = useStyles(props);

	const { description } = props;

	return <div className={classNames(classes.colParagraph, classes.description)} dangerouslySetInnerHTML={{ __html: description }} />;
};

Description.propTypes = {
	description: PropTypes.string,
};

Description.defaultProps = {
	description: '',
};

export default Description;
