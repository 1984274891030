import React from 'react';
import PropTypes from 'prop-types';
import ReactSvg from 'react-svg';
import classNames from 'classnames/bind';
import InfoIcon from '@material-ui/icons/Info';
import DateRangeIcon from '@material-ui/icons/DateRange';
import styles from './styles.scss';
import CustomTooltip from '../../CustomTooltip';
import useStyles from './styles';
import { getTimeRangeDetails } from '../../../helpers/utility';

const cx = classNames.bind(styles);

const SiteDashboardHeader = (props) => {
	const { title, link, timeRange = 1, startDateProps, endDateProps, isWhite = false, ranges, isCentrePage = false, icon } = props;
	const classes = useStyles(props);

	const { infoTitle, endDate, startDate } = getTimeRangeDetails(timeRange, ranges, endDateProps, startDateProps);
	return (
		<div className={cx('siteDashboardHeader')}>
			<div className={cx('wrapperOutside')}>
				<div className={cx('floatWrapper')}>
					<div className={classes.siteDashboardTitle}>
						{link ? (
							<ReactSvg src={require(`${link}`)} className={classes.customIcon} data-testid='site-dashboard-header-svg' />
						) : icon ? (
							<ReactSvg src={icon} className={classes.customIcon} data-testid='site-dashboard-header-svg' />
						) : null}
						<span data-testid='site-dashboard-header-title'>{title}</span>
					</div>
					{!isCentrePage && (
						<div className={cx('duration')} data-testid='site-dashboard-header-duration'>
							<CustomTooltip head={title} title={infoTitle}>
								<InfoIcon className={cx('infoIcon')} />
							</CustomTooltip>
							<DateRangeIcon className={cx('dateIcon')} />
							<span
								className={cx('durationText', { whiteDurationText: isWhite })}
								data-testid='site-dashboard-header-duration-text'
							>{`${startDate} - ${endDate}`}</span>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

SiteDashboardHeader.propTypes = {
	title: PropTypes.string,
	link: PropTypes.string,
	timeRange: PropTypes.number,
	startDateProps: PropTypes.string,
	endDateProps: PropTypes.string,
	isWhite: PropTypes.bool,
	ranges: PropTypes.array,
	isCentrePage: PropTypes.bool,
	icon: PropTypes.string,
};

SiteDashboardHeader.defaultProps = {
	title: '',
	link: '',
	timeRange: 1,
	startDateProps: '',
	endDateProps: '',
	isWhite: false,
	ranges: [],
	isCentrePage: false,
	icon: '',
};

export default SiteDashboardHeader;
