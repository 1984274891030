import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { withStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CustomButton from '../../CustomButton';
import { textfieldStyle } from '../../../constants/Theme';
import css from './styles';

const style = { ...textfieldStyle, ...css };

class DataRangePickerButton extends React.PureComponent {
	render() {
		const { classes, ranges, toggleDateRangePicker } = this.props;

		return (
			<div
				role='button'
				tabIndex={0}
				className={classes.selectors}
				onClick={toggleDateRangePicker}
				onKeyDown={(event) => {
					if (event.keyCode === 13) {
						toggleDateRangePicker();
					}
				}}
				data-testid='data-range-picker-button'
			>
				{ranges && ranges.length > 0 && ranges[0].startDate && ranges[0].endDate ? (
					<CustomButton disableHover color='#c3c3c3'>
						<span className={classes.dateRangetext}>{`From ${moment(ranges[0].startDate, 'YYYY-MM-DD').format('MMM DD')} to ${moment(
							ranges[0].endDate,
							'YYYY-MM-DD'
						).format('MMM DD')}`}</span>
						<ArrowDropDownIcon className={classes.dateRangeIcon} />
					</CustomButton>
				) : (
					<CustomButton>EditRange</CustomButton>
				)}
			</div>
		);
	}
}

DataRangePickerButton.propTypes = {
	ranges: PropTypes.array,
	toggleDateRangePicker: PropTypes.func,
};

DataRangePickerButton.defaultProps = {
	ranges: [],
	toggleDateRangePicker: null,
};

export default withStyles(style)(DataRangePickerButton);
