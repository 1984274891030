export const SIGNIN_REQUEST = 'SIGNIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const INPUT_LOGIN_USER = 'INPUT_LOGIN_USER';
export const INPUT_LOGIN_PASSWORD = 'INPUT_LOGIN_PASSWORD';
export const INPUT_LOGIN_PREFERRED_NAME = 'INPUT_LOGIN_PREFERRED_NAME';
export const CLEAR_LOGIN_PASSWORD = 'CLEAR_LOGIN_PASSWORD';

export const SIGNOUT_REQUEST = 'SIGNOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';

export const SET_FIRST_TIME_LOGIN = 'SET_FIRST_TIME_LOGIN';

export const GET_USER_PERMISSIONS = 'GET_USER_PERMISSIONS';
export const GET_USER_PERMISSIONS_DONE = 'GET_USER_PERMISSIONS_DONE';
export const GET_USER_PERMISSIONS_FAILED = 'GET_USER_PERMISSIONS_FAILED';

export const FETCH_OWNER_USER_INFO = 'FETCH_OWNER_USER_INFO';
export const FETCH_OWNER_USER_INFO_DONE = 'FETCH_OWNER_USER_INFO_DONE';
export const FETCH_OWNER_USER_INFO_FAILED = 'FETCH_OWNER_USER_INFO_FAILED';

export const RESET_USER_PREFERENCE_INFO = 'RESET_USER_PREFERENCE_INFO';

export const TOGGLE_RESET_USER_PASSWORD_MODAL = 'TOGGLE_RESET_USER_PASSWORD_MODAL';
export const INPUT_RESET_USER_PASSWORD = 'INPUT_RESET_USER_PASSWORD';
export const INPUT_RESET_USER_PASSWORD_AGAIN = 'INPUT_RESET_USER_PASSWORD_AGAIN';
export const CLOSE_SNACKBAR_MODAL = 'CLOSE_SNACKBAR_MODAL';

export const RESET_USER_PASSWORD = 'RESET_USER_PASSWORD';
export const RESET_USER_PASSWORD_DONE = 'RESET_USER_PASSWORD_DONE';
export const RESET_USER_PASSWORD_FAILED = 'RESET_USER_PASSWORD_FAILED';

export const TOGGLE_MAIN_MENU_EXPANSION = 'TOGGLE_MAIN_MENU_EXPANSION';
export const CLICK_MAIN_MENU_ITEM = 'CLICK_MAIN_MENU_ITEM';
export const TOGGLE_SUB_MENU_EXPANSION_PANEL = 'TOGGLE_SUB_MENU_EXPANSION_PANEL';

export const TOGGLE_APP_THEME = 'TOGGLE_APP_THEME';

export const CURRENT_SITE_SELECTED = 'CURRENT_SITE_SELECTED';

export const CENTRE_DASHBOARD_REPORT = 'CENTRE_DASHBOARD_REPORT';

export const SITE_REPORT_MONTHLY_STATS_DONE = 'SITE_REPORT_MONTHLY_STATS_DONE';
export const SITE_REPORT_MONTHLY_STATS_FAILED = 'SITE_REPORT_MONTHLY_STATS_FAILED';

export const SITE_REPORT_VISITS_BY_DAY = 'SITE_REPORT_VISITS_BY_DAY';
export const SITE_REPORT_VISITS_BY_DAY_DONE = 'SITE_REPORT_VISITS_BY_DAY_DONE';
export const SITE_REPORT_VISITS_BY_DAY_FAILED = 'SITE_REPORT_VISITS_BY_DAY_FAILED';

export const SITE_REPORT_VISITS_BY_DATE = 'SITE_REPORT_VISITS_BY_DATE';
export const SITE_REPORT_VISITS_BY_DATE_DONE = 'SITE_REPORT_VISITS_BY_DATE_DONE';
export const SITE_REPORT_VISITS_BY_DATE_FAILED = 'SITE_REPORT_VISITS_BY_DATE_FAILED';

export const SITE_REPORT_POPULARITY_BY_DAY_DONE = 'SITE_REPORT_POPULARITY_BY_DAY_DONE';
export const SITE_REPORT_POPULARITY_BY_DAY_FAILED = 'SITE_REPORT_POPULARITY_BY_DAY_FAILED';

export const SITE_REPORT_DEMOGRAPHICS_DONE = 'SITE_REPORT_DEMOGRAPHICS_DONE';
export const SITE_REPORT_DEMOGRAPHICS_FAILED = 'SITE_REPORT_DEMOGRAPHICS_FAILED';

export const FETCH_CONTACT_SITE_LIST = 'FETCH_CONTACT_SITE_LIST';
export const FETCH_CONTACT_SITE_LIST_DONE = 'FETCH_CONTACT_SITE_LIST_DONE';
export const FETCH_CONTACT_SITE_LIST_FAILED = 'FETCH_CONTACT_SITE_LIST_FAILED';

export const SELECT_SITE = 'SELECT_SITE';
export const SELECT_RADIUS = 'SELECT_RADIUS';

export const FETCH_CENSUS_DASHBOARD_DATA = 'FETCH_CENSUS_DASHBOARD_DATA';

export const FETCH_POPULATION_DATA = 'FETCH_POPULATION_DATA';
export const FETCH_POPULATION_DATA_DONE = 'FETCH_POPULATION_DATA_DONE';
export const FETCH_POPULATION_DATA_FAILED = 'FETCH_POPULATION_DATA_FAILED';

export const FETCH_AGE_GROUPS_DATA = 'FETCH_AGE_GROUPS_DATA';
export const FETCH_AGE_GROUPS_DATA_DONE = 'FETCH_AGE_GROUPS_DATA_DONE';
export const FETCH_AGE_GROUPS_DATA_FAILED = 'FETCH_AGE_GROUPS_DATA_FAILED';

export const FETCH_WORK_OCCUPATION_DATA = 'FETCH_WORK_OCCUPATION_DATA';
export const FETCH_WORK_OCCUPATION_DATA_DONE = 'FETCH_WORK_OCCUPATION_DATA_DONE';
export const FETCH_WORK_OCCUPATION_DATA_FAILED = 'FETCH_WORK_OCCUPATION_DATA_FAILED';

export const FETCH_ETHNICITY_AND_LANGUAGES_DATA = 'FETCH_ETHNICITY_AND_LANGUAGES_DATA';
export const FETCH_ETHNICITY_AND_LANGUAGES_DATA_DONE = 'FETCH_ETHNICITY_AND_LANGUAGES_DATA_DONE';
export const FETCH_ETHNICITY_AND_LANGUAGES_DATA_FAILED = 'FETCH_ETHNICITY_AND_LANGUAGES_DATA_FAILED';

export const FETCH_MARITAL_STATUS_AND_FAMILY_TYPES_DATA = 'FETCH_MARITAL_STATUS_AND_FAMILY_TYPES_DATA';
export const FETCH_MARITAL_STATUS_AND_FAMILY_TYPES_DATA_DONE = 'FETCH_MARITAL_STATUS_AND_FAMILY_TYPES_DATA_DONE';
export const FETCH_MARITAL_STATUS_AND_FAMILY_TYPES_DATA_FAILED = 'FETCH_MARITAL_STATUS_AND_FAMILY_TYPES_DATA_FAILED';

export const FETCH_PROPERTY_OWNER_SHIP_DATA = 'FETCH_PROPERTY_OWNER_SHIP_DATA';
export const FETCH_PROPERTY_OWNER_SHIP_DATA_DONE = 'FETCH_PROPERTY_OWNER_SHIP_DATA_DONE';
export const FETCH_PROPERTY_OWNER_SHIP_DATA_FAILED = 'FETCH_PROPERTY_OWNER_SHIP_DATA_FAILED';

export const FETCH_PERSONAS = 'FETCH_PERSONAS';
export const FETCH_PERSONAS_DONE = 'FETCH_PERSONAS_DONE';
export const FETCH_PERSONAS_FAILED = 'FETCH_PERSONAS_FAILED';

export const FETCH_COMMUNITIES = 'FETCH_COMMUNITIES';
export const FETCH_COMMUNITIES_DONE = 'FETCH_COMMUNITIES_DONE';
export const FETCH_COMMUNITIES_FAILED = 'FETCH_COMMUNITIES_FAILED';
