import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
	pageLoader: {
		height: '100%',
		width: '100%',
		overflow: 'auto',
		whiteSpace: 'nowrap',
		backgroundColor: '#f2f2f2',
	},
	censusDataPageWrapper: {
		height: '100%',
		width: '100%',
		overflow: 'scroll',
	},
	censusDataPageLayout: {
		height: '100%',
		width: '100%',
	},
	censusDataPageHeader: {
		width: '100%',
		padding: '10px 20px',
		backgroundColor: '#e5e5e5',
	},
	censusDataPageContainer: {
		height: '100%',
		width: '100%',
	},
}));
