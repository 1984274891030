import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import DetailedSinglePieChart from '../../Charts/DetailedSinglePieChart';
import PopulationSummary from '../PopulationSummary';
import CustomLoader from '../../CustomLoader';
import ErrorMessageSpan from '../../TextFields/ErrorMessageSpan';
import CustomCard from '../../CustomCard';
import SiteDashboardHeader from '../../Headers/SiteDashboardHeader';
import { Colors } from '../../../constants/Constants.json';
import images from '../../Icons';

import useStyles from './styles';

const Population = (props) => {
	const classes = useStyles(props);

	const { populationDataLoader, populationDataErrorMessage, genderPopulation, agePopulation } = props;

	return (
		<CustomCard isCentrePage={true}>
			<SiteDashboardHeader title='POPULATION' color={Colors[16]} textColor={'#000'} isCentrePage={true} icon={images.gender} />
			<div className={classes.paperContainer}>
				{populationDataLoader ? (
					<CustomLoader size={40} />
				) : populationDataErrorMessage ? (
					<ErrorMessageSpan text={populationDataErrorMessage} />
				) : (
					<Grid container spacing={2} justifyContent='center'>
						<Grid item xs={5}>
							<PopulationSummary data={agePopulation} />
						</Grid>
						<Grid item xs={7}>
							<DetailedSinglePieChart
								id='population_chart'
								data={genderPopulation}
								categoryX='label'
								valueY='count'
								isLegend={true}
								position='right'
								valign='middle'
								contentAlign='center'
								width={80}
								paddingBottom={10}
								paddingLeft={10}
								paddingTop={10}
								legendPaddingLeft={10}
								colors={['#DF4661', '#2774AE']}
								footerText='Male / Female (%)'
								isPercent={true}
							/>
						</Grid>
					</Grid>
				)}
			</div>
		</CustomCard>
	);
};

Population.propTypes = {
	populationDataLoader: PropTypes.bool,
	populationDataErrorMessage: PropTypes.string,
	genderPopulation: PropTypes.array,
	agePopulation: PropTypes.array,
};

Population.defaultProps = {
	populationDataLoader: false,
	populationDataErrorMessage: '',
	genderPopulation: [],
	agePopulation: [],
};

export default Population;
