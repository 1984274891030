import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
	section: {
		margin: '20px 50px 50px 20px',
	},
	statTitle: {
		fontSize: '16px',
		fontWeight: '600',
		textTransform: 'uppercase',
		marginBottom: '20px',
	},
	map: {
		margin: '50px',
		[theme.breakpoints.up('lg')]: {
			marginTop: '100px',
		},
		[theme.breakpoints.up('xl')]: {
			marginTop: '10px',
			marginBottom: '0px',
		},
	},
}));
