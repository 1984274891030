import { makeStyles } from '@material-ui/styles';

export default makeStyles({
	personaDemographic: {
		margin: '0px 5px 0px 5px',
	},
	personaDemographicIcon: {
		float: 'left',
		marginRight: '12px',
	},
	personaDemographicLabel: {
		color: '#646f80',
		fontWeight: '300',
		fontSize: '16px',
		backgroundSize: '16px 16px',
		lineHeight: 'inherit',
		marginTop: '5px',
	},
	demographicValue: {
		marginLeft: '3px',
		color: '#646f80',
		fontWeight: '600',
		marginTop: '5px',
	},
	customIcon: {
		fill: (props) => props.colourTheme.icon.fill || '#31adce',
		'& div': {
			'& svg': {
				width: 'auto',
				height: 30,
			},
		},
		stroke: (props) => props.colourTheme.icon.stroke || '#31adce',
	},
});
