export default {
	textFieldWrapper: {
		width: 'calc(100% - 170px)',
		display: 'inline-block',
	},
	rightFloatTextFieldWrapper: {
		width: 'calc(100% - 70px)',
		display: 'inline-block',
	},
};
