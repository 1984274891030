import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
	valuesSegmentsSection: {
		margin: '0px 0px 50px 20px',
	},
	valuesSegmentsText: {
		fontSize: '22px',
		fontWeight: 'bold',
	},
	valuesSegments: {
		width: '100%',
		height: '400px',
	},
	statTitle: {
		fontSize: '16px',
		fontWeight: '600',
		textTransform: 'uppercase',
		marginBottom: '20px',
	},
	statText: {
		marginTop: '20px',
		fontSize: '11px',
	},
}));
