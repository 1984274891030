import internalAxiosInstance from '../axios/internalAxiosInstance';
import { responseHandler, errorHandler } from './utility';

export function getSitesByContactId(contactId) {
	return internalAxiosInstance
		.get(`/contacts?contact_id=${contactId}`)
		.then((response) => responseHandler(response))
		.catch((err) => {
			console.log(err);
			return errorHandler(err);
		});
}
