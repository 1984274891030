import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import BorderedSection from '../../BorderedSection';
import DemographicsItem from '../DemographicsItem';

const Demographics = (props) => {
	const { demographics, colourTheme } = props;

	return (
		<BorderedSection title={demographics.headingText}>
			<Grid item xs={12}>
				<DemographicsItem demographics={demographics} colourTheme={colourTheme} />
			</Grid>
		</BorderedSection>
	);
};

Demographics.propTypes = {
	demographics: PropTypes.object,
	colourTheme: PropTypes.object,
};

Demographics.defaultProps = {
	demographics: null,
	colourTheme: null,
};

export default Demographics;
