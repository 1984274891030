import { takeEvery, call, fork, all, put } from 'redux-saga/effects';
import * as ACTION_TYPES from '../../constants/ActionTypes';
import { getSitesByContactId } from '../apis/contactsAPIs';
import { fetchContactSiteListDone, fetchContactSiteListFailed } from '../actions/contactsActions';
function* watchFetchSitesByContactIdDone() {
	//console.log('action', action);
	yield takeEvery(ACTION_TYPES.FETCH_CONTACT_SITE_LIST, function* (action) {
		try {
			const result = yield call(getSitesByContactId, action.contactId);
			yield put(fetchContactSiteListDone(result));
		} catch (error) {
			yield put(fetchContactSiteListFailed(error));
		}
	});
}

export default function* rootSaga() {
	yield all([fork(watchFetchSitesByContactIdDone)]);
}
