import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import useStyles from './styles';
import { getMaxObject } from '../../../helpers/utility';

const SocioEconomic = (props) => {
	const { data } = props;

	const classes = useStyles(props);

	const maxSocioEconomic = getMaxObject(data);

	return (
		<div className={classes.svgContainer}>
			<svg width='550' height='150' viewBox='0 0 550 150' className={classes.socioSvg}>
				<g transform='translate(30,15)'>
					<g transform='translate(0,60)'>
						<line x1='0' x2='490' y1='0' y2='0' strokeWidth='3' className={classNames(classes.quintilesLine)}></line>
					</g>
					<circle cx='0' opacity='0.7' cy='60' r='15.79' className={classNames(classes.quintilesOuterCircle)}></circle>
					<circle cx='122.5' opacity='0.7' cy='60' r='15.79' className={classNames(classes.quintilesOuterCircle)}></circle>
					<circle cx='245' opacity='0.7' cy='60' r='15.79' className={classNames(classes.quintilesOuterCircle)}></circle>
					<circle cx='367.5' opacity='0.7' cy='60' r='15.79' className={classNames(classes.quintilesOuterCircle)}></circle>
					<circle cx='490' opacity='0.7' cy='60' r='15.79' className={classNames(classes.quintilesOuterCircle)}></circle>

					<circle cx='0' cy='60' r='13.79' className={classNames(classes.quintilesWhiteCircle)}></circle>
					<circle cx='122.5' cy='60' r='13.79' className={classNames(classes.quintilesWhiteCircle)}></circle>
					<circle cx='245' cy='60' r='13.79' className={classNames(classes.quintilesWhiteCircle)}></circle>
					<circle cx='367.5' cy='60' r='13.79' className={classNames(classes.quintilesWhiteCircle)}></circle>
					<circle cx='490' cy='60' r='13.79' className={classNames(classes.quintilesWhiteCircle)}></circle>

					<circle opacity='0.7' cx='0' cy='60' r='5.79' className={classNames(classes.quintilesInnerCircle)}></circle>
					<circle opacity='0.7' cx='122.5' cy='60' r='5.79' className={classNames(classes.quintilesInnerCircle)}></circle>
					<circle opacity='0.7' cx='245' cy='60' r='5.79' className={classNames(classes.quintilesInnerCircle)}></circle>
					<circle opacity='0.7' cx='367.5' cy='60' r='5.79' className={classNames(classes.quintilesInnerCircle)}></circle>
					<circle opacity='0.7' cx='490' cy='60' r='5.79' className={classNames(classes.quintilesInnerCircle)}></circle>

					<text dx='0' dy='100' className={classNames(classes.quintilesLabel)}>
						{data[0].label}
					</text>
					<text dx='122.5' dy='100' className={classNames(classes.quintilesLabel)}>
						{data[1].label}
					</text>
					<text dx='245' dy='100' className={classNames(classes.quintilesLabel)}>
						{data[2].label}
					</text>
					<text dx='367.5' dy='100' className={classNames(classes.quintilesLabel)}>
						{data[3].label}
					</text>
					<text dx='490' dy='100' className={classNames(classes.quintilesLabel)}>
						{data[4].label}
					</text>

					<text
						dx='0'
						dy='30'
						className={classNames(data[0].label === maxSocioEconomic.label ? classes.quintilesPercentageHide : classes.quintilesPercentage)}
					>
						{data[0].value}%
					</text>
					<text
						dx='122.5'
						dy='30'
						className={classNames(data[1].label === maxSocioEconomic.label ? classes.quintilesPercentageHide : classes.quintilesPercentage)}
					>
						{data[1].value}%
					</text>
					<text
						dx='245'
						dy='30'
						className={classNames(data[2].label === maxSocioEconomic.label ? classes.quintilesPercentageHide : classes.quintilesPercentage)}
					>
						{data[2].value}%
					</text>
					<text
						dx='367.5'
						dy='30'
						className={classNames(data[3].label === maxSocioEconomic.label ? classes.quintilesPercentageHide : classes.quintilesPercentage)}
					>
						{data[3].value}%
					</text>
					<text
						dx='490'
						dy='30'
						className={classNames(data[4].label === maxSocioEconomic.label ? classes.quintilesPercentageHide : classes.quintilesPercentage)}
					>
						{data[4].value}%
					</text>

					<g
						transform='translate(0,-10)'
						className={classNames(
							data[0].label === maxSocioEconomic.label ? classes.quintilesPercentagePathShow : classes.quintilesBigPercentageHide
						)}
					>
						<path
							d='m0 0c10.801 0 19.559 8.759 19.559 19.556 0 2.582-.502 5.046-1.412 7.302l-18.309 40.956-17.987-40.957c-.908-2.256-1.412-4.72-1.412-7.302 0-10.797 8.76-19.555 19.561-19.555zm-.002084 3.371751c-8.928223 0-16.187416 7.255942-16.187416 16.183081s7.25811 16.187417 16.187416 16.187417c8.930391 0 16.189584-7.259194 16.189584-16.187417-.001084-8.927139-7.259193-16.183081-16.189584-16.183081z'
							className={classNames(
								classes.quintilesPercentagePath,
								data[0].label === maxSocioEconomic.label ? classes.quintilesPercentagePathShow : {}
							)}
						></path>
						<text transform='translate(0, 23)' className={classNames(classes.quintilesPercentagePath, classes.quintilesBigPercentageText)}>
							{data[0].value}%
						</text>
						<text></text>
					</g>
					<g
						transform='translate(122.5,-10)'
						className={classNames(
							data[1].label === maxSocioEconomic.label ? classes.quintilesPercentagePathShow : classes.quintilesBigPercentageHide
						)}
					>
						<path
							d='m0 0c10.801 0 19.559 8.759 19.559 19.556 0 2.582-.502 5.046-1.412 7.302l-18.309 40.956-17.987-40.957c-.908-2.256-1.412-4.72-1.412-7.302 0-10.797 8.76-19.555 19.561-19.555zm-.002084 3.371751c-8.928223 0-16.187416 7.255942-16.187416 16.183081s7.25811 16.187417 16.187416 16.187417c8.930391 0 16.189584-7.259194 16.189584-16.187417-.001084-8.927139-7.259193-16.183081-16.189584-16.183081z'
							className={classNames(
								classes.quintilesPercentagePath,
								data[1].label === maxSocioEconomic.label ? classes.quintilesPercentagePathShow : {}
							)}
						></path>
						<text transform='translate(0, 23)' className={classNames(classes.quintilesPercentagePath, classes.quintilesBigPercentageText)}>
							{data[1].value}%
						</text>
						<text></text>
					</g>
					<g
						transform='translate(245,-10)'
						className={classNames(
							data[2].label === maxSocioEconomic.label ? classes.quintilesPercentagePathShow : classes.quintilesBigPercentageHide
						)}
					>
						<path
							d='m0 0c10.801 0 19.559 8.759 19.559 19.556 0 2.582-.502 5.046-1.412 7.302l-18.309 40.956-17.987-40.957c-.908-2.256-1.412-4.72-1.412-7.302 0-10.797 8.76-19.555 19.561-19.555zm-.002084 3.371751c-8.928223 0-16.187416 7.255942-16.187416 16.183081s7.25811 16.187417 16.187416 16.187417c8.930391 0 16.189584-7.259194 16.189584-16.187417-.001084-8.927139-7.259193-16.183081-16.189584-16.183081z'
							className={classNames(
								classes.quintilesPercentagePath,
								data[2].label === maxSocioEconomic.label ? classes.quintilesPercentagePathShow : {}
							)}
						></path>
						<text transform='translate(0, 23)' className={classNames(classes.quintilesPercentagePath, classes.quintilesBigPercentageText)}>
							{data[2].value}%
						</text>
						<text></text>
					</g>
					<g
						transform='translate(367.5,-10)'
						className={classNames(
							data[3].label === maxSocioEconomic.label ? classes.quintilesPercentagePathShow : classes.quintilesBigPercentageHide
						)}
					>
						<path
							d='m0 0c10.801 0 19.559 8.759 19.559 19.556 0 2.582-.502 5.046-1.412 7.302l-18.309 40.956-17.987-40.957c-.908-2.256-1.412-4.72-1.412-7.302 0-10.797 8.76-19.555 19.561-19.555zm-.002084 3.371751c-8.928223 0-16.187416 7.255942-16.187416 16.183081s7.25811 16.187417 16.187416 16.187417c8.930391 0 16.189584-7.259194 16.189584-16.187417-.001084-8.927139-7.259193-16.183081-16.189584-16.183081z'
							className={classNames(
								classes.quintilesPercentagePath,
								data[3].label === maxSocioEconomic.label ? classes.quintilesPercentagePathShow : {}
							)}
						></path>
						<text transform='translate(0, 23)' className={classNames(classes.quintilesPercentagePath, classes.quintilesBigPercentageText)}>
							{data[3].value}%
						</text>
						<text></text>
					</g>
					<g
						transform='translate(490,-10)'
						className={classNames(
							data[4].label === maxSocioEconomic.label ? classes.quintilesPercentagePathShow : classes.quintilesBigPercentageHide
						)}
					>
						<path
							d='m0 0c10.801 0 19.559 8.759 19.559 19.556 0 2.582-.502 5.046-1.412 7.302l-18.309 40.956-17.987-40.957c-.908-2.256-1.412-4.72-1.412-7.302 0-10.797 8.76-19.555 19.561-19.555zm-.002084 3.371751c-8.928223 0-16.187416 7.255942-16.187416 16.183081s7.25811 16.187417 16.187416 16.187417c8.930391 0 16.189584-7.259194 16.189584-16.187417-.001084-8.927139-7.259193-16.183081-16.189584-16.183081z'
							className={classNames(
								classes.quintilesPercentagePath,
								data[4].label === maxSocioEconomic.label ? classes.quintilesPercentagePathShow : {}
							)}
						></path>
						<text transform='translate(0, 23)' className={classNames(classes.quintilesPercentagePath, classes.quintilesBigPercentageText)}>
							{data[4].value}%
						</text>
						<text></text>
					</g>
				</g>
			</svg>
		</div>
	);
};

SocioEconomic.propTypes = {
	data: PropTypes.array,
	colourTheme: PropTypes.object,
};

SocioEconomic.defaultProps = {
	data: [],
	colourTheme: null,
};

export default SocioEconomic;
