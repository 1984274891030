import React from 'react';
import PropTypes from 'prop-types';

import useStyles from './styles';

const CensusSubHeader = (props) => {
	const classes = useStyles(props);

	const { selectedSiteName, selectedCensusRadius } = props;

	const siteName = selectedSiteName !== 'All Centres' ? `${selectedSiteName} Centre` : 'Centres';

	return (
		<div className={classes.censusSummary} data-testid='census-data-page-template-summary'>
			<p>
				Data from <b>{siteName}</b> is collated based on {selectedCensusRadius}km radius.
			</p>
		</div>
	);
};

CensusSubHeader.propTypes = {
	selectedSiteName: PropTypes.string,
	selectedCensusRadius: PropTypes.string,
};

CensusSubHeader.defaultProps = {
	selectedSiteName: '',
	selectedCensusRadius: '3',
};

export default CensusSubHeader;
