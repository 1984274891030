import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
	censusPageMiddleContent: {
		width: '100%',
		marginTop: '10px',
	},
	rowContainer: {
		display: 'flex',
		marginBottom: '10px',
		height: '300px',
	},
	leftContainer: {
		width: 'calc(50% - 10px)',
		height: '100%',
		marginRight: '10px',
	},
	rightContainer: {
		width: 'calc(50%)',
		height: '100%',
	},
}));
