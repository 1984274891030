import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import RadarChart from '../../../../Charts/RadarChart';
import classNames from 'classnames/bind';

import useStyles from './styles';

const ValuesSegments = (props) => {
	const { data, colourTheme } = props;

	const classes = useStyles(props);

	return (
		<div className={classes.valuesSegmentsSection}>
			<Grid item xs={12}>
				<h3 className={classes.statTitle}>{data.headingText}</h3>
			</Grid>
			<Grid item container xs={12}>
				<div className={classNames(classes.valuesSegments)}>
					<RadarChart id='values-segments-chart' data={data.data} fillColor={colourTheme.defultColour} />
				</div>
				<div className={classNames(classes.statText)}>
					<sup>*</sup>
					{data.footerText}
				</div>
			</Grid>
		</div>
	);
};

ValuesSegments.propTypes = {
	data: PropTypes.shape({
		headingText: PropTypes.string,
		data: PropTypes.array,
		footerText: PropTypes.string,
	}),
	colourTheme: PropTypes.object,
};

ValuesSegments.defaultProps = {
	data: {
		headingText: '',
		data: [],
		footerText: '',
	},
	colourTheme: null,
};

export default ValuesSegments;
