export default {
	selectors: {
		paddingTop: 15,
		margin: '0px 10px',
		'&:focus': {
			outline: 'none',
		},
	},
	dateRangetext: {
		color: 'rgba(0, 0, 0, .65)',
	},
	dateRangeIcon: {
		fill: 'rgba(0, 0, 0, .54)',
	},
};
