import { Map, fromJS } from 'immutable';
import isEmpty from 'lodash/isEmpty';
import * as ACTION_TYPES from '../../constants/ActionTypes';

export const initState = new Map({
	loginUser: '',
	loginPassword: '',

	loginLoader: false,
	loginErrorMessage: '',
	loginSuccess: false,

	permissionLoading: false,
	userInfo: new Map(),

	firstTimeFetch: true,
	userPreferenceLoader: false,
	userPreference: new Map(),
	preferenceErrorMessage: '',

	toggleResetPswModal: false,
	resetLoader: false,
	userNewPassword: '',
	userNewPasswordAgain: '',
	userId: '',
	userName: '',
	userLastName: '',
	userFirstName: '',
	userEmail: '',
	userAyudaName: '',
	snackbarOpen: false,
});

const authReducer = (state = initState, action) => {
	switch (action.type) {
		case ACTION_TYPES.SIGNIN_REQUEST:
			return state.set('loginLoader', true).set('loginErrorMessage', '').set('loginSuccess', false);

		case ACTION_TYPES.LOGIN_SUCCESS:
			return state.set('loginUser', '').set('loginPassword', '').set('loginLoader', false).set('loginSuccess', true);

		case ACTION_TYPES.LOGIN_ERROR:
			return state.set('loginLoader', false).set('loginErrorMessage', action.error.message);

		case ACTION_TYPES.SIGNOUT_REQUEST:
			return state.set('loginSuccess', false);

		case ACTION_TYPES.LOGOUT_SUCCESS:
			return state.set('loginSuccess', false);

		case ACTION_TYPES.LOGOUT_ERROR:
			return state.set('loginSuccess', false);

		case ACTION_TYPES.INPUT_LOGIN_USER:
			return state.set('loginUser', action.value).set('loginErrorMessage', '');

		case ACTION_TYPES.INPUT_LOGIN_PASSWORD:
			return state.set('loginPassword', action.value);

		case ACTION_TYPES.CLEAR_LOGIN_PASSWORD:
			return state.set('loginPassword', '');

		case ACTION_TYPES.GET_USER_PERMISSIONS:
			return state.set('permissionLoading', true);

		case ACTION_TYPES.GET_USER_PERMISSIONS_DONE: {
			const { data } = action;

			if (!isEmpty(data)) {
				return state
					.set('permissionLoading', false)
					.set('loginSuccess', true)
					.set('userInfo', data.user_info ? fromJS(data.user_info[0]) : new Map());
			}
			return state.set('permissionLoading', false).set('loginSuccess', true).set('userInfo', new Map());
		}

		case ACTION_TYPES.GET_USER_PERMISSIONS_FAILED:
			return state.set('permissionLoading', false).set('userInfo', new Map());

		case ACTION_TYPES.FETCH_OWNER_USER_INFO:
			return state.set('userPreferenceLoader', true).set('preferenceErrorMessage', '');

		case ACTION_TYPES.FETCH_OWNER_USER_INFO_DONE:
			return state
				.set('userPreferenceLoader', false)
				.set('userPreference', action.data.length > 0 ? fromJS(action.data[0]) : new Map())
				.set('userName', action.data.length > 0 ? action.data[0].user_name : '')
				.set('userEmail', action.data.length > 0 ? action.data[0].email : '')
				.set('userAyudaName', action.data.length > 0 ? action.data[0].ayuda_user_name : '')
				.set('userFirstName', action.data.length > 0 ? action.data[0].first_name : '')
				.set('userLastName', action.data.length > 0 ? action.data[0].last_name : '')
				.set('firstTimeFetch', false)
				.set('userId', action.data.length > 0 ? action.data[0]._id : '');

		case ACTION_TYPES.FETCH_OWNER_USER_INFO_FAILED:
			return state
				.set('userPreferenceLoader', false)
				.set('userPreference', new Map())
				.set('userName', '')
				.set('userEmail', '')
				.set('userAyudaName', '')
				.set('userFirstName', '')
				.set('userLastName', '')
				.set('userId', '')
				.set('preferenceErrorMessage', action.error.message);
		case ACTION_TYPES.RESET_USER_PREFERENCE_INFO:
			return state
				.set('userName', state.getIn(['userPreference', 'user_name']) ? state.getIn(['userPreference', 'user_name']) : '')
				.set('userEmail', state.getIn(['userPreference', 'email']) ? state.getIn(['userPreference', 'email']) : '')
				.set('userAyudaName', state.getIn(['userPreference', 'ayuda_user_name']) ? state.getIn(['userPreference', 'ayuda_user_name']) : '')
				.set('userFirstName', state.getIn(['userPreference', 'first_name']) ? state.getIn(['userPreference', 'first_name']) : '')
				.set('userLastName', state.getIn(['userPreference', 'last_name']) ? state.getIn(['userPreference', 'last_name']) : '')
				.set('userId', '');

		case ACTION_TYPES.TOGGLE_RESET_USER_PASSWORD_MODAL:
			if (action.bools) {
				return state
					.set('toggleResetPswModal', true)
					.set('resetLoader', false)
					.set('userNewPassword', '')
					.set('userNewPasswordAgain', '')
					.set('preferenceErrorMessage', '');
			}
			return state.set('toggleResetPswModal', false);

		case ACTION_TYPES.INPUT_RESET_USER_PASSWORD:
			return state.set('userNewPassword', action.value);

		case ACTION_TYPES.INPUT_RESET_USER_PASSWORD_AGAIN:
			return state.set('userNewPasswordAgain', action.value);

		case ACTION_TYPES.RESET_USER_PASSWORD:
			return state.set('resetLoader', true).set('preferenceErrorMessage', '').set('snackbarOpen', false);

		case ACTION_TYPES.RESET_USER_PASSWORD_DONE:
			return state
				.set('resetLoader', false)
				.set('userNewPassword', '')
				.set('userNewPasswordAgain', '')
				.set('toggleResetPswModal', false)
				.set('snackbarOpen', true);

		case ACTION_TYPES.RESET_USER_PASSWORD_FAILED:
			return state.set('resetLoader', false).set('preferenceErrorMessage', action.error.message);

		case ACTION_TYPES.CLOSE_SNACKBAR_MODAL:
			return state.set('snackbarOpen', false);

		default:
			return state;
	}
};

export default authReducer;
