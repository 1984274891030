import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Grid } from '@material-ui/core';
import HyveCard from '../../../CustomCard';
import SitesSelectorWithSearch from '../../../SitesSelectorWithSearch';
import CustomSelect from '../../../CustomSelect';

import useStyles from './styles';

const FilterComponent = (props) => {
	const classes = useStyles(props);

	const { sites, selectedSiteId, handleChangeSite, radius, selectedRadius, handleChangeRadius } = props;

	return (
		<HyveCard isCentrePage={true}>
			<Grid container>
				<Grid item xs={12}>
					<div className={classes.censusDashboardHeader}>
						<div className={classes.filtersRight}>
							<div className={classNames(classes.siteSelector, classes.selectors, classes.inlineFilters)}>
								<SitesSelectorWithSearch
									selectedSiteId={selectedSiteId}
									sites={sites}
									label='Centre'
									handleChangeSite={handleChangeSite}
									siteKey='id'
								/>
							</div>
							<div className={classNames(classes.siteSelector, classes.selectors, classes.inlineFilters)}>
								<CustomSelect
									value={selectedRadius}
									menus={radius}
									menuValueKey='value'
									menuNameKey='label'
									handleOnChange={handleChangeRadius}
									label='Radius'
								/>
							</div>
						</div>
					</div>
				</Grid>
			</Grid>
		</HyveCard>
	);
};

FilterComponent.propTypes = {
	sites: PropTypes.array,
	selectedSiteId: PropTypes.string,
	handleChangeSite: PropTypes.func,
	siteKey: PropTypes.string,
	radius: PropTypes.array,
	selectedRadius: PropTypes.string,
	handleChangeRadius: PropTypes.func,
};

FilterComponent.defaultProps = {
	sites: [],
	selectedSiteId: '',
	handleChangeSite: null,
	siteKey: 'id',
	radius: [],
	selectedRadius: null,
	handleChangeRadius: null,
};

export default FilterComponent;
