import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import ReactSVG from 'react-svg';

import useStyles from './styles';

const MainGoal = (props) => {
	const { data } = props;

	const classes = useStyles(props);

	return (
		<div className={classNames(classes.mainGoalInLifeGrid)}>
			<h3 className={classNames(classes.statTitle)}>{data.headingText}</h3>
			<div className={classNames(classes.goalGrid)}>
				{data.data.goalInLifeTable.map((goal, index) => {
					const title = goal.title.split('.');
					const goalInLifeChartItem = data.data.goalInLifeChart.find((goal) => goal.key === title[1]);
					if (goal.highlight) {
						return (
							<div className={classNames(classes.goalColumn, classes.goalColumnSelected)} key={`goal-highlight-${index}`}>
								<div className={classNames(classes.goalColumnItem)}>
									<span className={classNames(classes.goalColumnItemIcon, classes.goalColumnItemIconSelected)}>
										<ReactSVG src={require(`../Icons/personas/${goal.filename}`)} />
									</span>
								</div>
								<div className={classNames(classes.goalColumnPercentage, classes.goalColumnPercentageSelected)}>{goal.percentage}%</div>
								<div className={classNames(classes.goalColumnTitle, classes.goalColumnTitleSelected)}>{goalInLifeChartItem.key}</div>
								<div className={classNames(classes.goalColumnSecondPercentage, classes.goalColumnSecondPercentageSelected)}>
									{goalInLifeChartItem.value}%
								</div>
							</div>
						);
					} else {
						return (
							<div className={classNames(classes.goalColumn)} key={`goal-${index}`}>
								<div className={classNames(classes.goalColumnItem)}>
									<span className={classNames(classes.goalColumnItemIcon)}>
										<ReactSVG src={require(`../Icons/personas/${goal.filename}`)} />
									</span>
								</div>
								<div className={classNames(classes.goalColumnPercentage)}>{goal.percentage}%</div>
								<div className={classNames(classes.goalColumnTitle)}>{goalInLifeChartItem.key}</div>
								<div className={classNames(classes.goalColumnSecondPercentage)}>{goalInLifeChartItem.value}%</div>
							</div>
						);
					}
				})}
			</div>
		</div>
	);
};

MainGoal.propTypes = {
	data: PropTypes.shape({
		headingText: PropTypes.string,
		data: PropTypes.object,
	}),
	colourTheme: PropTypes.object,
};

MainGoal.defaultProps = {
	data: {
		headingText: '',
		data: [],
	},
	colourTheme: null,
};

export default MainGoal;
