export default {
	textfieldHeight: {
		height: 50,
		width: '100%',
	},
	wrapper: {
		width: '90%',
		float: 'left',
		padding: '20px',
	},
	title: {
		fontSize: 13,
		display: 'inline-block',
		width: 160,
		color: '#999',
		direction: 'rtl',
		marginRight: 10,
	},
	textFieldWrapper: {
		width: 'calc(100% - 170px)',
		display: 'inline-block',
	},
	requiredIcon: {
		fontSize: 8,
		marginBottom: 2,
		fill: '#c33634',
	},
	value: {
		fontSize: 13,
	},
}