import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
	mainGoalInLifeGrid: {
		maxWidth: '100%',
		margin: '0px 20px 30px 20px',
	},
	statTitle: {
		marginBottom: '20px',
		fontSize: '16px',
		fontWeight: '600',
		textTransform: 'uppercase',
	},
	goalGrid: {
		display: 'flex',
		flexFlow: 'row wrap',
		justifyContent: 'center',
		width: '100%',
		margin: '0 auto',
	},
	goalColumn: {
		backgroundColor: 'rgb(255, 255, 255)',
		opacity: '0.67',
		position: 'relative',
		width: 'calc(20% - 2px)',
		margin: '0 1px -1px',
		padding: '20px 0 0',
	},
	goalColumnSelected: {
		backgroundColor: (props) => props.colourTheme.defultColour || '#31adce',
	},
	goalColumnItem: {
		textAlign: 'center',
		display: 'flex',
		flexFlow: 'column nowrap',
		alignItems: 'center',
		justifyContent: 'center',
		height: '50px',
		marginBottom: '5px',
	},
	goalColumnItemIcon: {
		fill: (props) => props.colourTheme.icon.stroke || '#31adce',
		stroke: (props) => props.colourTheme.icon.stroke || '#31adce',
		fontWeight: '600',
		fontSize: '11px',
		'& div': {
			'& svg': {
				width: 32,
				height: 32,
			},
		},
	},
	goalColumnItemIconSelected: {
		fill: 'rgb(255, 255, 255) !important',
		stroke: 'rgb(255, 255, 255) !important',
	},
	goalColumnPercentage: {
		textAlign: 'center',
		color: (props) => props.colourTheme.defultColour || '#31adce',
	},
	goalColumnPercentageSelected: {
		color: 'rgb(255, 255, 255) !important',
	},
	goalColumnTitle: {
		background: '#eee',
		marginTop: '5px',
		paddingTop: '10px',
		textAlign: 'center',

		fontSize: '12px',
		fontFamily: `"Proxima Nova",sans-serif`,
		lineHeight: '12px',
		fontWeight: '700',
		color: '#000101',
	},
	goalColumnTitleSelected: {
		color: '#8a8a86',
	},
	goalColumnSecondPercentage: {
		background: '#eee',
		paddingBottom: '5px',
		textAlign: 'center',

		fontSize: '12px',
		fontFamily: `"Proxima Nova",sans-serif`,
		lineHeight: '18px',
		color: '#000101',
	},
	goalColumnSecondPercentageSelected: {
		color: '#000101',
	},
}));
