import React from 'react';
import PropTypes from 'prop-types';

import useStyles from './styles';

const TextView = (props) => {
	const classes = useStyles(props);

	const { primaryText, secondaryText } = props;

	return (
		<div className={classes.textViewContainer} data-testid='text-view'>
			<h2>{primaryText}</h2>
			<h3>{secondaryText}</h3>
		</div>
	);
};

TextView.propTypes = {
	primaryText: PropTypes.string,
	secondaryTest: PropTypes.string,
};

TextView.defaultProps = {
	primaryText: '',
	secondaryText: '',
};

export default TextView;
