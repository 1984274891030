import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
	root: {
		backgroundColor: theme.color.card.bgcolor,
		color: theme.color.card.textcolor,
		width: '100%',
		height: '100%',
		borderRadius: (props) => (props.isCentrePage ? '12px' : '4px !important'),
		boxShadow: (props) => props.isCentrePage && 'none',
	},
}));
