import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import HyveCard from '../../CustomCard';
import SitesSelectorWithSearch from '../../SitesSelectorWithSearch';

import useStyles from './styles';
import CensusSubHeader from '../CensusSubHeader';
import { Grid } from '@material-ui/core';
import CustomSelect from '../../CustomSelect';

const CensusHeader = (props) => {
	const classes = useStyles(props);

	const { sites, selectedSiteId, handleChangeSite, siteKey, selectedSiteName, censusRadius, selectedCensusRadius, handleChangeRadius } = props;

	return (
		<HyveCard isCentrePage={true}>
			<Grid container>
				<Grid item xs={12}>
					<div className={classes.censusDashboardHeader}>
						<div className={classes.censusDashboardTitle} data-testid='census-data-page-template-header'>
							<h2 className={classes.censusDashboardTitleText}>CENSUS INFORMATION</h2>
						</div>
						<div className={classes.filtersRight}>
							<div className={classNames(classes.siteSelector, classes.selectors, classes.inlineFilters)}>
								<SitesSelectorWithSearch
									selectedSiteId={selectedSiteId}
									sites={sites}
									label='Centre'
									handleChangeSite={handleChangeSite}
									siteKey={siteKey}
								/>
							</div>
							<div className={classNames(classes.siteSelector, classes.selectors, classes.inlineFilters)}>
								<CustomSelect
									value={selectedCensusRadius}
									menus={censusRadius}
									menuValueKey='value'
									menuNameKey='label'
									handleOnChange={handleChangeRadius}
									label='Radius'
								/>
							</div>
						</div>
					</div>
				</Grid>
				<Grid item xs={12}>
					<CensusSubHeader selectedSiteName={selectedSiteName} selectedCensusRadius={selectedCensusRadius} />
				</Grid>
			</Grid>
		</HyveCard>
	);
};

CensusHeader.propTypes = {
	sites: PropTypes.array,
	selectedSiteId: PropTypes.string,
	handleChangeSite: PropTypes.func,
	siteKey: PropTypes.string,
	censusRadius: PropTypes.array,
	selectedCensusRadius: PropTypes.string,
	handleChangeRadius: PropTypes.func,
};

CensusHeader.defaultProps = {
	sites: [],
	selectedSiteId: '',
	handleChangeSite: null,
	siteKey: 'id',
	censusRadius: [],
	selectedCensusRadius: null,
	handleChangeRadius: null,
};

export default CensusHeader;
