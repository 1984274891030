import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Popover from '@material-ui/core/Popover';
import MenuItem from '@material-ui/core/MenuItem';
import UserProfileIcon from '../UserProfileIcon';
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';
import SettingsIcon from '@material-ui/icons/Settings';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import classNames from 'classnames/bind';
import { clickMainMenuItem } from '../../../redux/actions/appActions';
import styles from './styles.scss';
import useStyles from './styles';

const cx = classNames.bind(styles);

const LogoutIcon = createSvgIcon(
	<path d='M11,7L9.6,8.4l2.6,2.6H2v2h10.2l-2.6,2.6L11,17l5-5L11,7z M20,19h-8v2h8c1.1,0,2-0.9,2-2V5c0-1.1-0.9-2-2-2h-8v2h8V19z' />,
	'Logout'
);

const ActionMenu = (props) => {
	const { handleLogout, history, firstName, lastName, clickMainMenuItem } = props;
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleClickReference = () => {
		history.push('/user_preferences');
		clickMainMenuItem(true, '');
		setAnchorEl(null);
	};

	const handleClickHelp = () => {
		history.push('/help');
		clickMainMenuItem(true, '');
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	return (
		<div className={cx('userProfileWrapper')}>
			<div className={cx('userProfileOutside')} onClick={handleClick}>
				<UserProfileIcon lastName={lastName} firstName={firstName} />
				<div className={cx('userNameWrapper')} data-testid='user-name'>
					{lastName ? `${firstName} ${lastName}` : `${firstName}`}
				</div>
				<ArrowDropDownIcon className={cx('dropDownIcon')} />
			</div>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				className={classes.popover}
				data-testid='action-menu-popover'
			>
				<MenuItem className={classNames(classes.item, classes.border)} onClick={handleClickReference} data-testid='action-menu-reference'>
					<SettingsIcon className={classes.menuItemIcon} />
					Preferences
				</MenuItem>

				<MenuItem className={classNames(classes.item, classes.border)} onClick={handleClickHelp} data-testid='action-menu-help'>
					<HelpOutlineIcon className={classes.menuItemIcon} />
					Help
				</MenuItem>
				<MenuItem className={classes.item} onClick={() => handleLogout()} data-testid='action-menu-log-out' data-open={open}>
					<LogoutIcon className={classes.menuItemIcon} />
					Log out
				</MenuItem>
			</Popover>
		</div>
	);
};

ActionMenu.propTypes = {
	history: PropTypes.object,
	handleLogout: PropTypes.func,
	firstName: PropTypes.string,
	lastName: PropTypes.string,
};

ActionMenu.defaultProps = {
	history: {},
	handleLogout: null,
	firstName: '',
	lastName: '',
};

const mapDispatchToProps = (dispatch) => ({
	clickMainMenuItem: (bools, identifier) => dispatch(clickMainMenuItem(bools, identifier)),
});

export default connect(null, mapDispatchToProps)(ActionMenu);
