import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';
import DemographicsAmchartsBarChart from '../../Charts/DemographicsAmchartsBarChart';
import SiteDashboardHeader from '../../Headers/SiteDashboardHeader';
import CustomLoader from '../../CustomLoader';
import ErrorMessageSpan from '../../TextFields/ErrorMessageSpan';
import HyveCard from '../../CustomCard';
import { siteDashboardChartWrapper } from '../../../constants/Theme';
import { Colors } from '../../../constants/Constants.json';
import images from '../../../components/Icons';
import { getUserDemographicsDate } from '../../../helpers/utility';

class UserDemographics extends React.Component {
	state = {
		data: [],
	};

	componentDidMount() {
		const { demographics } = this.props;
		if (demographics.size > 0) {
			this.setState({
				data: getUserDemographicsDate(demographics),
			});
		}
	}

	componentDidUpdate(prevProps) {
		const { demographics } = this.props;
		if (demographics.size > 0 && !isEqual(prevProps.demographics, demographics)) {
			this.setState({
				data: getUserDemographicsDate(demographics),
			});
		}
	}

	render() {
		const {
			timeRange,
			startDate,
			endDate,
			ranges,
			DemographicsLoader,
			errorMessageDemographics,
			color = Colors[9],
			color01 = Colors[12],
			title,
			paddingTop,
			numberFormat,
			isCentrePage = false,
		} = this.props;
		const { data } = this.state;

		return (
			<HyveCard isCentrePage={isCentrePage}>
				<SiteDashboardHeader
					title={title || 'Wi-Fi Consumer Demographics'}
					icon={images.wifi}
					timeRange={timeRange}
					startDateProps={startDate}
					endDateProps={endDate}
					ranges={ranges}
					color={isCentrePage ? Colors[16] : Colors[12]}
					textColor={isCentrePage ? '#000' : null}
					fontSize={isCentrePage ? '14px' : null}
					border={isCentrePage ? 'none' : null}
					isCentrePage={isCentrePage}
				/>
				<div style={{ width: '100%', height: siteDashboardChartWrapper }}>
					{DemographicsLoader ? (
						<CustomLoader size={40} />
					) : errorMessageDemographics ? (
						<ErrorMessageSpan text={errorMessageDemographics} />
					) : (
						data.length > 0 && (
							<DemographicsAmchartsBarChart
								data={data}
								category='id'
								valueY='Female'
								valueY01='Male'
								id='user'
								color={color}
								color01={color01}
								paddingTop={paddingTop}
								isCentrePage={isCentrePage}
								numberFormat={numberFormat}
							/>
						)
					)}
				</div>
			</HyveCard>
		);
	}
}

UserDemographics.propTypes = {
	timeRange: PropTypes.number,
	startDate: PropTypes.string,
	endDate: PropTypes.string,
	ranges: PropTypes.array,
	color: PropTypes.string,
	color01: PropTypes.string,
	title: PropTypes.string,
	paddingTop: PropTypes.number,
	numberFormat: PropTypes.string,
	isCentrePage: PropTypes.bool,
};

UserDemographics.defaultProps = {
	timeRange: null,
	startDate: '',
	endDate: '',
	ranges: [],
	color: Colors[9],
	color01: Colors[12],
	title: '',
	paddingTop: 0,
	numberFormat: '',
	isCentrePage: false,
};

const mapStateToProps = (state) => ({
	demographics: state.sitesReducer.get('demographics'),
	errorMessageDemographics: state.sitesReducer.get('errorMessageDemographics'),
	DemographicsLoader: state.sitesReducer.get('DemographicsLoader'),
});

export default connect(mapStateToProps)(UserDemographics);
