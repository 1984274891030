import React from 'react';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';
import { withStyles } from '@material-ui/core/styles';
import CustomButton from '../../CustomButton';
import { textfieldStyle } from '../../../constants/Theme';

const style = { ...textfieldStyle };

class ExportButton extends React.PureComponent {
	render() {
		const { classes, textColor, color, csv, highlightHover, disableHover } = this.props;

		return (
			<div className={classes.actionArea} data-testid='export-button'>
				<div className={classes.buttonWrapper}>
					<CSVLink data={csv} filename='DailyVisitationExport.csv'>
						<CustomButton color={color} textColor={textColor} margin={'15px 10px 0 0'} highlightHover={highlightHover} disableHover={disableHover}>
							Export Data
						</CustomButton>
					</CSVLink>
				</div>
			</div>
		);
	}
}

ExportButton.propTypes = {
	textColor: PropTypes.string,
	color: PropTypes.string,
	csv: PropTypes.array,
	highlightHover: PropTypes.bool,
	disableHover: PropTypes.bool,
};

ExportButton.defaultProps = {
	textColor: '',
	color: '',
	csv: [],
	highlightHover: false,
	disableHover: false,
};

export default withStyles(style)(ExportButton);
