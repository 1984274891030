import moment from 'moment-timezone';
import internalAxiosInstance from '../axios/internalAxiosInstance';
import { responseHandler, errorHandler } from './utility';

const _1year = moment().tz('Australia/Sydney').startOf('month').subtract(11, 'months').format('YYYY-MM-DD');
const endOfCurrentMonth = moment().tz('Australia/Sydney').endOf('month').format('YYYY-MM-DD');
const yesterday = moment().tz('Australia/Sydney').subtract(24, 'hours').format('YYYY-MM-DD');
const _30Days = moment().tz('Australia/Sydney').subtract(30, 'days').format('YYYY-MM-DD');

export function getSiteReportVisitsByDay(siteId, startdate = _30Days, enddate = yesterday) {
	return internalAxiosInstance
		.get(`/sites/${siteId}/reports/visitsbyday?startdate=${startdate}&enddate=${enddate}`)
		.then((response) => responseHandler(response))
		.catch((err) => {
			console.log(err);
			return errorHandler(err);
		});
}

export function getSiteReportVisitsByDate(siteId, startdate = _30Days, enddate = yesterday) {
	return internalAxiosInstance
		.get(`/sites/${siteId}/reports/visitsbydate?startdate=${startdate}&enddate=${enddate}`)
		.then((response) => responseHandler(response))
		.catch((err) => {
			console.log(err);
			return errorHandler(err);
		});
}

export function getSiteReportPopularityByDay(siteId, startdate = _30Days, enddate = yesterday) {
	return internalAxiosInstance
		.get(`/sites/${siteId}/reports/popularitybyday?startdate=${startdate}&enddate=${enddate}`, { timeout: 120000 })
		.then((response) => responseHandler(response))
		.catch((err) => {
			console.log(err);
			return errorHandler(err);
		});
}

export function getSiteReportDemographics(siteId, startdate = _30Days, enddate = yesterday) {
	return internalAxiosInstance
		.get(`/sites/${siteId}/reports/userdemographics?startdate=${startdate}&enddate=${enddate}`)
		.then((response) => responseHandler(response))
		.catch((err) => {
			console.log(err);
			return errorHandler(err);
		});
}

export function getSiteReportMonthlyStats(siteId) {
	const startDate = new Date(_1year).getTime() < new Date('2021-07-01').getTime() ? '2021-07-01' : _1year;
	return internalAxiosInstance
		.get(`/sites/${siteId}/reports/monthlyStats?startdate=${startDate}&enddate=${endOfCurrentMonth}`)
		.then((response) => responseHandler(response))
		.catch((err) => {
			console.log(err);
			return errorHandler(err);
		});
}
