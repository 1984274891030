import React from 'react';
import UnauthorizedTemplate from '../../templates/unauthorizedTemplate/index.template';

class UnauthorizedPage extends React.Component {
	render() {
		return <UnauthorizedTemplate {...this.props} />;
	}
}

export default UnauthorizedPage;
