import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';

const ErrorMessageSpan = (props) => {
	const classes = useStyles();
	const { text } = props;
	return (
		<div className={classes.textWrapper} data-testid='error-message-span'>
			<span className={classes.text}>{text}</span>
		</div>
	);
};

ErrorMessageSpan.propTypes = {
	text: PropTypes.string,
};

ErrorMessageSpan.defaultProps = {
	text: '',
};

export default ErrorMessageSpan;
