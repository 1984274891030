import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
	formControl: {
		width: (props) => (props.isContactUserPassword ? '100%' : 'calc(100% - 170px)'),
	},
	textField: {
		width: '100%',
		backgroundColor: 'white',

		'& .MuiOutlinedInput-input': {
			padding: '6px 14px',
		},
		'& fieldset': {
			borderColor: `#c4c4c4 !important`,
		},
	},
	root: {
		padding: 0,
	},
}));
