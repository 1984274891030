import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
	chartContainer: {
		height: '200px',
		marginRight: '10px',
	},
	chartFooterContainer: {
		textAlign: 'center',
		height: '50px',
		'& h3': {
			fontWeight: 'normal',
			fontSize: '12px',
			color: '#716e6a',
		},
	},
}));
