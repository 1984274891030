export default {
	tooltip: {
		padding: 0,
		backgroundColor: '#860000',
		borderRadius: 0,
		margin: 0,
	},
	popper: {
		left: '18px !important',
		top: '-11px !important',
	},
};
