import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
	summaryContainer: {
		textAlign: 'center',
		height: '100%',
		transform: 'translate(0%, 15%)',
	},
	summaryContainerWithSingleItem: {
		textAlign: 'center',
		padding: '70px 0',
	},
}));
