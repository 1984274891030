import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
	root: {
		'& .MuiSnackbarContent-root': {
			backgroundColor: '#a8e1d5',
			color: 'white',
		},
	},
	close: {
		padding: 5,
	},
}));
