import { makeStyles } from '@material-ui/styles';

export default makeStyles({
	section: {
		margin: '0px 50px 20px 20px',
	},
	statTitle: {
		fontSize: '16px',
		fontWeight: '600',
		textTransform: 'uppercase',
		marginBottom: '20px',
	},
});
