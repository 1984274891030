import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import NationalDistribution from './NationalDistribution';
import LikesAndSocioEconomic from './LikesAndSocioEconomic';
import KnowMeDescription from './KnowMeDescription';
import TopHeader from './TopHeader';

const KnowMeSection = (props) => {
	const { data, colourTheme, selectedSiteName } = props;

	return (
		<>
			<TopHeader data={data} selectedSiteName={selectedSiteName} colourTheme={colourTheme} />
			<Grid item container xs={12} justifyContent='center'>
				<KnowMeDescription description={data.description} images={data.galleryImages} colourTheme={colourTheme} />
			</Grid>
			<Grid item container xs={12}>
				<Grid item xs={12} lg={6}>
					<NationalDistribution nationalDistributionData={data.nationalDistribution} colourTheme={colourTheme} />
				</Grid>
				<Grid item xs={12} lg={6}>
					<LikesAndSocioEconomic likes={data.likes} socioEconomic={data.socioEconomic} colourTheme={colourTheme} />
				</Grid>
			</Grid>
		</>
	);
};

KnowMeSection.propTypes = {
	data: PropTypes.shape({
		headingText: PropTypes.string,
		secondaryText: PropTypes.string,
		centreName: PropTypes.string,
		cultureHeadingText: PropTypes.string,
		demographics: PropTypes.object,
		description: PropTypes.string,
		imageGallery: PropTypes.array,
		nationalDistribution: PropTypes.object,
		likes: PropTypes.object,
		socioEconomic: PropTypes.object,
	}),
	colourTheme: PropTypes.object,
	selectedSiteName: PropTypes.string,
};

KnowMeSection.defaultProps = {
	data: {
		headingText: '',
		secondaryText: '',
		centreName: '',
		cultureHeadingText: '',
		demographics: null,
		description: '',
		imageGallery: [],
		likes: null,
		socioEconomic: null,
	},
	colourTheme: null,
	selectedSiteName: '',
};

export default KnowMeSection;
