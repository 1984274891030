import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { amchartsStyle } from '../../../constants/Theme';
import { Colors } from '../../../constants/Constants.json';

am4core.useTheme(am4themes_animated);

class AveragePeakTimeLineChart extends React.Component {
	componentDidMount() {
		const { data, category, paddingTop = 20, axisColor = '#000', isCentrePage = false, id } = this.props;
		let chart = am4core.create(`chart-${id}`, am4charts.XYChart);

		chart.data = data;
		chart.paddingTop = paddingTop;

		// Create axes
		var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
		categoryAxis.dataFields.category = category;
		categoryAxis.renderer.grid.template.location = 0;
		categoryAxis.renderer.grid.template.stroke = axisColor;
		categoryAxis.renderer.labels.template.fontSize = amchartsStyle.labelsFontSize;
		categoryAxis.renderer.labels.template.fill = axisColor;
		categoryAxis.tooltip.disabled = true;

		var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
		valueAxis.renderer.labels.template.fontSize = amchartsStyle.labelsFontSize;
		valueAxis.renderer.labels.template.fill = axisColor;
		valueAxis.renderer.grid.template.stroke = axisColor;
		valueAxis.tooltip.disabled = true;
		valueAxis.extraMax = 0.5;
		valueAxis.renderer.minGridDistance = 25;

		let series01 = chart.series.push(new am4charts.LineSeries());
		series01.dataFields.valueY = 'Monday'; //day
		series01.dataFields.categoryX = category; //timerange
		series01.name = 'Monday';
		series01.strokeWidth = 3;
		series01.tensionX = 0.8;
		series01.stroke = Colors[11];
		series01.minBulletDistance = 15;

		let segment01 = series01.segments.template;
		segment01.interactionsEnabled = true;

		let hoverState01 = segment01.states.create('hover');
		hoverState01.properties.strokeWidth = 6;

		let dimmed01 = segment01.states.create('dimmed');
		dimmed01.properties.stroke = am4core.color('#dadada');

		segment01.events.on('over', function (event) {
			processOver(event.target.parent.parent.parent);
		});

		segment01.events.on('out', function (event) {
			processOut(event.target.parent.parent.parent);
		});

		let bullet01 = series01.bullets.push(new am4charts.CircleBullet());
		bullet01.circle.radius = 3;
		bullet01.circle.fill = am4core.color('#fff');
		bullet01.circle.strokeWidth = 1;

		let series02 = chart.series.push(new am4charts.LineSeries());
		series02.dataFields.valueY = 'Tuesday'; //day
		series02.dataFields.categoryX = category; //timerange
		series02.name = 'Tuesday';
		series02.strokeWidth = 3;
		series02.tensionX = 0.8;
		series02.stroke = Colors[1];
		series02.minBulletDistance = 15;

		let segment02 = series02.segments.template;
		segment02.interactionsEnabled = true;

		let hoverState02 = segment02.states.create('hover');
		hoverState02.properties.strokeWidth = 6;

		let dimmed02 = segment02.states.create('dimmed');
		dimmed02.properties.stroke = am4core.color('#dadada');

		segment02.events.on('over', function (event) {
			processOver(event.target.parent.parent.parent);
		});

		segment02.events.on('out', function (event) {
			processOut(event.target.parent.parent.parent);
		});

		let bullet02 = series02.bullets.push(new am4charts.CircleBullet());
		bullet02.circle.radius = 3;
		bullet02.circle.fill = am4core.color('#fff');
		bullet02.circle.strokeWidth = 1;

		let series03 = chart.series.push(new am4charts.LineSeries());
		series03.dataFields.valueY = 'Wednesday'; //day
		series03.dataFields.categoryX = category; //timerange
		series03.name = 'Wednesday';
		series03.strokeWidth = 3;
		series03.tensionX = 0.8;
		series03.stroke = Colors[8];
		series03.minBulletDistance = 15;

		let segment03 = series03.segments.template;
		segment03.interactionsEnabled = true;

		let hoverState03 = segment03.states.create('hover');
		hoverState03.properties.strokeWidth = 6;

		let dimmed03 = segment03.states.create('dimmed');
		dimmed03.properties.stroke = am4core.color('#dadada');

		segment03.events.on('over', function (event) {
			processOver(event.target.parent.parent.parent);
		});

		segment03.events.on('out', function (event) {
			processOut(event.target.parent.parent.parent);
		});

		let bullet03 = series03.bullets.push(new am4charts.CircleBullet());
		bullet03.circle.radius = 3;
		bullet03.circle.fill = am4core.color('#fff');
		bullet03.circle.strokeWidth = 1;

		let series04 = chart.series.push(new am4charts.LineSeries());
		series04.dataFields.valueY = 'Thursday'; //day
		series04.dataFields.categoryX = category; //timerange
		series04.name = 'Thursday';
		series04.strokeWidth = 3;
		series04.tensionX = 0.8;
		series04.stroke = Colors[14];
		series04.minBulletDistance = 15;

		let segment04 = series04.segments.template;
		segment04.interactionsEnabled = true;

		let hoverState04 = segment04.states.create('hover');
		hoverState04.properties.strokeWidth = 6;

		let dimmed04 = segment04.states.create('dimmed');
		dimmed04.properties.stroke = am4core.color('#dadada');

		segment04.events.on('over', function (event) {
			processOver(event.target.parent.parent.parent);
		});

		segment04.events.on('out', function (event) {
			processOut(event.target.parent.parent.parent);
		});

		let bullet04 = series04.bullets.push(new am4charts.CircleBullet());
		bullet04.circle.radius = 3;
		bullet04.circle.fill = am4core.color('#fff');
		bullet04.circle.strokeWidth = 1;

		let series05 = chart.series.push(new am4charts.LineSeries());
		series05.dataFields.valueY = 'Friday'; //day
		series05.dataFields.categoryX = category; //timerange
		series05.name = 'Friday';
		series05.strokeWidth = 3;
		series05.tensionX = 0.8;
		series05.stroke = Colors[9];
		series05.minBulletDistance = 15;

		let segment05 = series05.segments.template;
		segment05.interactionsEnabled = true;

		let hoverState05 = segment05.states.create('hover');
		hoverState05.properties.strokeWidth = 6;

		let dimmed05 = segment05.states.create('dimmed');
		dimmed05.properties.stroke = am4core.color('#dadada');

		segment05.events.on('over', function (event) {
			processOver(event.target.parent.parent.parent);
		});

		segment05.events.on('out', function (event) {
			processOut(event.target.parent.parent.parent);
		});

		let bullet05 = series05.bullets.push(new am4charts.CircleBullet());
		bullet05.circle.radius = 3;
		bullet05.circle.fill = am4core.color('#fff');
		bullet05.circle.strokeWidth = 1;

		let series06 = chart.series.push(new am4charts.LineSeries());
		series06.dataFields.valueY = 'Saturday'; //day
		series06.dataFields.categoryX = category; //timerange
		series06.name = 'Saturday';
		series06.strokeWidth = 3;
		series06.tensionX = 0.8;
		series06.stroke = Colors[13];
		series06.minBulletDistance = 15;

		let segment06 = series06.segments.template;
		segment06.interactionsEnabled = true;

		let hoverState06 = segment06.states.create('hover');
		hoverState06.properties.strokeWidth = 6;

		let dimmed06 = segment06.states.create('dimmed');
		dimmed06.properties.stroke = am4core.color('#dadada');

		segment06.events.on('over', function (event) {
			processOver(event.target.parent.parent.parent);
		});

		segment06.events.on('out', function (event) {
			processOut(event.target.parent.parent.parent);
		});

		let bullet06 = series06.bullets.push(new am4charts.CircleBullet());
		bullet06.circle.radius = 3;
		bullet06.circle.fill = am4core.color('#fff');
		bullet06.circle.strokeWidth = 1;

		let series07 = chart.series.push(new am4charts.LineSeries());
		series07.dataFields.valueY = 'Sunday'; //day
		series07.dataFields.categoryX = category; //timerange
		series07.name = 'Sunday';
		series07.strokeWidth = 3;
		series07.tensionX = 0.8;
		series07.stroke = Colors[10];
		series07.minBulletDistance = 15;

		let segment07 = series07.segments.template;
		segment07.interactionsEnabled = true;

		let hoverState07 = segment07.states.create('hover');
		hoverState07.properties.strokeWidth = 6;

		let dimmed07 = segment07.states.create('dimmed');
		dimmed07.properties.stroke = am4core.color('#dadada');

		segment07.events.on('over', function (event) {
			processOver(event.target.parent.parent.parent);
		});

		segment07.events.on('out', function (event) {
			processOut(event.target.parent.parent.parent);
		});

		let bullet07 = series07.bullets.push(new am4charts.CircleBullet());
		bullet07.circle.radius = 3;
		bullet07.circle.fill = am4core.color('#fff');
		bullet07.circle.strokeWidth = 1;
		series07.tooltip.pointerOrientation = 'vertical';
		series07.tooltip.fontSize = amchartsStyle.tooltipFontSize;
		series07.tooltip.getFillFromObject = false;
		series07.tooltip.background.fill = am4core.color(Colors[1]);

		if (isCentrePage) {
			// changes axes colour
			categoryAxis.renderer.labels.template.fill = valueAxis.renderer.labels.template.fill = '#716E6A';

			// disables vertical grid lines
			categoryAxis.renderer.grid.template.disabled = true;
		}

		chart.legend = new am4charts.Legend();
		chart.legend.position = 'bottom';
		chart.legend.scrollable = true;
		chart.legend.fontSize = amchartsStyle.legendFontSize;
		chart.legend.labels.template.fill = axisColor;
		chart.legend.maxHeight = 20;
		chart.legend.labels.template.text = '{name}[/]';
		var markerTemplate = chart.legend.markers.template;
		markerTemplate.width = amchartsStyle.legendWidthHeight;
		markerTemplate.height = amchartsStyle.legendWidthHeight;
		chart.legend.itemContainers.template.events.on('over', function (event) {
			processOver(event.target.dataItem.dataContext);
		});

		chart.legend.itemContainers.template.events.on('out', function (event) {
			processOut(event.target.dataItem.dataContext);
		});

		function processOver(hoveredSeries) {
			hoveredSeries.toFront();

			hoveredSeries.segments.each(function (segment) {
				segment.setState('hover');
			});

			chart.series.each(function (series) {
				if (series !== hoveredSeries) {
					series.segments.each(function (segment) {
						segment.setState('dimmed');
					});
					series.bulletsContainer.setState('dimmed');
				}
			});
		}

		function processOut(hoveredSeries) {
			chart.series.each(function (series) {
				series.segments.each(function (segment) {
					segment.setState('default');
				});
				series.bulletsContainer.setState('default');
			});
		}

		// Make a cursor
		chart.cursor = new am4charts.XYCursor();

		this.chart = chart;
	}
	componentDidUpdate(prevProps) {
		const { data } = this.props;
		if (data.length > 0 && !isEqual(prevProps.data, data)) {
			this.chart.data = data;
		}
	}
	componentWillUnmount() {
		if (this.chart) {
			this.chart.dispose();
		}
	}
	render() {
		const { id } = this.props;
		return <div id={`chart-${id}`} style={{ width: '100%', height: '100%' }} data-testid='average-peak-time-line-chart'></div>;
	}
}

AveragePeakTimeLineChart.propTypes = {
	data: PropTypes.array,
	category: PropTypes.string,
	paddingTop: PropTypes.number,
	axisColor: PropTypes.string,
	isCentrePage: PropTypes.bool,
	id: PropTypes.string,
};

AveragePeakTimeLineChart.defaultProps = {
	data: [],
	category: '',
	paddingTop: 20,
	axisColor: '#000',
	isCentrePage: false,
	id: '',
};

export default AveragePeakTimeLineChart;
