import { Map } from 'immutable';
import * as ACTION_TYPES from '../../constants/ActionTypes';

export const initState = new Map({
	populationDataLoader: false,
	populationDataErrorMessage: '',
	genderPopulation: [],
	agePopulation: [],

	ageGroupsDataLoader: false,
	ageGroupsDataErrorMessage: '',
	ageGroupsData: [],

	workOccupationDataLoader: false,
	workOccupationDataErrorMessage: '',
	workOccupationData: [],

	ethnicityAndLanguagesDataLoader: false,
	ethnicityAndLanguagesDataErrorMessage: '',
	ethnicityData: [],
	languagesData: [],

	maritalStatusAndFamilyTypesDataLoader: false,
	maritalStatusAndFamilyTypesDataErrorMessage: '',
	maritalStatusData: [],
	familyTypesData: [],

	propertyOwnershipDataLoader: false,
	propertyOwnershipDataErrorMessage: '',
	propertyOwnershipData: [],
	monthlyMortgageRepaymentsData: '',
});

export default function censusDataReducer(state = initState, action) {
	switch (action.type) {
		case ACTION_TYPES.FETCH_CENSUS_DASHBOARD_DATA:
			return state
				.set('populationDataLoader', true)
				.set('populationDataErrorMessage', '')
				.set('genderPopulation', [])
				.set('agePopulation', [])

				.set('ageGroupsDataLoader', true)
				.set('ageGroupsDataErrorMessage', '')
				.set('ageGroupsData', [])

				.set('workOccupationDataLoader', true)
				.set('workOccupationDataErrorMessage', '')
				.set('workOccupationData', [])

				.set('ethnicityAndLanguagesDataLoader', true)
				.set('ethnicityAndLanguagesDataErrorMessage', '')
				.set('ethnicityData', [])
				.set('languagesData', [])

				.set('maritalStatusAndFamilyTypesDataLoader', true)
				.set('maritalStatusAndFamilyTypesDataErrorMessage', '')
				.set('maritalStatusData', [])
				.set('familyTypesData', [])

				.set('propertyOwnershipDataLoader', true)
				.set('propertyOwnershipDataErrorMessage', '')
				.set('propertyOwnershipData', [])
				.set('monthlyMortgageRepaymentsData', '');

		case ACTION_TYPES.FETCH_POPULATION_DATA:
			return state.set('populationDataLoader', true).set('populationDataErrorMessage', '').set('genderPopulation', []).set('agePopulation', []);
		case ACTION_TYPES.FETCH_POPULATION_DATA_DONE:
			return state
				.set('populationDataLoader', false)
				.set('populationDataErrorMessage', '')
				.set('genderPopulation', action.data.genderPopulation)
				.set('agePopulation', action.data.agePopulation);
		case ACTION_TYPES.FETCH_POPULATION_DATA_FAILED:
			return state
				.set('populationDataLoader', false)
				.set('populationDataErrorMessage', action.error.message)
				.set('genderPopulation', [])
				.set('agePopulation', []);

		case ACTION_TYPES.FETCH_AGE_GROUPS_DATA:
			return state.set('ageGroupsDataLoader', true).set('ageGroupsDataErrorMessage', '').set('ageGroupsData', []);
		case ACTION_TYPES.FETCH_AGE_GROUPS_DATA_DONE:
			return state.set('ageGroupsDataLoader', false).set('ageGroupsDataErrorMessage', '').set('ageGroupsData', action.data);
		case ACTION_TYPES.FETCH_AGE_GROUPS_DATA_FAILED:
			return state.set('ageGroupsDataLoader', false).set('ageGroupsDataErrorMessage', action.error.message).set('ageGroupsData', []);

		case ACTION_TYPES.FETCH_WORK_OCCUPATION_DATA:
			return state.set('workOccupationDataLoader', true).set('workOccupationDataErrorMessage', '').set('workOccupationData', []);
		case ACTION_TYPES.FETCH_WORK_OCCUPATION_DATA_DONE:
			return state.set('workOccupationDataLoader', false).set('workOccupationDataErrorMessage', '').set('workOccupationData', action.data);
		case ACTION_TYPES.FETCH_WORK_OCCUPATION_DATA_FAILED:
			return state.set('workOccupationDataLoader', false).set('workOccupationDataErrorMessage', action.error.message).set('workOccupationData', []);

		case ACTION_TYPES.FETCH_ETHNICITY_AND_LANGUAGES_DATA:
			return state
				.set('ethnicityAndLanguagesDataLoader', true)
				.set('ethnicityAndLanguagesDataErrorMessage', '')
				.set('ethnicityData', [])
				.set('languagesData', []);
		case ACTION_TYPES.FETCH_ETHNICITY_AND_LANGUAGES_DATA_DONE:
			return state
				.set('ethnicityAndLanguagesDataLoader', false)
				.set('ethnicityAndLanguagesDataErrorMessage', '')
				.set('ethnicityData', action.data.ethnicity)
				.set('languagesData', action.data.languages);
		case ACTION_TYPES.FETCH_ETHNICITY_AND_LANGUAGES_DATA_FAILED:
			return state
				.set('ethnicityAndLanguagesDataLoader', false)
				.set('ethnicityAndLanguagesDataErrorMessage', action.error.message)
				.set('ethnicityData', [])
				.set('languagesData', []);

		case ACTION_TYPES.FETCH_MARITAL_STATUS_AND_FAMILY_TYPES_DATA:
			return state
				.set('maritalStatusAndFamilyTypesDataLoader', true)
				.set('maritalStatusAndFamilyTypesDataErrorMessage', '')
				.set('maritalStatusData', [])
				.set('familyTypesData', []);
		case ACTION_TYPES.FETCH_MARITAL_STATUS_AND_FAMILY_TYPES_DATA_DONE:
			return state
				.set('maritalStatusAndFamilyTypesDataLoader', false)
				.set('maritalStatusAndFamilyTypesDataErrorMessage', '')
				.set('maritalStatusData', action.data.maritalStatus)
				.set('familyTypesData', action.data.familyTypes);
		case ACTION_TYPES.FETCH_MARITAL_STATUS_AND_FAMILY_TYPES_DATA_FAILED:
			return state
				.set('maritalStatusAndFamilyTypesDataLoader', false)
				.set('maritalStatusAndFamilyTypesDataErrorMessage', action.error.message)
				.set('maritalStatusData', [])
				.set('familyTypesData', []);

		case ACTION_TYPES.FETCH_PROPERTY_OWNER_SHIP_DATA:
			return state
				.set('propertyOwnershipDataLoader', true)
				.set('propertyOwnershipDataErrorMessage', '')
				.set('propertyOwnershipData', [])
				.set('monthlyMortgageRepaymentsData', '');
		case ACTION_TYPES.FETCH_PROPERTY_OWNER_SHIP_DATA_DONE:
			return state
				.set('propertyOwnershipDataLoader', false)
				.set('propertyOwnershipDataErrorMessage', '')
				.set('propertyOwnershipData', action.data.propertyOwnership)
				.set('monthlyMortgageRepaymentsData', action.data.monthlyMortgageRepayments);
		case ACTION_TYPES.FETCH_PROPERTY_OWNER_SHIP_DATA_FAILED:
			return state
				.set('propertyOwnershipDataLoader', false)
				.set('propertyOwnershipDataErrorMessage', action.error.message)
				.set('propertyOwnershipData', [])
				.set('monthlyMortgageRepaymentsData', '');

		default:
			return state;
	}
}
