import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import ReactSVG from 'react-svg';

import useStyles from './styles';

const DemographicsItem = (props) => {

	const { demographicsItem } = props;

		const classes = useStyles(props);

	return (
		<Grid item xs={12} lg={6}>
			<div className={classes.personaDemographic}>
				<span className={classes.personaDemographicIcon}>
					<ReactSVG src={require(`../../../Icons/personas/${demographicsItem.filename}`)} className={classes.customIcon} />
				</span>
				<span className={classes.personaDemographicLabel}>{demographicsItem.label}</span>
				<span className={classes.demographicValue}>{demographicsItem.value}</span>
			</div>
		</Grid>
	);
};

DemographicsItem.propTypes = {
	demographicsItem: PropTypes.object,
	colourTheme: PropTypes.object,
};

DemographicsItem.defaultProps = {
	demographicsItem: null,
	colourTheme: null,
};

export default DemographicsItem;
