import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
	sectionHeader: {
		padding: '50px 0px 50px 20px',
	},
	sectionHeaderText: {
		fontSize: '30px',
		fontWeight: 'bold',
	},
	statTitle: {
		fontSize: '16px',
		fontWeight: '600',
		textTransform: 'uppercase',
		marginBottom: '20px',
	},
	subSection: {
		padding: '0px 20px 20px 20px',
	},
}));
