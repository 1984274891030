import * as ACTION_TYPES from '../../constants/ActionTypes';

export function fetchCensusDashboardData(siteId, censusRadius) {
	return {
		type: ACTION_TYPES.FETCH_CENSUS_DASHBOARD_DATA,
		siteId,
		censusRadius,
	};
}

export function fetchPopulationData(siteId, censusRadius) {
	return {
		type: ACTION_TYPES.FETCH_POPULATION_DATA,
		siteId,
		censusRadius,
	};
}

export function fetchPopulationDataDone(data) {
	return {
		type: ACTION_TYPES.FETCH_POPULATION_DATA_DONE,
		data,
	};
}

export function fetchPopulationDataFailed(error) {
	return {
		type: ACTION_TYPES.FETCH_POPULATION_DATA_FAILED,
		error,
	};
}

export function fetchAgeGroupsData(siteId, censusRadius) {
	return {
		type: ACTION_TYPES.FETCH_AGE_GROUPS_DATA,
		siteId,
		censusRadius,
	};
}

export function fetchAgeGroupsDataDone(data) {
	return {
		type: ACTION_TYPES.FETCH_AGE_GROUPS_DATA_DONE,
		data,
	};
}

export function fetchAgeGroupsDataFailed(error) {
	return {
		type: ACTION_TYPES.FETCH_AGE_GROUPS_DATA_FAILED,
		error,
	};
}

export function fetchWorkOccupationData(siteId, censusRadius) {
	return {
		type: ACTION_TYPES.FETCH_WORK_OCCUPATION_DATA,
		siteId,
		censusRadius,
	};
}

export function fetchWorkOccupationDataDone(data) {
	return {
		type: ACTION_TYPES.FETCH_WORK_OCCUPATION_DATA_DONE,
		data,
	};
}

export function fetchWorkOccupationDataFailed(error) {
	return {
		type: ACTION_TYPES.FETCH_WORK_OCCUPATION_DATA_FAILED,
		error,
	};
}

export function fetchEthnicityAndLanguagesData(siteId, censusRadius) {
	return {
		type: ACTION_TYPES.FETCH_ETHNICITY_AND_LANGUAGES_DATA,
		siteId,
		censusRadius,
	};
}

export function fetchEthnicityAndLanguagesDataDone(data) {
	return {
		type: ACTION_TYPES.FETCH_ETHNICITY_AND_LANGUAGES_DATA_DONE,
		data,
	};
}

export function fetchEthnicityAndLanguagesDataFailed(error) {
	return {
		type: ACTION_TYPES.FETCH_ETHNICITY_AND_LANGUAGES_DATA_FAILED,
		error,
	};
}

export function fetchMaritalStatusAndFamilyTypesData(siteId, censusRadius) {
	return {
		type: ACTION_TYPES.FETCH_MARITAL_STATUS_AND_FAMILY_TYPES_DATA,
		siteId,
		censusRadius,
	};
}

export function fetchMaritalStatusAndFamilyTypesDataDone(data) {
	return {
		type: ACTION_TYPES.FETCH_MARITAL_STATUS_AND_FAMILY_TYPES_DATA_DONE,
		data,
	};
}

export function fetchMaritalStatusAndFamilyTypesDataFailed(error) {
	return {
		type: ACTION_TYPES.FETCH_MARITAL_STATUS_AND_FAMILY_TYPES_DATA_FAILED,
		error,
	};
}

export function fetchPropertyOwnershipData(siteId, censusRadius) {
	return {
		type: ACTION_TYPES.FETCH_PROPERTY_OWNER_SHIP_DATA,
		siteId,
		censusRadius,
	};
}

export function fetchPropertyOwnershipDataDone(data) {
	return {
		type: ACTION_TYPES.FETCH_PROPERTY_OWNER_SHIP_DATA_DONE,
		data,
	};
}

export function fetchPropertyOwnershipDataFailed(error) {
	return {
		type: ACTION_TYPES.FETCH_PROPERTY_OWNER_SHIP_DATA_FAILED,
		error,
	};
}
