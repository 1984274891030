import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Grid } from '@material-ui/core';
import LikeItem from '../LikesAndSocioEconomic/LikeItem';

import useStyles from './styles';

const Likes = (props) => {
	const classes = useStyles(props);

	const { likes, colourTheme } = props;

	return (
		<div className={classNames(classes.likes)}>
			<Grid item xs={12}>
				<h3 className={classNames(classes.statTitle)}>{likes.headingText}</h3>
			</Grid>
			<Grid container item xs={12}>
				{likes.data.map((like, index) => {
					return <LikeItem like={like} index={index} key={`likes-${index}`} colourTheme={colourTheme} />;
				})}
			</Grid>
		</div>
	);
};

Likes.propTypes = {
	likes: PropTypes.object,
	colourTheme: PropTypes.object,
};

Likes.defaultProps = {
	likes: null,
	colourTheme: null,
};

export default Likes;
