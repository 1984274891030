export default (theme) => ({
	textField: {
		width: '90%',
		margin: '0 10px',
		height: 'auto',
		'& .MuiInputBase-root': {
			color: '#0762C8',
			'& input': {
				fontSize: 14,
				padding: '0px 20px',
				borderRadius: 15,
				height: 50,
			},
			'& .MuiSelect-select:focus': {
				backgroundColor: '#0762C8',
			},
		},
	},
	textField1: {
		width: '100%',
		margin: '0 10px',
		height: 'auto',
		'& .MuiInputBase-root': {
			color: '#0762C8',
			'& input': {
				fontSize: 14,
				padding: '0px 20px',
				borderTopLeftRadius: 13,
				borderBottomLeftRadius: 13,
				borderBottomRightRadius: 0,
				borderTopRightRadius: 0,
				height: 50,
			},
			'& .MuiSelect-select:focus': {
				backgroundColor: '#0762C8',
			},
		},
	},
	cssOutlinedInput: {
		'&$cssFocused $notchedOutline': {
			borderColor: '#ffffff !important',
			borderRadius: 15,
		},
	},
	cssFocused: {},
	notchedOutline: {
		borderColor: '#ffffff !important',
		borderRadius: 15,
		borderWidth: 2,
	},

	cssOutlinedInput1: {
		'&$cssFocused $notchedOutline': {
			border: 'none !important',
		},
	},
	notchedOutline1: {
		border: 'none !important',
	},
});
