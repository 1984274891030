import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import classNames from 'classnames/bind';
import SiteDashboardHeader from '../../Headers/SiteDashboardHeader';
import HyveCard from '../../CustomCard';
import ColumnBarChart from '../../Charts/ColumnBarChart';
import CustomLoader from '../../CustomLoader';
import ErrorMessageSpan from '../../TextFields/ErrorMessageSpan';
import { Colors } from '../../../constants/Constants.json';
import images from '../../../components/Icons';
import styles from './styles.scss';

const cx = classNames.bind(styles);

const _1year = moment().tz('Australia/Sydney').startOf('month').subtract(11, 'months').format('YYYY-MM-DD');
const is1YearBeforeJuly21 = new Date(_1year).getTime() < new Date('2021-07-01').getTime();
const yesterday = moment().tz('Australia/Sydney').subtract(24, 'hours').format('YYYY-MM-DD');

class MonthlyVisitation extends React.Component {
	render() {
		const { isBullet, MonthlyStatsLoader, ranges, timeRange, errorMessageMonthlyStats, monthlyStats } = this.props;
		return (
			<div className={cx('monthlyCard')}>
				<HyveCard isCentrePage={true}>
					<SiteDashboardHeader
						title='MONTHLY VISITS'
						color={Colors[16]}
						textColor={'#000'}
						isCentrePage={true}
						timeRange={timeRange}
						startDateProps={is1YearBeforeJuly21 ? '2021-07-01' : _1year}
						endDateProps={yesterday}
						ranges={ranges}
						icon={images.calendarMonth}
					/>
					<div className={cx('monthlyGraph')}>
						{MonthlyStatsLoader ? (
							<CustomLoader size={40} />
						) : errorMessageMonthlyStats ? (
							<ErrorMessageSpan text={errorMessageMonthlyStats} />
						) : (
							<ColumnBarChart
								data={monthlyStats.toJS()}
								valueY='unique_visits'
								category='month'
								id='monthlyVisits'
								isBullet={isBullet}
								color={Colors[15]}
								paddingRight={20}
								paddingTop={20}
								paddingBottom={0}
								barWidth={80}
								isCentrePage={true}
							/>
						)}
					</div>
				</HyveCard>
			</div>
		);
	}
}

MonthlyVisitation.propTypes = {
	isBullet: PropTypes.bool,
	ranges: PropTypes.array,
	timeRange: PropTypes.number,
};

MonthlyVisitation.defaultProps = {
	isBullet: false,
	ranges: [],
	timeRange: null,
};

const mapStateToProps = (state) => {
	return {
		monthlyStats: state.sitesReducer.get('monthlyStats'),
		MonthlyStatsLoader: state.sitesReducer.get('MonthlyStatsLoader'),
		errorMessageMonthlyStats: state.sitesReducer.get('errorMessageMonthlyStats'),
	};
};

export default connect(mapStateToProps)(MonthlyVisitation);
