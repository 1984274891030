import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import ReactSVG from 'react-svg';

import useStyles from './styles';

const DemographicsItem = (props) => {
	const classes = useStyles(props);

	const { demographics } = props;

	return (
		<div className={classes.section}>
			<Grid item container xs={12}>
				{demographics.data.map((demographic, index) => {
					return (
						<Grid item xs={6} key={`demographics-item-${index}`}>
							<div className={classes.row}>
								<div className={classes.icon}>
									<ReactSVG src={require(`../../../Icons/personas/${demographic.filename}`)} className={classes.customIcon} />
								</div>
								<div>
									<b>{demographic.value}</b>
									<br />
									<span>{demographic.label}</span>
								</div>
							</div>
						</Grid>
					);
				})}
			</Grid>
		</div>
	);
};

DemographicsItem.propTypes = {
	demographics: PropTypes.object,
};

DemographicsItem.defaultProps = {
	demographics: null,
};

export default DemographicsItem;
