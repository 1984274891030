import React from 'react';
import PropTypes from 'prop-types';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames/bind';
import SecondaryExpansionPanel from '../SecondaryExpansionPanel';
import styles from './styles.scss';
import panelStyles from './styles.js';

const cx = classNames.bind(styles);

const CustomExpansionPanelDetails = withStyles(panelStyles)(AccordionDetails);

class ExpansionPanelDetails extends React.Component {
	render() {
		const { history, menu, Menu, menuIdentifier, selectedSubMenuIdentifier, selectedThirdSubMenuIdentifier, isMainMenuCollapsed, handleClick } = this.props;
		return (
			<CustomExpansionPanelDetails>
				{!isMainMenuCollapsed && menu !== undefined && menu.size > 0 && (
					<div className={cx('expansionPanelDetailsItemsWrapper')} data-testid='expansion-panel-details'>
						{menu
							.sort((a, b) => a.resource_index - b.resource_index)
							.map((menu_item) => {
								let link = '/centre_dashboard';
								Menu.some((Menu_item) => {
									if (Menu_item.identifier === menu_item.get('_identifier')) {
										link = Menu_item.link;
										return true;
									}
									return false;
								});
								return (
									<div
										className={cx('expansionPanelDetailsItem', {
											unSelected: selectedSubMenuIdentifier === undefined || selectedSubMenuIdentifier !== menu_item.get('_identifier'),
											isSelected: selectedSubMenuIdentifier && selectedSubMenuIdentifier === menu_item.get('_identifier'),
										})}
										key={menu_item.get('_identifier')}
										onClick={menu_item.get('children') ? null : () => handleClick(link)}
										data-identifier={`expansion-panel-details-menu-identifier-${menu_item.get('_identifier')}`}
									>
										{menu_item.get('children') && menu_item.get('children').size ? (
											<SecondaryExpansionPanel
												key={menu_item.get('_identifier')}
												history={history}
												isMainMenuCollapsed={isMainMenuCollapsed}
												name={menu_item.get('resource_name')}
												Menu={Menu}
												menu={menu_item.get('children')}
												isSelected={selectedSubMenuIdentifier === menu_item.get('_identifier')}
												parentMenuIdentifier={menuIdentifier}
												selectedParentMenuIdentifier={selectedSubMenuIdentifier}
												menuIdentifier={menu_item.get('_identifier')}
												selectedMenuIdentifier={selectedThirdSubMenuIdentifier}
											/>
										) : (
											<span className={cx('expansionPanelDetailsItemText')}>{menu_item.get('resource_name')}</span>
										)}
									</div>
								);
							})}
					</div>
				)}
			</CustomExpansionPanelDetails>
		);
	}
}

ExpansionPanelDetails.propTypes = {
	history: PropTypes.object,
	menu: PropTypes.object,
	Menu: PropTypes.array,
	menuIdentifier: PropTypes.string,
	selectedSubMenuIdentifier: PropTypes.string,
	selectedThirdSubMenuIdentifier: PropTypes.string,
	isMainMenuCollapsed: PropTypes.bool,
	handleClick: PropTypes.func,
};

ExpansionPanelDetails.defaultProps = {
	history: {},
	menu: {},
	Menu: [],
	menuIdentifier: '',
	selectedSubMenuIdentifier: '',
	selectedThirdSubMenuIdentifier: '',
	isMainMenuCollapsed: false,
	handleClick: null,
};

export default ExpansionPanelDetails;
