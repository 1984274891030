import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
	popover: {
		'& .MuiPaper-root': {
			padding: '5px 0px',
			marginLeft: 10,
		},
	},
	item: {
		fontSize: 13,
		paddingTop: 0,
		paddingBottom: 0,
		minHeight: 30,
		color: 'rgb(23, 43, 77)',
	},
	border: {
		borderBottom: '1px solid rgba(0, 0, 0, 0.15)',
	},
	menuItemIcon: {
		fontSize: 20,
		marginRight: 5,
	},
}));
