import React from 'react';
import useStyles from './styles';

const BorderedSection = (props) => {
	const classes = useStyles(props);

	const { children, title } = props;

	return (
		<div className={classes.mainContainer}>
			<div className={classes.header}>
				<div className={classes.headerBorderBefore}></div>
				<div className={classes.headerTitle}>
					<span className={classes.title}>{title}</span>
				</div>
				<div className={classes.headerBorderAfter}></div>
			</div>
			<div className={classes.childrenContainer}>{children}</div>
		</div>
	);
};

export default BorderedSection;
