/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { connect } from 'react-redux';
import IdleTimer from 'react-idle-timer';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import * as am4core from '@amcharts/amcharts4/core';
import { signOut, getUserPermissionsAction } from './redux/actions/authActions';
import { getToken, getCurrentUserInfo } from './helpers/utility';
import history from './history';
import AppLayout from './components/Layout/AppLayout';
import PageNotFound from './pages/pageNotFound';
import PrivateRoute from '../src/components/Layout/PrivateRoute';
import SignInPage from './pages/signIn';
import './App.css';

am4core.addLicense('CH214414463');

class App extends React.Component {
	constructor(props) {
		super(props);
		this.idleTimer = null;
		this.onIdle = this._onIdle.bind(this);
	}

	componentDidMount() {
		if (getToken()) {
			const { getUserPermissionsAction, signOut } = this.props;
			const userInfo = getCurrentUserInfo();
			if (userInfo && userInfo.username) {
				getUserPermissionsAction(userInfo.username);
			} else {
				signOut();
			}
		}
	}

	componentDidUpdate(prevProps) {
		const { getUserPermissionsAction, signOut, loginSuccess, userInfo } = this.props;
		const hasExternalUserResetPassword = userInfo.size !== 0 && userInfo.get('reset_password') !== null ? false : true;
		if (getToken()) {
			if (loginSuccess && !prevProps.loginSuccess) {
				const userInfo = getCurrentUserInfo();
				if (userInfo && userInfo.username) {
					getUserPermissionsAction(userInfo.username);
				} else {
					signOut();
				}
			}
		}
		if (!hasExternalUserResetPassword) {
			history.push('/user_preferences');
		}
	}
	_onIdle() {
		this.props.signOut();
	}

	render() {
		const { permissionLoading } = this.props;

		if (getToken() === null) {
			return (
				<div className='App'>
					<Router history={history}>
						<Switch>
							<Route exact path='/sign_in' render={(props) => <SignInPage {...props} />} />
							<Route path='*' render={() => <Redirect to='/sign_in' />} />
						</Switch>
					</Router>
				</div>
			);
		}

		if (permissionLoading) {
			return (
				<div className='App'>
					<div className='LoadingWrapper'>
						<div className='LoadingInside'>
							<div className='Loading' />
							<p>Loading...</p>
						</div>
					</div>
				</div>
			);
		}

		return (
			<div className='App'>
				<Router history={history}>
					<IdleTimer
						ref={(ref) => {
							this.idleTimer = ref;
						}}
						element={document}
						onActive={this.onActive}
						onIdle={this.onIdle}
						onAction={this.onAction}
						debounce={250}
						stopOnIdle
						timeout={1000 * 120 * Number(process.env.REACT_APP_INACTIVITY_TIMEOUT_MINS)}
					/>
					<Switch>
						<Route exact path='/404' component={PageNotFound} />
						<PrivateRoute hasPermission path='*' component={AppLayout} />
					</Switch>
				</Router>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	loginSuccess: state.authReducer.get('loginSuccess'),
	permissionLoading: state.authReducer.get('permissionLoading'),
	userInfo: state.authReducer.get('userInfo'),
});

const mapDispatchToProps = (dispatch) => ({
	signOut: () => dispatch(signOut()),
	getUserPermissionsAction: (username) => dispatch(getUserPermissionsAction(username)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
