import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { signOut } from '../../../redux/actions/authActions';
import SideMenu from '../../Menus/SideMenu';
import AppRouter from '../AppRouter';
import AppHeader from '../../Headers/AppHeader';
import styles from './styles.scss';

const cx = classNames.bind(styles);

const ref = React.createRef();

class AppLayout extends React.Component {
	state = {
		clientWidth: 0,
	};

	componentDidMount() {
		window.addEventListener('resize', this.updateDimensions);
		this.setState({
			clientWidth: ref.current.clientWidth,
		});
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateDimensions);
	}

	updateDimensions = () => {
		this.setState({
			clientWidth: ref.current.clientWidth,
		});
	};

	render() {
		const { history, signOut, userInfo, isMainMenuCollapsed } = this.props;

		return (
			<div className={cx('appWrapper')}>
				<div className={cx('layout')}>
					<AppHeader signOut={signOut} history={history} userInfo={userInfo} />
					<main className={cx('middleContent')}>
						<aside className={cx('mainMenuSide', { collapsed: isMainMenuCollapsed, expanded: !isMainMenuCollapsed })} trigger={null}>
							<SideMenu
								history={history}
								isMainMenuCollapsed={isMainMenuCollapsed}
								handleLogout={signOut}
								firstName={userInfo.size > 0 ? userInfo.get('first_name') : ''}
							/>
						</aside>
						<main
							ref={ref}
							className={cx('content', { marginLeft60: isMainMenuCollapsed, marginLeft220: !isMainMenuCollapsed })}
							onClick={this.handleClickOutside}
						>
							<AppRouter clientWidth={this.state.clientWidth} />
						</main>
					</main>
				</div>
			</div>
		);
	}
}

AppLayout.propTypes = {
	history: PropTypes.object,
	userInfo: PropTypes.object,
	isMainMenuCollapsed: PropTypes.bool,
};

AppLayout.defaultProps = {
	history: {},
	userInfo: {},
	isMainMenuCollapsed: false,
};

const mapStateToProps = (state) => ({
	isMainMenuCollapsed: state.appReducer.get('isMainMenuCollapsed'),
	userInfo: state.authReducer.get('userInfo'),
});

const mapDispatchToProps = (dispatch) => ({
	signOut: () => dispatch(signOut()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppLayout);
