import React from 'react';
import PropTypes from 'prop-types';
import SecondaryExpansionPanel from '../SecondaryExpansionPanel';
import classNames from 'classnames/bind';
import styles from './styles.scss';
const cx = classNames.bind(styles);

class SubMenu extends React.Component {
	handleClick = (link) => {
		const { history } = this.props;
		history.push(link);
	};

	render() {
		const { history, menu, Menu, menuIdentifier, selectedSubMenuIdentifier, selectedMenuIdentifier } = this.props;

		return (
			<div className={cx('submenuWrapper')} data-testid='sub-menu'>
				{menu !== undefined && menu.size > 0 && (
					<div className={cx('submenuInside')}>
						{menu.map((menu_item, index) => {
							let link = '/centre_dashboard';
							Menu.some((Menu_item) => {
								if (Menu_item.identifier === menu_item.get('_identifier')) {
									link = Menu_item.link;
									return true;
								} else return false;
							});
							return (
								<div
									className={cx('submenuItem', {
										unSelected: selectedMenuIdentifier === undefined || selectedMenuIdentifier !== menu_item.get('_identifier'),
										isSelected: selectedMenuIdentifier === menu_item.get('_identifier'),
									})}
									key={index}
									onClick={menu_item.get('children') ? null : () => this.handleClick(link)}
									data-identifier={`sub-menu-${menu_item.get('_identifier')}`}
								>
									{menu_item.get('children') && menu_item.get('children').size ? (
										<SecondaryExpansionPanel
											key={index}
											order={index}
											history={history}
											isMainMenuCollapsed={false}
											name={menu_item.get('resource_name')}
											Menu={Menu}
											isSelected={selectedSubMenuIdentifier === menu_item.get('_identifier')}
											parentMenuIdentifier={menuIdentifier}
											menu={menu_item.get('children')}
											menuIdentifier={menu_item.get('_identifier')}
											selectedMenuIdentifier={selectedSubMenuIdentifier}
										/>
									) : (
										<span className={cx('submenuItemText')}>{menu_item.get('resource_name')}</span>
									)}
								</div>
							);
						})}
					</div>
				)}
			</div>
		);
	}
}

SubMenu.propTypes = {
	history: PropTypes.object,
	menu: PropTypes.object,
	Menu: PropTypes.array,
	menuIdentifier: PropTypes.string,
	selectedMenuIdentifier: PropTypes.string,
	selectedSubMenuIdentifier: PropTypes.string,
};

SubMenu.defaultProps = {
	history: {},
	menu: {},
	Menu: [],
	menuIdentifier: '',
	selectedMenuIdentifier: '',
	selectedSubMenuIdentifier: '',
};

export default SubMenu;
