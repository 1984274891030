import React from 'react';
import { unmountComponentAtNode } from 'react-dom';
import { render } from '../../../tests/test-utils';
import SiteDashboardHeader from './index';
import images from '../../../components/Icons';
import { getTimeRangeDetails } from '../../../helpers/utility';

let container = null;
describe('SiteDashboardHeader', () => {
	beforeEach(() => {
		// setup a DOM element as a render target
		container = document.createElement('div');
		document.body.appendChild(container);
	});

	afterEach(() => {
		// cleanup on exiting
		unmountComponentAtNode(container);
		container.remove();
		container = null;
	});

	const endDateProps = '2022-11-11';
	const startDateProps = '2022-11-01';
	const timeRange = 7;
	const ranges = [];
	const { endDate, startDate } = getTimeRangeDetails(7, true, endDateProps, startDateProps);

	it('should load SiteDashboardHeader', () => {
		const { asFragment, getByTestId, queryByTestId } = render(
			<SiteDashboardHeader timeRange={timeRange} ranges={ranges} endDateProps={endDateProps} startDateProps={startDateProps} />,
			container
		);
		expect(asFragment()).toMatchSnapshot();
		expect(getByTestId('site-dashboard-header-title').textContent).toEqual('');
		expect(queryByTestId('site-dashboard-header-duration').textContent).toEqual(`${startDate} - ${endDate}`);
		expect(queryByTestId('site-dashboard-header-svg')).toBeNull();
	});

	it('should load SiteDashboardHeader with title', () => {
		const title = 'Title';
		const { asFragment, getByTestId, queryByTestId } = render(
			<SiteDashboardHeader title={title} timeRange={timeRange} ranges={ranges} endDateProps={endDateProps} startDateProps={startDateProps} />,
			container
		);
		expect(asFragment()).toMatchSnapshot();
		expect(getByTestId('site-dashboard-header-title').textContent).toEqual(title);
		expect(queryByTestId('site-dashboard-header-duration').textContent).toEqual(`${startDate} - ${endDate}`);
		expect(queryByTestId('site-dashboard-header-svg')).toBeNull();
	});

	it('should load SiteDashboardHeader with icon', () => {
		const { asFragment, queryByTestId, getByTestId } = render(
			<SiteDashboardHeader icon={images.camera} timeRange={timeRange} ranges={ranges} endDateProps={endDateProps} startDateProps={startDateProps} />,
			container
		);
		expect(asFragment()).toMatchSnapshot();
		expect(getByTestId('site-dashboard-header-title').textContent).toEqual('');
		expect(queryByTestId('site-dashboard-header-duration').textContent).toEqual(`${startDate} - ${endDate}`);
		expect(getByTestId('site-dashboard-header-svg')).toBeTruthy();
	});

	it('should load SiteDashboardHeader with isCentrePage', () => {
		const { asFragment, queryByTestId, getByTestId } = render(
			<SiteDashboardHeader isCentrePage={true} timeRange={timeRange} ranges={ranges} endDateProps={endDateProps} startDateProps={startDateProps} />,
			container
		);

		expect(asFragment()).toMatchSnapshot();
		expect(getByTestId('site-dashboard-header-title').textContent).toEqual('');
		expect(queryByTestId('site-dashboard-header-duration')).toBeNull();
		expect(queryByTestId('site-dashboard-header-svg')).toBeNull();
		expect(queryByTestId('site-dashboard-header-duration-text')).toBeNull();
	});

	it('should load SiteDashboardHeader with fullScreenAction', () => {
		const fullScreenAction = jest.fn();
		const { asFragment, getByTestId, queryByTestId } = render(
			<SiteDashboardHeader
				fullScreenAction={fullScreenAction}
				timeRange={timeRange}
				ranges={ranges}
				endDateProps={endDateProps}
				startDateProps={startDateProps}
			/>,
			container
		);
		expect(asFragment()).toMatchSnapshot();
		expect(getByTestId('site-dashboard-header-title').textContent).toEqual('');
		expect(queryByTestId('site-dashboard-header-duration').textContent).toEqual(`${startDate} - ${endDate}`);
		expect(queryByTestId('site-dashboard-header-svg')).toBeNull();
	});
});
