import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
	technologyAdoptionSection: {
		margin: '0px 20px 0px 20px',
	},
	technologyAdoptionText: {
		fontSize: '22px',
		fontWeight: 'bold',
	},
	technologyAdoption: {
		width: '100%',
		[theme.breakpoints.up('md')]: {
			margin: '100px 0px 0px 0px',
		},
		[theme.breakpoints.down('md')]: {
			margin: '50px',
		},
		[theme.breakpoints.down('sm')]: {
			margin: '0px',
		},
	},
	statTitle: {
		fontSize: '16px',
		fontWeight: '600',
		textTransform: 'uppercase',
		marginBottom: '20px',
	},
}));
