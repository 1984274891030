import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ChevronRight';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames/bind';
import { toggleSubMenuExpansionPanel } from '../../../redux/actions/appActions';
import usePanelStyles, { rootStyle } from './panelStyles';
import ExpansionPanel from '../ExpansionPanel';
import ExpansionPanelSummary from '../ExpansionPanelSummary';
import styles from './styles.scss';

const cx = classNames.bind(styles);

const CustomExpansionPanelDetails = withStyles(rootStyle)(AccordionDetails);

const SingleSubMenuItemSummary = (props) => {
	const classes = usePanelStyles(props);
	const { name, order, currentMenuIdentifier, menuIdentifier } = props;
	return (
		<div
			className={cx('secondaryExpansionPanelSummaryItem', {
				secondaryExpansionPanelDetailsItemFirst: order === 0,
			})}
		>
			<span className={cx('secondaryExpansionPanelSummaryItemText')}>{name}</span>
			<ExpandMoreIcon
				className={classNames(classes.expandIcon, {
					[classes.deg90]: currentMenuIdentifier && currentMenuIdentifier === menuIdentifier,
				})}
			/>
		</div>
	);
};

class SecondaryExpansionPanel extends React.Component {
	constructor() {
		super();
		this.state = {
			state_currentMenuIdentifier: '',
			state_selected_parentMenuIdentifier: '',
		};
	}

	componentDidUpdate(prevProps, prevState) {
		const { selectedMenuIdentifier, setStateParentMenuIdentifier, menu } = this.props;
		const { state_selected_parentMenuIdentifier } = this.state;
		if (selectedMenuIdentifier !== prevProps.selectedMenuIdentifier) {
			if (typeof setStateParentMenuIdentifier === 'function') {
				menu.forEach((item) => {
					if (item.get('_identifier') === selectedMenuIdentifier) {
						setStateParentMenuIdentifier(item.get('_parent_identifier'));
					} else {
						setStateParentMenuIdentifier('');
					}
				});
			}
		}

		if (state_selected_parentMenuIdentifier !== prevState.state_selected_parentMenuIdentifier) {
			if (typeof setStateParentMenuIdentifier === 'function') {
				menu.some((item) => {
					if (item.get('_identifier') === state_selected_parentMenuIdentifier) {
						setStateParentMenuIdentifier(item.get('_parent_identifier'));
						return true;
					}
					return false;
				});
			}
		}
	}

	setStateParentMenuIdentifier = (identifier) => {
		this.setState({
			state_selected_parentMenuIdentifier: identifier,
		});
	};

	handleChange = (menuIdentifier, submenuIdentifier) => {
		const { state_currentMenuIdentifier } = this.state;
		if (state_currentMenuIdentifier === submenuIdentifier) {
			this.setState({
				state_currentMenuIdentifier: '',
			});
		} else {
			this.setState({
				state_currentMenuIdentifier: submenuIdentifier,
			});
		}
	};

	handleClick = (link) => {
		const { history } = this.props;
		history.push(link);
	};

	render() {
		const { order, isMainMenuCollapsed, parentMenuIdentifier, menuIdentifier, selectedMenuIdentifier, Menu, menu, name, history, isSelected } = this.props;

		const { state_currentMenuIdentifier, state_selected_parentMenuIdentifier } = this.state;

		return (
			<ExpansionPanel
				square
				expanded={!isMainMenuCollapsed && state_currentMenuIdentifier !== '' && state_currentMenuIdentifier === menuIdentifier}
				onChange={isMainMenuCollapsed ? null : () => this.handleChange(parentMenuIdentifier, menuIdentifier)}
			>
				<ExpansionPanelSummary
					isExpanded={state_currentMenuIdentifier && state_currentMenuIdentifier === menuIdentifier}
					isSelected={selectedMenuIdentifier && isSelected}
					expandIcon={null}
				>
					<div className={cx('secondaryExpansionPanelSummaryItemsWrapper')}>
						<SingleSubMenuItemSummary
							order={order}
							menuIdentifier={menuIdentifier}
							currentMenuIdentifier={state_currentMenuIdentifier}
							name={name}
						/>
					</div>
				</ExpansionPanelSummary>
				<CustomExpansionPanelDetails>
					{!isMainMenuCollapsed && menu !== undefined && menu.size > 0 && (
						<div className={cx('secondaryExpansionPanelDetailsItemsWrapper')} data-testid='secondary-expansion-panel-details'>
							{menu.map((menu_item, index) => {
								let link = '/centre_dashboard';
								Menu.some((Menu_item) => {
									if (Menu_item.identifier === menu_item.get('_identifier')) {
										link = Menu_item.link;
										return true;
									}
									return false;
								});
								return (
									<div
										className={cx('secondaryExpansionPanelDetailsItem', {
											secondaryUnSelected:
												selectedMenuIdentifier === undefined ||
												(menu_item.get('children') === undefined && selectedMenuIdentifier !== menu_item.get('_identifier')) ||
												(menu_item.get('children') &&
													menu_item.get('children').size &&
													state_selected_parentMenuIdentifier !== menu_item.get('_identifier')),
											secondaryIsSelected:
												selectedMenuIdentifier &&
												((menu_item.get('children') === undefined && selectedMenuIdentifier === menu_item.get('_identifier')) ||
													(menu_item.get('children') &&
														menu_item.get('children').size &&
														state_selected_parentMenuIdentifier === menu_item.get('_identifier'))),
										})}
										key={index}
										onClick={menu_item.get('children') ? null : () => this.handleClick(link)}
										data-identifier={`secondary-expansion-panel-details-menu-identifier-${menu_item.get('_identifier')}`}
									>
										{menu_item.get('children') && menu_item.get('children').size ? (
											<SecondaryExpansionPanel
												key={index}
												history={history}
												isMainMenuCollapsed={isMainMenuCollapsed}
												name={menu_item.get('resource_name')}
												menu={menu_item.get('children')}
												Menu={Menu}
												isSelected={state_selected_parentMenuIdentifier === menu_item.get('_identifier')}
												setStateParentMenuIdentifier={this.setStateParentMenuIdentifier}
												parentMenuIdentifier={menu_item.get('_parent_identifier')}
												selectedParentMenuIdentifier={state_selected_parentMenuIdentifier}
												menuIdentifier={menu_item.get('_identifier')}
												selectedMenuIdentifier={selectedMenuIdentifier}
											/>
										) : (
											<span className={cx('secondaryExpansionPanelDetailsItemText')}>{menu_item.get('resource_name')}</span>
										)}
									</div>
								);
							})}
						</div>
					)}
				</CustomExpansionPanelDetails>
			</ExpansionPanel>
		);
	}
}

SecondaryExpansionPanel.propTypes = {
	history: PropTypes.object,
	disabled: PropTypes.bool,
	name: PropTypes.string,
	path: PropTypes.string,
	lockedPath: PropTypes.string,
	menu: PropTypes.object,
	Menu: PropTypes.array,
	link: PropTypes.string,
	menuIdentifier: PropTypes.string,
	currentMenuIdentifier: PropTypes.string,
	selectedMenuIdentifier: PropTypes.string,
	selectedSubMenuIdentifier: PropTypes.string,
	selectedThirdSubMenuIdentifier: PropTypes.string,
	isMainMenuCollapsed: PropTypes.bool,
	icon: PropTypes.string,
	order: PropTypes.number,
	isSelected: PropTypes.bool,
};

SecondaryExpansionPanel.defaultProps = {
	history: {},
	disabled: false,
	name: '',
	path: '',
	lockedPath: '',
	menu: {},
	Menu: [],
	link: '',
	menuIdentifier: '',
	currentMenuIdentifier: '',
	selectedMenuIdentifier: '',
	selectedSubMenuIdentifier: '',
	selectedThirdSubMenuIdentifier: '',
	isMainMenuCollapsed: false,
	icon: '',
	order: null,
	isSelected: false,
};

const mapDispatchToProps = (dispatch) => ({
	toggleSubMenuExpansionPanel: (bools, menuIdentifier, submenuIdentifier) => dispatch(toggleSubMenuExpansionPanel(bools, menuIdentifier, submenuIdentifier)),
});

export default connect(null, mapDispatchToProps)(SecondaryExpansionPanel);
