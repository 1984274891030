import React from 'react';
import PropTypes from 'prop-types';
import PasswordChecklist from 'react-password-checklist';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import PasswordTextField from '../PasswordTextField';
import style from './styles';

const PasswordTooltip = withStyles((theme) => ({
	tooltip: {
		backgroundColor: theme.palette.common.white,
		color: 'rgba(0, 0, 0, 0.87)',
		boxShadow: theme.shadows[1],
		maxWidth: 400,
		fontSize: theme.typography.pxToRem(10),
		border: '1px solid #dadde9',
		margin: '0',
		padding: '10px',
	},
}))(Tooltip);

class PasswordValidationInput extends React.Component {
	state = {
		passwordRule: 'specialChar',
	};

	componentDidUpdate(previousProps) {
		const { password } = this.props;
		if (previousProps.password !== password) {
			const doesItHaveNumber = /\d/.test(password);
			//eslint-disable-next-line
			const doesItHaveSpecialChars = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password);

			if (doesItHaveNumber || doesItHaveSpecialChars) {
				this.setState({
					passwordRule: doesItHaveNumber ? 'number' : 'specialChar',
				});
			}
		}
	}
	render() {
		const {
			classes,
			password,
			passwordToMatch,
			handleChangePasswordAction,
			isContactUserPassword = true,
			isContactPage = false,
			isRightFloat = false,
			id,
		} = this.props;
		const { passwordRule } = this.state;

		const passwordRules = passwordToMatch ? ['match'] : ['minLength', `${passwordRule}`, 'capital', 'lowercase'];

		return (
			<PasswordTooltip
				title={
					<PasswordChecklist
						rules={passwordRules}
						minLength={8}
						value={password}
						valueAgain={passwordToMatch ? passwordToMatch : null}
						iconSize={10}
						messages={{
							specialChar: 'Password has a number OR a special character.',
							number: 'Password has a number OR a special character.',
						}}
					/>
				}
				placement='top-start'
				data-testid='password-validation-input-tooltip'
			>
				<FormControl className={isRightFloat ? classes.rightFloatTextFieldWrapper : classes.textFieldWrapper}>
					<PasswordTextField
						value={isContactPage ? password : null}
						password={isContactPage ? null : password}
						isContactUserPassword={isContactUserPassword}
						handleChangePasswordAction={handleChangePasswordAction}
						id={id}
						data-testid='password-validation-input-text-field'
					/>
				</FormControl>
			</PasswordTooltip>
		);
	}
}

PasswordValidationInput.propTypes = {
	password: PropTypes.string,
	passwordToMatch: PropTypes.string,
	handleChangePasswordAction: PropTypes.func,
	isContactUserPassword: PropTypes.bool,
	isContactPage: PropTypes.bool,
	isRightFloat: PropTypes.bool,
	id: PropTypes.string,
};

PasswordValidationInput.defaultProps = {
	password: '',
	passwordToMatch: '',
	handleChangePasswordAction: null,
	isContactUserPassword: true,
	isContactPage: false,
	isRightFloat: false,
	id: '',
};

export default withStyles(style)(PasswordValidationInput);
