import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import InnerPieChart from '../../../../Charts/InnerPieChart';

import useStyles from './styles';

const DiscretionarySpend = (props) => {
	const { discretionarySpend, colourTheme } = props;

	const classes = useStyles(props);

	return (
		<div className={classes.section}>
			<Grid item xs={12}>
				<h3 className={classes.statTitle}>{discretionarySpend.headingText}</h3>
			</Grid>
			<Grid item container xs={12}>
				<div className={classes.chart}>
					<InnerPieChart
						id='discretionary_spend'
						data={discretionarySpend.data}
						categoryX='label'
						valueY='value'
						colors={colourTheme.discretionarySpend}
						innerRadius={10}
					/>
				</div>
			</Grid>
		</div>
	);
};

DiscretionarySpend.propTypes = {
	discretionarySpend: PropTypes.object,
	colourTheme: PropTypes.object,
};

DiscretionarySpend.defaultProps = {
	discretionarySpend: null,
	colourTheme: null,
};

export default DiscretionarySpend;
