import React from 'react';
import Population from '../../components/CensusDashboard/Population';
import AgeGroupsChart from '../../components/CensusDashboard/AgeGroupsChart';

import useStyles from './styles';
import WorkOccupationChart from '../../components/CensusDashboard/WorkOccupationChart';
import EthnicityAndLanguagesChart from '../../components/CensusDashboard/EthnicityAndLanguagesChart';
import MaritalStatusAndFamilyTypes from '../../components/CensusDashboard/MaritalStatusAndFamilyTypes';
import PropertyOwnershipAndMortgage from '../../components/CensusDashboard/PropertyOwnershipAndMortgage';

const CensusDashboardTemplate = (props) => {
	const classes = useStyles(props);

	return (
		<div className={classes.censusPageMiddleContent}>
			<div className={classes.rowContainer}>
				<div className={classes.leftContainer}>
					<Population {...props} />
				</div>
				<div className={classes.rightContainer}>
					<AgeGroupsChart {...props} />
				</div>
			</div>
			<div className={classes.rowContainer}>
				<div className={classes.leftContainer}>
					<WorkOccupationChart {...props} />
				</div>
				<div className={classes.rightContainer}>
					<EthnicityAndLanguagesChart {...props} />
				</div>
			</div>
			<div className={classes.rowContainer}>
				<div className={classes.leftContainer}>
					<MaritalStatusAndFamilyTypes {...props} />
				</div>
				<div className={classes.rightContainer}>
					<PropertyOwnershipAndMortgage {...props} />
				</div>
			</div>
		</div>
	);
};

export default CensusDashboardTemplate;
