import React from 'react';
import HelpPageContainer from '../../containers/help/helpPageContainer';

class HelpPage extends React.Component {
	render() {
		return <HelpPageContainer {...this.props} />;
	}
}

export default HelpPage;
