import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Grid } from '@material-ui/core';
import SocioEconomic from '../../../SocioEconomic';

import useStyles from './styles';

const SocioEconomics = (props) => {
	const classes = useStyles(props);

	const { socioEconomic, colourTheme } = props;

	return (
		<div className={classNames(classes.socioEconomicContainer)}>
			<Grid item xs={12}>
				<h3 className={classNames(classes.statTitle, classes.socioEconomicTitle)}>{socioEconomic.headingText}</h3>
			</Grid>
			<Grid container item xs={12} className={classes.socioEconomicChart}>
				<SocioEconomic data={socioEconomic.data} colourTheme={colourTheme} />
			</Grid>
		</div>
	);
};

SocioEconomics.propTypes = {
	likes: PropTypes.object,
	socioEconomic: PropTypes.object,
};

SocioEconomics.defaultProps = {
	likes: null,
	socioEconomic: null,
};

export default SocioEconomics;
