import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import classNames from 'classnames/bind';
import Demographics from '../Demographics';

import useStyles from './styles';

const TopHeader = (props) => {
	const { data, colourTheme, selectedSiteName } = props;

	const classes = useStyles(props);

	return (
		<>
			<Grid item xs={12}>
				<div className={classNames(classes.header)}>
					<h1 className={classes.headerText}>{data.headingText}</h1>
				</div>
			</Grid>
			<Grid item container xs={12}>
				<Grid item xs={12} md={6}>
					<div className={classes.dominantSection}>
						<h2 className={classes.dominantText}>
							{data.secondaryText} {selectedSiteName}
						</h2>
						<h2 className={classNames(classes.dominantText, classes.textSpace)}>{data.cultureHeadingText}</h2>
					</div>
				</Grid>
				<Grid item container direction='row' xs={12} md={6}>
					<Demographics demographics={data.demographics} colourTheme={colourTheme} />
				</Grid>
			</Grid>
		</>
	);
};

TopHeader.propTypes = {
	data: PropTypes.shape({
		headingText: PropTypes.string,
		secondaryText: PropTypes.string,
		cultureHeadingText: PropTypes.string,
		demographics: PropTypes.object,
	}),
	colourTheme: PropTypes.object,
	selectedSiteName: PropTypes.string,
};

TopHeader.defaultProps = {
	data: {
		headingText: '',
		secondaryText: '',
		cultureHeadingText: '',
		demographics: null,
	},
	colourTheme: null,
	selectedSiteName: '',
};

export default TopHeader;
