import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import HelpPage from '../../../pages/help';
import UserPreferencePage from '../../../pages/userPreference';
import CentreDashboardPage from '../../../pages/centreDashboard';
import PrivateRoute from '../PrivateRoute';
import CensusDataPage from '../../../pages/censusData';
import Community from '../../../pages/royMorgan/community';
import Persona from '../../../pages/royMorgan/persona';

class AppRouter extends React.PureComponent {
	render() {
		return (
			<Switch>
				<Route exact path='/' render={() => <Redirect to={{ pathname: '/centre_dashboard' }} />} />
				<PrivateRoute hasPermission path='/user_preferences' component={UserPreferencePage} />
				<PrivateRoute hasPermission path='/centre_dashboard' component={CentreDashboardPage} />
				<PrivateRoute hasPermission path='/census_data' component={CensusDataPage} />
				<PrivateRoute hasPermission path='/roymorgan/personas' component={Persona} />
				<PrivateRoute hasPermission path='/roymorgan/communities' component={Community} />
				<PrivateRoute hasPermission path='/help' component={HelpPage} />
				<Route path='*' render={() => <Redirect to={{ pathname: '/centre_dashboard' }} />} />
			</Switch>
		);
	}
}

export default AppRouter;
