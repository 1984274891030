import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import ReactSVG from 'react-svg';

import useStyles from './styles';

const CategorizedListComponent = (props) => {
	const { list, hideHeader } = props;

	const classes = useStyles(props);

	return (
		<div className={classes.section}>
			{!hideHeader && (
				<Grid item xs={12}>
					<h3 className={classes.statTitle}>{list.headingText}</h3>
				</Grid>
			)}
			<Grid item container xs={12}>
				{list.data.map((listItem, index) => {
					return (
						<Grid item xs={12} key={`categorized-list-item-${index}`}>
							<div className={classes.row}>
								<div className={classes.icon}>
									<ReactSVG src={require(`../../Icons/personas/${listItem.filename}`)} className={classes.customIcon} />
								</div>
								<div>
									<b>{listItem.shortText}</b>
									<br />
									<span dangerouslySetInnerHTML={{ __html: listItem.longText }} />
								</div>
							</div>
						</Grid>
					);
				})}
			</Grid>
		</div>
	);
};

CategorizedListComponent.propTypes = {
	list: PropTypes.object,
	hideHeader: PropTypes.bool,
	backgroundColor: PropTypes.string,
	fontColor: PropTypes.string,
	iconColor: PropTypes.string,
};

CategorizedListComponent.defaultProps = {
	list: null,
	hideHeader: false,
	backgroundColor: null,
	fontColor: null,
	iconColor: null,
};

export default CategorizedListComponent;
