import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames/bind';
import TextFieldHeader from '../../components/Headers/TextFieldHeader';
import { overviewStyle } from '../../constants/Theme';
import styles from './styles';

const style = { ...styles, ...overviewStyle };

const SingleUserOverviewTemplate = (props) => {
	const { classes, fullName, firstName, lastName, email, preferredName, toggleResetPasswordModal } = props;

	return (
		<div data-testid='single-user-overview-template'>
			<TextFieldHeader title='General' />
			<div>
				<div className={classes.textfieldHeight}>
					<div className={classes.wrapper} data-testid='single-user-overview-template-full-name'>
						<div className={classNames(classes.title, classes.titleWidth)}>
							<h4 className={classes.text}>Username</h4>
						</div>
						<h4>{fullName}</h4>
					</div>
					<div className={classes.wrapper} data-testid='single-user-overview-template-preferred-name'>
						<div className={classNames(classes.title, classes.titleWidth)}>
							<h4 className={classes.text}>Ayuda User</h4>
						</div>
						<h4>{preferredName || '-'}</h4>
					</div>
				</div>
				<div className={classes.textfieldHeight}>
					<div className={classes.wrapper} data-testid='single-user-overview-template-first-name'>
						<div className={classNames(classes.title, classes.titleWidth)}>
							<h4 className={classes.text}>First Name</h4>
						</div>
						<h4>{firstName}</h4>
					</div>
					<div className={classes.wrapper} data-testid='single-user-overview-template-last-name'>
						<div className={classNames(classes.title, classes.titleWidth)}>
							<h4 className={classes.text}>Last Name</h4>
						</div>
						<h4>{lastName}</h4>
					</div>
				</div>
				<div className={classes.textfieldHeight}>
					<div className={classes.wrapper} data-testid='single-user-overview-template-email-name'>
						<div className={classNames(classes.title, classes.titleWidth)}>
							<h4 className={classes.text}>Email</h4>
						</div>
						<h4>{email}</h4>
					</div>
					<div className={classes.wrapperRight}>
						<div className={classNames(classes.title, classes.titleWidth)} />
						<Button
							className={classes.resetButton}
							variant='outlined'
							onClick={toggleResetPasswordModal}
							data-testid='single-user-overview-template-button'
						>
							Reset Password
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

SingleUserOverviewTemplate.propTypes = {
	fullName: PropTypes.string,
	firstName: PropTypes.string,
	lastName: PropTypes.string,
	email: PropTypes.string,
	password: PropTypes.string,
	preferredName: PropTypes.string,
	toggleResetPasswordModal: PropTypes.func,
};

SingleUserOverviewTemplate.defaultProps = {
	fullName: '',
	firstName: '',
	lastName: '',
	email: '',
	password: '',
	preferredName: '',
	toggleResetPasswordModal: null,
};

export default withStyles(style)(SingleUserOverviewTemplate);
