import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
	section: {
		padding: '0px 20px 20px 20px',
		backgroundColor: '#ffffff',
		color: '#000000 !important',
	},
	statTitle: {
		fontSize: '16px',
		fontWeight: '600',
		textTransform: 'uppercase',
		color: '#000000',
		marginBottom: '20px',
	},
	customIcon: {
		fill: (props) => props.colourTheme.icon.fill || '#31adce',
		'& div': {
			'& svg': {
				width: 50,
				height: 50,
			},
		},
		stroke: (props) => props.colourTheme.icon.stroke || '#31adce',
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'left',
		textAlign: 'left',
		marginTop: '20px',
	},
	icon: {
		padding: '0px 10px 0px 0px',
		justifyContent: 'center',
		textAlign: 'center',
	},
}));
