import React from 'react';
import { connect } from 'react-redux';
import { censusRadius, getAvailableSites } from '../../../helpers/utility';
import { fetchContactSiteList, selectRadius, selectSite } from '../../../redux/actions/contactsActions';
import { fetchCommunities } from '../../../redux/actions/communitiesAction';
import CommunityPageTemplate from '../../../templates/royMorgan/community/index.template';

class CommunityPageContainer extends React.Component {
	constructor(props) {
		super(props);
		this.wrapperRef = React.createRef();
		this.state = {
			selectedSiteId: '',
			selectedSiteName: '',
			selectedSite: {},
			sites: [],
			selectedRadius: censusRadius[0],
		};
	}

	componentDidMount() {
		const { userInfo, fetchContactSiteList, contactSites, selectedSite, selectedRadius } = this.props;

		if (userInfo.size !== 0 && userInfo.get('contact_id') !== undefined && userInfo.get('contact_id') !== null) {
			fetchContactSiteList(userInfo.get('contact_id'));
		}
		if (contactSites.size > 0) {
			const sites = getAvailableSites(contactSites, 'id', true);
			const selectedContactSite = selectedSite || sites[0];
			const selectedRadiusValue = selectedRadius || censusRadius[0];
			sites.length > 0 &&
				this.setState({
					sites,
					selectedSite: selectedContactSite,
					selectedSiteId: selectedContactSite.id,
					selectedSiteName: selectedContactSite.site_name,
					selectedRadius: selectedRadiusValue,
				});
		}
	}

	componentDidUpdate(prevProps) {
		const { contactSites, fetchCommunities, selectedSite, selectedRadius } = this.props;

		if (prevProps.contactSites !== contactSites) {
			if (contactSites.size > 0) {
				const sites = getAvailableSites(contactSites, 'id', true);
				const selectedContactSite = selectedSite || sites[0];
				const selectedRadiusValue = selectedRadius || censusRadius[0];
				if (sites.length) {
					this.setState({
						sites,
						selectedSite: selectedContactSite,
						selectedSiteId: selectedContactSite.id,
						selectedSiteName: selectedContactSite.site_name,
						selectedRadius: selectedRadiusValue,
					});
					fetchCommunities(selectedContactSite.id, selectedRadiusValue.value);
				}
			}
		}
	}

	handleChangeSite = (value, site) => {
		const { fetchCommunities, selectSite } = this.props;
		const { selectedRadius } = this.state;
		if (value) {
			this.setState({ selectedSiteId: value, selectedSite: site, selectedSiteName: site.site_name });
			selectSite(site);
			fetchCommunities(value, selectedRadius.value);
		}
	};

	handleChangeRadius = (value, radius) => {
		const { fetchCommunities, selectRadius } = this.props;
		const { selectedSiteId } = this.state;
		if (value) {
			this.setState({ selectedRadius: radius });
			selectRadius(radius);
			fetchCommunities(selectedSiteId, value);
		}
	};

	render() {
		const { pageLoader, communitiesData } = this.props;
		const { sites, selectedSiteId, selectedSiteName, selectedSite, selectedRadius } = this.state;

		return (
			<CommunityPageTemplate
				pageLoader={pageLoader}
				selectedSiteId={selectedSiteId}
				selectedSiteName={selectedSiteName}
				sites={sites}
				handleChangeSite={this.handleChangeSite}
				siteKey='id'
				selectedSite={selectedSite}
				radius={censusRadius}
				selectedRadius={selectedRadius.value}
				handleChangeRadius={this.handleChangeRadius}
				communitiesData={communitiesData}
			/>
		);
	}
}

const mapStateToProps = (state) => ({
	userInfo: state.authReducer.get('userInfo'),
	contactSites: state.contactsReducer.get('contactSites'),
	selectedSite: state.contactsReducer.get('selectedSite'),
	selectedRadius: state.contactsReducer.get('selectedRadius'),

	pageLoader: state.communitiesReducer.get('communitiesLoader'),
	communitiesErrorMessage: state.communitiesReducer.get('communitiesErrorMessage'),
	communitiesData: state.communitiesReducer.get('communities'),
});

export const mapDispatchToProps = (dispatch) => ({
	fetchContactSiteList: (contactId) => dispatch(fetchContactSiteList(contactId)),
	selectSite: (selectedSite) => dispatch(selectSite(selectedSite)),
	selectRadius: (selectedRadius) => dispatch(selectRadius(selectedRadius)),
	fetchCommunities: (siteId, radius) => dispatch(fetchCommunities(siteId, radius)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CommunityPageContainer);
