import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
	section: {
		padding: '0px 0px 0px 20px',
		backgroundColor: '#ffffff',
		color: '#000000 !important',
		[theme.breakpoints.down('md')]: {
			marginBottom: '50px',
		},
	},
	statTitle: {
		fontSize: '16px',
		fontWeight: '600',
		textTransform: 'uppercase',
		color: '#000000',
		marginBottom: '20px',
	},
	chart: {
		width: '100%',
		height: '250px',
	},
}));
