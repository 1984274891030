import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
	fullscreen: {
		marginLeft: '-250px', // half of width
		left: '50% !important',
		marginTop: '-95px',
		top: '50% !important',
		width: '100%',
		height: '100%',
	},
	modalWrapper: {
		position: 'absolute',
		width: 500,
		backgroundColor: 'white',
		borderRadius: 5,
	},
	modalHead: {
		backgroundColor: '#c33634',
		height: 40,
		width: '100%',
		fontSize: 18,
		lineHeight: '40px',
		color: 'white',
		paddingLeft: 20,
		borderTopLeftRadius: 5,
		borderTopRightRadius: 5,
	},
	modalContent: {
		padding: 20,
	},
	modalFooter: {
		display: 'flex',
		borderTop: '1px solid rgba(0, 0, 0, 0.15)',
		padding: '10px 20px',
	},
	link: {
		marginLeft: 'auto',
	},
}));
