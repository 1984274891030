export default {
	titleWidth: {
		width: 110,
	},
	textFieldWrapper: {
		width: 'calc(100% - 110px)',
		display: 'inline-block',
	},
	resetButton: {
		textTransform: 'none',
		padding: '2px 6px',
		fontSize: '12px',
		minWidth: '113px',
		color: '#c33634',
		borderColor: '#c33634',
		'&:hover': {
			backgroundColor: '#c33634',
			color: 'white',
		},
	},
};
