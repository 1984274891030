import React from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withStyles } from '@material-ui/core/styles';
import { textfieldStyle } from '../../constants/Theme';
import style01 from './style';

const style = { ...style01, ...textfieldStyle };

const CustomSelect = (props) => {
	const [inputValue, setInputValue] = React.useState(props.value);

	const { classes, label, value, menus, menuValueKey, menuNameKey } = props;

	const handleChange = (event, newValue) => {
		const { handleOnChange } = props;
		if (newValue && newValue[menuValueKey]) {
			handleOnChange(newValue[menuValueKey], newValue);
		}
	};

	const handleChangeInput = (event, newInputValue) => {
		setInputValue(newInputValue);
	};

	const debounced = debounce(handleChangeInput, 150, {
		leading: true,
		trailing: false,
	});

	return (
		<FormControl className={classes.formControl_autoComplete} data-testid='custom-select'>
			<Autocomplete
				classes={{
					root: classes.autocompleteRoot,
					popupIndicator: classes.popupIndicator,
					noOptions: classes.noOptions,
					listbox: classes.listbox,
					groupLabel: classes.groupLabel,
				}}
				size='small'
				disableClearable
				options={menus}
				getOptionDisabled={(item) => item.disabled}
				getOptionLabel={(item) => {
					return item[menuNameKey] || '';
				}}
				getOptionSelected={(item) => {
					return item[menuValueKey] === value;
				}}
				value={!value ? {} : menus.filter((item) => item[menuValueKey] === value)[0] || {}}
				onChange={handleChange}
				inputValue={inputValue}
				onInputChange={debounced}
				renderOption={(item) => (
					<React.Fragment>
						<span className={classes.optionLabel} data-testid='custom-select-span'>
							{item[menuNameKey]}
						</span>
					</React.Fragment>
				)}
				renderInput={(params) => (
					<TextField
						{...params}
						id='custom-select-text'
						variant='outlined'
						size='small'
						label={label}
						fullWidth
						className={classes.root}
						inputProps={{
							...params.inputProps,
							className: classes.inputSelect,
							id: 'custom-select-text-input',
						}}
						InputProps={{
							...params.InputProps,
							classes: {
								root: classes.cssOutlinedInput,
								focused: classes.cssFocused,
								notchedOutline: classes.notchedOutline,
							},
						}}
						InputLabelProps={{
							...params.InputLabelProps,
							classes: {
								root: classes.inputLabel,
								marginDense: classes.labelDense,
								shrink: classes.labelShrink,
							},
							htmlFor: 'custom-select-text-input',
							id: 'custom-select-text-label',
						}}
						data-testid='custom-select-text-field'
					/>
				)}
			/>
		</FormControl>
	);
};

CustomSelect.propTypes = {
	label: PropTypes.string,
	value: PropTypes.string,
	menus: PropTypes.array,
	menuValueKey: PropTypes.string,
	menuNameKey: PropTypes.string,
	handleOnChange: PropTypes.func,
};

CustomSelect.defaultProps = {
	label: '',
	value: null,
	menus: [],
	menuValueKey: '',
	menuNameKey: '',
	handleOnChange: null,
};

export default withStyles(style)(CustomSelect);
