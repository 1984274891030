import { takeEvery, call, fork, all, put } from 'redux-saga/effects';
import * as ACTION_TYPES from '../../constants/ActionTypes';
import { getPersonasData } from '../apis/personasAPIs';
import { fetchPersonasDone, fetchPersonasFailed } from '../actions/personasAction';

function* watchFetchPersonasDone() {
	yield takeEvery(ACTION_TYPES.FETCH_PERSONAS, function* (action) {
		try {
			const result = yield call(getPersonasData, action.siteId, action.radius);
			yield put(fetchPersonasDone(result));
		} catch (error) {
			yield put(fetchPersonasFailed(error));
		}
	});
}

export default function* rootSaga() {
	yield all([fork(watchFetchPersonasDone)]);
}
