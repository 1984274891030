import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CustomLoader from '../../../components/CustomLoader';
import WalkWithMeSection from '../../../components/RoyMorganDashboard/Personas/WalkWithMeSection';
import PersonasHeader from '../../../components/RoyMorganDashboard/Personas/Header';
import FilterComponent from '../../../components/RoyMorganDashboard/Personas/FilterComponent';

import useStyles from './styles';
import Description from '../../../components/RoyMorganDashboard/Personas/KnowMeSection/Description';
import SocioEconomics from '../../../components/RoyMorganDashboard/Personas/KnowMeSection/SocioEconomics';
import classNames from 'classnames';
import Likes from '../../../components/RoyMorganDashboard/Personas/KnowMeSection/Likes';
import TopHeader from '../../../components/RoyMorganDashboard/Personas/KnowMeSection/TopHeader';

const CommunityPageTemplate = (props) => {
	const { pageLoader, communitiesData, selectedSiteId, sites, handleChangeSite, siteKey, selectedSiteName, radius, selectedRadius, handleChangeRadius } =
		props;

	const classes = useStyles(props);

	if (pageLoader) {
		return (
			<div className={classes.pageLoader}>
				<CustomLoader site={64} />
			</div>
		);
	} else {
		return (
			<div className={classes.censusDataPageWrapper}>
				<div className={classes.censusDataPageLayout}>
					<Grid container>
						<Grid item container direction='row' xs={12}>
							<FilterComponent
								selectedSiteId={selectedSiteId}
								sites={sites}
								label='Centre'
								handleChangeSite={handleChangeSite}
								siteKey={siteKey}
								radius={radius}
								selectedRadius={selectedRadius}
								handleChangeRadius={handleChangeRadius}
							/>
						</Grid>
						{communitiesData['header'] === undefined ||
						communitiesData['knowMeSectionData'] === undefined ||
						communitiesData['walkWithMeSectionData'] === undefined ||
						communitiesData['footer'] === undefined ||
						communitiesData['colourTheme'] === undefined ? (
							<div className={classes.noDataSection}>
								<div className={classes.noDataIcon}>
									<ErrorOutlineIcon fontSize='large' />
								</div>
								<div>
									<h2>No data found</h2>
								</div>
							</div>
						) : (
							<>
								<Grid item container direction='row' xs={12}>
									<PersonasHeader data={communitiesData.header} colourTheme={communitiesData.colourTheme} />
								</Grid>
								<Grid item container direction='row' xs={12}>
									<TopHeader
										data={communitiesData.knowMeSectionData}
										selectedSiteName={selectedSiteName}
										colourTheme={communitiesData.colourTheme}
									/>
								</Grid>
								<Grid item container direction='row' xs={12}>
									<Grid item xs={12} md={6} className={classes.colored}>
										<Description description={communitiesData.knowMeSectionData.description} />
									</Grid>
									<Grid item xs={12} md={6} className={classes.blackColored}>
										<SocioEconomics
											socioEconomic={communitiesData.knowMeSectionData.socioEconomic}
											colourTheme={communitiesData.colourTheme}
										/>
									</Grid>
								</Grid>
								<Grid item container direction='row' xs={12}>
									<div className={classes.row}>
										<div className={classNames(classes.left)}>
											<div className={classes.imageContainer}>
												<img
													src={`${process.env.REACT_APP_MEDIA_FILES_S3_BUCKET_URL}/personas/${communitiesData['header'].images[3].filename}`}
													className={classes.imageStyle}
													alt={communitiesData['header'].images[3].filename}
												/>
											</div>
										</div>
										<div className={classNames(classes.right)}>
											<Likes likes={communitiesData.knowMeSectionData.likes} colourTheme={communitiesData.colourTheme} />
										</div>
									</div>
								</Grid>
								<Grid item container direction='row' xs={12}>
									<WalkWithMeSection data={communitiesData.walkWithMeSectionData} colourTheme={communitiesData.colourTheme} />
								</Grid>
							</>
						)}
					</Grid>
				</div>
			</div>
		);
	}
};

CommunityPageTemplate.propTypes = {
	pageLoader: PropTypes.bool,
	sites: PropTypes.array,
	selectedSiteId: PropTypes.string,
	handleChangeSite: PropTypes.func,
	siteKey: PropTypes.string,
	selectedSite: PropTypes.object,
	radius: PropTypes.array,
	selectedRadius: PropTypes.string,
	handleChangeRadius: PropTypes.func,
};

CommunityPageTemplate.defaultProps = {
	pageLoader: false,
	sites: [],
	selectedSiteId: '',
	handleChangeSite: null,
	siteKey: 'id',
	selectedSite: {},
	radius: [],
	selectedRadius: null,
	handleChangeRadius: null,
};

export default CommunityPageTemplate;
