import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SiteDashboardHeader from '../../Headers/SiteDashboardHeader';
import HyveCard from '../../CustomCard';
import ColumnBarChart from '../../Charts/ColumnBarChart';
import CustomLoader from '../../CustomLoader';
import ErrorMessageSpan from '../../TextFields/ErrorMessageSpan';
import { Colors } from '../../../constants/Constants.json';
import { siteDashboardChartWrapper } from '../../../constants/Theme';
import images from '../../../components/Icons';

class VisitationsByDay extends React.Component {
	render() {
		const {
			isBullet,
			RVBDayLoader,
			startDate,
			endDate,
			ranges,
			timeRange,
			errorMessageRVBDay,
			currentSiteReportVisitsByDay,
			title = 'Visitations By Day',
			color = Colors[6],
			colorHeader = Colors[6],
			isCentrePage = false,
		} = this.props;
		return (
			<HyveCard isCentrePage={isCentrePage}>
				<SiteDashboardHeader
					title={title}
					timeRange={timeRange}
					startDateProps={startDate}
					endDateProps={endDate}
					ranges={ranges}
					icon={isCentrePage ? images.calendarDay : images.date}
					color={isCentrePage ? '#716E6A' : colorHeader}
					textColor={isCentrePage ? '#000' : null}
					fontSize={isCentrePage ? '14px' : null}
					isCentrePage={isCentrePage}
					border={isCentrePage ? 'none' : null}
				/>
				<div style={{ width: '100%', height: siteDashboardChartWrapper }}>
					{RVBDayLoader ? (
						<CustomLoader size={40} />
					) : errorMessageRVBDay ? (
						<ErrorMessageSpan text={errorMessageRVBDay} />
					) : (
						<ColumnBarChart
							data={currentSiteReportVisitsByDay.toJS()}
							valueY='unique_visits'
							category='report_day'
							rotation={isCentrePage ? null : 15}
							id='byDay'
							isBullet={isBullet}
							color={color}
							paddingRight={isCentrePage ? 20 : 30}
							paddingTop={isCentrePage ? 20 : 10}
							paddingBottom={0}
							isCentrePage={isCentrePage}
						/>
					)}
				</div>
			</HyveCard>
		);
	}
}

VisitationsByDay.propTypes = {
	isBullet: PropTypes.bool,
	startDate: PropTypes.string,
	endDate: PropTypes.string,
	ranges: PropTypes.array,
	timeRange: PropTypes.number,
	title: PropTypes.string,
	color: PropTypes.string,
	colorHeader: PropTypes.string,
	isCentrePage: PropTypes.bool,
};

VisitationsByDay.defaultProps = {
	isBullet: false,
	startDate: '',
	endDate: '',
	ranges: [],
	timeRange: null,
	title: 'Visitations By Day',
	color: Colors[6],
	colorHeader: Colors[6],
	isCentrePage: false,
};

const mapStateToProps = (state) => {
	return {
		currentSiteReportVisitsByDay: state.sitesReducer.get('currentSiteReportVisitsByDay'),
		RVBDayLoader: state.sitesReducer.get('RVBDayLoader'),
		errorMessageRVBDay: state.sitesReducer.get('errorMessageRVBDay'),
	};
};

export default connect(mapStateToProps)(VisitationsByDay);
