import { Map } from 'immutable';
import * as ACTION_TYPES from '../../constants/ActionTypes';

export const initState = new Map({
	personasLoader: false,
	personasErrorMessage: '',
	personas: {},
});

const personasReducer = (state = initState, action) => {
	switch (action.type) {
		case ACTION_TYPES.FETCH_PERSONAS:
			return state.set('personasLoader', true).set('personasErrorMessage', '');

		case ACTION_TYPES.FETCH_PERSONAS_DONE:
			return state.set('personas', action.data.data).set('personasLoader', false);

		case ACTION_TYPES.FETCH_PERSONAS_FAILED:
			return state.set('personasLoader', false).set('personas', {}).set('personasErrorMessage', action.error.message);

		default:
			return state;
	}
};

export default personasReducer;
