import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
	column: {
		margin: '2px',
	},
	img: {
		width: '100%',
		height: 'auto',
		maxHeight: '400px',
		objectFit: 'cover',
		objectPosition: 'right',
		[theme.breakpoints.down('sm')]: {
			minWidth: '650px',
		},
	},
}));
