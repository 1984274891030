import { makeStyles } from '@material-ui/styles';

export default makeStyles({
	header: {
		margin: '50px 50px 50px 20px',
	},
	headerText: {
		fontSize: '36px',
		fontWeight: 'bold',
		color: (props) => props.colourTheme.text || '#55cae5',
	},
});
