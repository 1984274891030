import React from 'react';
import PropTypes from 'prop-types';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);

const DateRangePicker = (props) => {
	const { dateFormat = 'yyyy-MM-dd', ranges, handleSelectDateRange, months = 1, showDateDisplay = false } = props;
	return (
		<DateRange
			months={months}
			editableDateInputs
			onChange={typeof handleSelectDateRange === 'function' ? (item) => handleSelectDateRange(item) : null}
			moveRangeOnFirstSelection={false}
			ranges={ranges}
			maxDate={yesterday}
			showDateDisplay={showDateDisplay}
			rangeColors={['#2774AE']}
			dateDisplayFormat={dateFormat}
			weekStartsOn={1}
		/>
	);
};

DateRangePicker.propTypes = {
	dateFormat: PropTypes.string,
	ranges: PropTypes.array,
	handleSelectDateRange: PropTypes.func,
	months: PropTypes.number,
	showDateDisplay: PropTypes.bool,
};

DateRangePicker.defaultProps = {
	dateFormat: 'yyyy-MM-dd',
	ranges: [],
	handleSelectDateRange: null,
	months: 1,
	showDateDisplay: false,
};

export default DateRangePicker;
