import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Grid } from '@material-ui/core';
import AustraliaMap from '../AustraliaMap';
import useStyles from './styles';

const NationalDistribution = (props) => {
	const { nationalDistributionData, colourTheme } = props;

	const classes = useStyles(props);

	return (
		<div className={classNames(classes.section)}>
			<Grid item xs={12}>
				<h3 className={classNames(classes.statTitle)}>{nationalDistributionData.headingText}</h3>
			</Grid>
			<Grid item xs={12}>
				<div className={classNames(classes.map)}>
					<AustraliaMap data={nationalDistributionData.data} colourTheme={colourTheme} />
				</div>
			</Grid>
		</div>
	);
};

NationalDistribution.propTypes = {
	nationalDistributionData: PropTypes.object,
	colourTheme: PropTypes.object,
};

NationalDistribution.defaultProps = {
	nationalDistributionData: null,
	colourTheme: null,
};

export default NationalDistribution;
