import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
	censusDashboardHeader: {
		height: '100%',
		width: '100%',
		paddingLeft: 20,
		paddingRight: 10,
	},
	censusDashboardTitle: {
		float: 'left',
		marginBottom: 10,
		marginRight: 10,
		marginTop: 20,
	},
	censusDashboardTitleText: { marginBottom: '10px', fontWeight: 600 },
	filtersRight: {
		float: 'right',
		width: 'auto',
		display: 'flex',
		marginBottom: '10px',
		marginTop: 20,
	},
	siteSelector: {
		width: 'auto',
	},
	stateSelector: {
		width: 100,
	},
	selectors: {
		paddingTop: 10,
		margin: '0px 10px',
	},
	inlineFilters: {
		display: 'flex',
		marginTop: 5,
	},
}));
