import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
	pageLoader: {
		height: '100%',
		width: '100%',
		overflow: 'auto',
		whiteSpace: 'nowrap',
		backgroundColor: '#f2f2f2',
	},
	noDataSection: {
		height: '80vh',
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		background: '#f4f0f0',
	},
	noDataIcon: {
		marginRight: '10px',
	},
	censusDataPageWrapper: {
		height: '100%',
		width: '100%',
		overflow: 'scroll',
	},
	censusDataPageLayout: {
		height: '100%',
		width: '100%',
		minWidth: '650px',
	},
	sectionBlack: {
		backgroundColor: '#000000',
		color: '#ffffff',
		'& h1': {
			color: '#ffffff',
		},
		'& h2': {
			color: '#ffffff',
		},
		'& h3': {
			color: '#ffffff',
		},
	},
	colored: {
		backgroundColor: (props) => (props.communitiesData.colourTheme !== undefined ? props.communitiesData.colourTheme.defultColour : '#55cae5'),
	},
	blackColored: {
		backgroundColor: '#000000',
		paddingLeft: '20px',
	},
	row: {
		display: 'flex',
		backgroundColor: '#000000',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
		},
	},
	right: {
		flexBasis: '50%',
		margin: 'auto',
		alignItems: 'center',
		justifyContent: 'center',
		float: 'left',
	},
	left: {
		display: 'flex',
		flexBasis: '50%',
		maxHeight: '475px',
		[theme.breakpoints.down('md')]: {
			maxHeight: '490px',
		},
	},
	imageContainer: {
		flexBasis: '100%',
	},
	imageStyle: {
		width: '100% !important',
		height: '100%',
		padding: '0',
		margin: '0',
		objectFit: 'cover',
		objectPosition: 'right',
	},
}));
