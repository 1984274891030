import { makeStyles } from '@material-ui/styles';

export default makeStyles({
	sectionHeader: {
		padding: '50px 0px 50px 20px',
	},
	sectionHeaderText: {
		fontSize: '30px',
		fontWeight: 'bold',
	},
	pageFooterLogoContainer: {
		display: 'flex',
		alignItems: 'end',
		justifyContent: 'center',
		height: '100%',
		width: '100%',
		padding: '0px 50px 50px 0px',
	},
});
