import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Grid } from '@material-ui/core';
import DiscretionarySpend from './DiscretionarySpend';
import Demographics from './Demographics';
import CategorizedListComponent from '../CategorizedListComponent';

import useStyles from './styles';

const ShopWithMeSection = (props) => {
	const classes = useStyles(props);

	const { data, colourTheme } = props;

	return (
		<>
			<Grid item xs={12}>
				<div className={classNames(classes.sectionHeader)}>
					<h1 className={classes.sectionHeaderText}>{data.headingText}</h1>
				</div>
			</Grid>
			<Grid item xs={12} sm={12} md={6} lg={4}>
				<CategorizedListComponent list={data.attitudeToShopping} iconColor={colourTheme.icon.fill} key='attitude-to-shopping' />
			</Grid>
			<Grid item xs={12} sm={12} md={6} lg={4}>
				<CategorizedListComponent list={data.storePreferences} iconColor={colourTheme.icon.fill} key='store-preferences' />
			</Grid>
			<Grid item xs={12} sm={12} md={6} lg={4}>
				<DiscretionarySpend discretionarySpend={data.discretionarySpend} colourTheme={colourTheme} />
			</Grid>
			<Grid item xs={12}>
				<div className={classes.subSection}>
					<h3 className={classes.statTitle}>{data.onlineShopping.headingText}</h3>
				</div>
			</Grid>
			<Grid item xs={12} sm={12} md={6} lg={4}>
				<CategorizedListComponent list={data.onlineShopping} hideHeader={true} iconColor={colourTheme.icon.fill} key='online-shopping' />
			</Grid>
			<Grid item xs={12} sm={12} md={6} lg={8}>
				<Demographics demographics={data.demographics} colourTheme={colourTheme} />
			</Grid>
		</>
	);
};

ShopWithMeSection.propTypes = {
	data: PropTypes.shape({
		headingText: PropTypes.string,
		attitudeToShopping: PropTypes.object,
		storePreferences: PropTypes.object,
		discretionarySpend: PropTypes.object,
		onlineShopping: PropTypes.object,
		demographics: PropTypes.object,
	}),
	colourTheme: PropTypes.object,
};

ShopWithMeSection.defaultProps = {
	data: {
		headingText: '',
		attitudeToShopping: null,
		storePreferences: null,
		discretionarySpend: null,
		onlineShopping: null,
		demographics: null,
	},
	colourTheme: null,
};

export default ShopWithMeSection;
