/* eslint-disable func-names */
import { takeEvery, call, fork, all, put } from 'redux-saga/effects';
import * as ACTION_TYPES from '../../constants/ActionTypes';
import {
	getSiteReportVisitsByDay,
	getSiteReportVisitsByDate,
	getSiteReportPopularityByDay,
	getSiteReportDemographics,
	getSiteReportMonthlyStats,
} from '../apis/sitesAPIs';
import {
	getSiteReportVisitsByDayDone,
	getSiteReportVisitsByDayFailed,
	getSiteReportVisitsByDateDone,
	getSiteReportVisitsByDateFailed,
	getSiteReportPopularityByDayDone,
	getSiteReportPopularityByDayFailed,
	getSiteReportDemographicsDone,
	getSiteReportDemographicsFailed,
	getSiteReportMonthlyStatsDone,
	getSiteReportMonthlyStatsFailed,
} from '../actions/sitesActions';

function* watchFetchSiteReportVisitsByDayDone() {
	yield takeEvery([ACTION_TYPES.CURRENT_SITE_SELECTED, ACTION_TYPES.CENTRE_DASHBOARD_REPORT], function* (action) {
		try {
			const sites = yield call(getSiteReportVisitsByDay, action.siteId, action.startDate, action.endDate);
			if (Array.isArray(sites) && sites.length > 0) {
				yield put(getSiteReportVisitsByDayDone(sites));
			} else {
				yield put(getSiteReportVisitsByDayFailed(sites));
			}
		} catch (error) {
			yield put(getSiteReportVisitsByDayFailed(error));
		}
	});
}

function* watchFetchSiteReportVisitsByDayOnlyDone() {
	yield takeEvery(ACTION_TYPES.SITE_REPORT_VISITS_BY_DAY, function* (action) {
		try {
			const result = yield call(getSiteReportVisitsByDay, action.siteId, action.startDate, action.endDate);
			if (Array.isArray(result)) {
				yield put(getSiteReportVisitsByDayDone(result));
			} else {
				yield put(getSiteReportVisitsByDayFailed(result));
			}
		} catch (error) {
			yield put(getSiteReportVisitsByDayFailed(error));
		}
	});
}

function* watchFetchSiteReportVisitsByDateDone() {
	yield takeEvery([ACTION_TYPES.CURRENT_SITE_SELECTED, ACTION_TYPES.CENTRE_DASHBOARD_REPORT], function* (action) {
		try {
			const sites = yield call(getSiteReportVisitsByDate, action.siteId, action.startDate, action.endDate);
			if (Array.isArray(sites) && sites.length > 0) {
				yield put(getSiteReportVisitsByDateDone(sites));
			} else {
				yield put(getSiteReportVisitsByDateFailed(sites));
			}
		} catch (error) {
			yield put(getSiteReportVisitsByDateFailed(error));
		}
	});
}

function* watchFetchSiteReportVisitsByDateOnlyDone() {
	yield takeEvery(ACTION_TYPES.SITE_REPORT_VISITS_BY_DATE, function* (action) {
		try {
			const result = yield call(getSiteReportVisitsByDate, action.siteId, action.startDate, action.endDate);
			yield put(getSiteReportVisitsByDateDone(result));
		} catch (error) {
			yield put(getSiteReportVisitsByDateFailed(error));
		}
	});
}

function* watchFetchSiteReportPopularityByDayDone() {
	yield takeEvery([ACTION_TYPES.CURRENT_SITE_SELECTED, ACTION_TYPES.CENTRE_DASHBOARD_REPORT], function* (action) {
		try {
			if (action.siteId) {
				const sites = yield call(getSiteReportPopularityByDay, action.siteId, action.startDate, action.endDate);
				if (Array.isArray(sites) && sites.length > 0) {
					yield put(getSiteReportPopularityByDayDone(sites));
				} else {
					yield put(getSiteReportPopularityByDayFailed(sites));
				}
			}
		} catch (error) {
			yield put(getSiteReportPopularityByDayFailed(error));
		}
	});
}

function* watchFetchSiteReportDemographicsDone() {
	yield takeEvery([ACTION_TYPES.CURRENT_SITE_SELECTED, ACTION_TYPES.CENTRE_DASHBOARD_REPORT], function* (action) {
		try {
			if (action.siteId) {
				const sites = yield call(getSiteReportDemographics, action.siteId, action.startDate, action.endDate);
				if (Array.isArray(sites) && sites.length > 0) {
					yield put(getSiteReportDemographicsDone(sites));
				} else {
					yield put(getSiteReportDemographicsFailed(sites));
				}
			}
		} catch (error) {
			yield put(getSiteReportDemographicsFailed(error));
		}
	});
}

function* watchFetchSiteReportMonthlyStatsDone() {
	yield takeEvery(ACTION_TYPES.CENTRE_DASHBOARD_REPORT, function* (action) {
		try {
			if (action.siteId) {
				const sites = yield call(getSiteReportMonthlyStats, action.siteId, action.startDate, action.endDate);
				if (Array.isArray(sites) && sites.length > 0) {
					yield put(getSiteReportMonthlyStatsDone(sites));
				} else {
					yield put(getSiteReportMonthlyStatsFailed(sites));
				}
			}
		} catch (error) {
			yield put(getSiteReportMonthlyStatsFailed(error));
		}
	});
}

export default function* rootSaga() {
	yield all([
		fork(watchFetchSiteReportVisitsByDayDone),
		fork(watchFetchSiteReportVisitsByDayOnlyDone),
		fork(watchFetchSiteReportVisitsByDateDone),
		fork(watchFetchSiteReportPopularityByDayDone),
		fork(watchFetchSiteReportDemographicsDone),
		fork(watchFetchSiteReportVisitsByDateOnlyDone),
		fork(watchFetchSiteReportMonthlyStatsDone),
	]);
}
