import React from 'react';
import PropTypes from 'prop-types';
import TextView from '../TextView';

import useStyles from './styles';

const PopulationSummary = (props) => {
	const classes = useStyles(props);

	const { data } = props;

	return (
		<div className={data.length === 1 ? classes.summaryContainerWithSingleItem : classes.summaryContainer} data-testid='population-summary'>
			{data.map((item, key) => (
				<TextView primaryText={item.count} secondaryText={item.category} key={key} />
			))}
		</div>
	);
};

PopulationSummary.propTypes = {
	data: PropTypes.array,
};

PopulationSummary.defaultProps = {
	data: [],
};

export default PopulationSummary;
