import React from 'react';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { Colors } from '../../../constants/Constants.json';

am4core.useTheme(am4themes_animated);

class InnerPieChart extends React.Component {
	componentDidMount() {
		const { id, data, categoryX, valueY, colors, innerRadius } = this.props;

		let chart = am4core.create(`chart-${id}`, am4charts.PieChart);

		chart.data = data;

		let series = chart.series.push(new am4charts.PieSeries());
		series.dataFields.value = valueY;
		series.dataFields.category = categoryX;
		series.labels.template.text = '{category}';

		chart.innerRadius = am4core.percent(innerRadius);

		series.alignLabels = false;

		// Set up fills
		series.slices.template.fillOpacity = 1;

		var colorSet = new am4core.ColorSet();
		colorSet.list = colors.map((color) => {
			return new am4core.color(color);
		});
		series.colors = colorSet;

		var hs = series.slices.template.states.getKey('hover');
		hs.properties.scale = 1;
		hs.properties.fillOpacity = 0.5;

		this.chart = chart;
	}
	componentDidUpdate(prevProps) {
		const { data } = this.props;
		if (data.length > 0 && !isEqual(prevProps.data, data)) {
			this.chart.data = data;
		}
	}
	componentWillUnmount() {
		if (this.chart) {
			this.chart.dispose();
		}
	}
	render() {
		const { id } = this.props;
		return <div id={`chart-${id}`} style={{ width: '100%', height: '100%' }}></div>;
	}
}
InnerPieChart.propTypes = {
	id: PropTypes.string,
	data: PropTypes.array,
	categoryX: PropTypes.string,
	valueY: PropTypes.string,
	colors: PropTypes.array,
	innerRadius: PropTypes.number,
};

InnerPieChart.defaultProps = {
	id: '',
	data: [],
	categoryX: '',
	valueY: '',
	colors: Colors,
	innerRadius: 0,
};

export default InnerPieChart;
