import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
	sectionHeader: {
		padding: '50px 0px 50px 20px',
		backgroundColor: '#000000',
		color: '#ffffff !important',
	},
	sectionHeaderText: {
		fontSize: '30px',
		fontWeight: 'bold',
		color: '#ffffff',
	},
}));
