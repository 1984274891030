import React from 'react';
import PropTypes from 'prop-types';
import SinglePieChart from '../SinglePieChart';

import useStyles from './styles';

const DetailedSinglePieChart = (props) => {
	const classes = useStyles(props);

	const { footerText } = props;

	return (
		<div className={classes.chartContainer} data-testid='detailed-single-pie-chart'>
			<SinglePieChart {...props} />
			{footerText && (
				<div className={classes.chartFooterContainer} data-testid='detailed-single-pie-chart-footer-text'>
					<h3>{footerText}</h3>
				</div>
			)}
		</div>
	);
};

DetailedSinglePieChart.propTypes = {
	footerText: PropTypes.string,
};

DetailedSinglePieChart.defaultProps = {
	footerText: '',
};

export default DetailedSinglePieChart;
