import React from 'react';
import PropTypes from 'prop-types';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import useStyles from './styles';

const PasswordTextField = (props) => {
	const classes = useStyles(props);

	const { password, handleChangePasswordAction, id } = props;

	const [showPassword, setShowPassword] = React.useState(false);

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	const handleChangePassword = (event) => {
		handleChangePasswordAction(event.target.value);
	};

	return (
		<FormControl className={classes.formControl} variant='outlined' data-testid='password-text-field'>
			<OutlinedInput
				id={'outlined-adornment-password' + id}
				type={showPassword ? 'text' : 'password'}
				value={password}
				className={classes.textField}
				onChange={handleChangePassword}
				autoComplete='new-password'
				endAdornment={
					<InputAdornment position='end'>
						<IconButton
							aria-label='toggle password visibility'
							onClick={handleClickShowPassword}
							onMouseDown={handleMouseDownPassword}
							edge='end'
							className={classes.root}
							data-testid='password-text-field-show-password'
						>
							{showPassword ? (
								<Visibility data-testid='password-text-field-visibility' />
							) : (
								<VisibilityOff data-testid='password-text-field-visibility-off' />
							)}
						</IconButton>
					</InputAdornment>
				}
			/>
		</FormControl>
	);
};

PasswordTextField.propTypes = {
	password: PropTypes.string,
	handleChangePasswordAction: PropTypes.func,
	id: PropTypes.string,
};

PasswordTextField.defaultProps = {
	password: '',
	handleChangePasswordAction: null,
	id: '',
};

export default PasswordTextField;
