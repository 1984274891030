import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Grid } from '@material-ui/core';
import CategorizedListComponent from '../CategorizedListComponent';

import useStyles from './styles';

const RelaxWithMeSection = (props) => {
	const classes = useStyles(props);

	const { data, footer } = props;

	return (
		<>
			<Grid item xs={12}>
				<div className={classNames(classes.sectionHeader)}>
					<h1 className={classes.sectionHeaderText}>{data.headingText}</h1>
				</div>
			</Grid>
			<Grid item xs={12} sm={12} md={6} lg={4}>
				<CategorizedListComponent list={data.goingOut} iconColor='#ffffff' />
			</Grid>
			<Grid item xs={12} sm={12} md={6} lg={4}>
				<CategorizedListComponent list={data.dining} iconColor='#ffffff' />
			</Grid>
			<Grid item xs={12} sm={12} md={6} lg={4}>
				<CategorizedListComponent list={data.stayingIn} iconColor='#ffffff' />
			</Grid>
			<Grid item xs={12} sm={12} md={6} lg={4}>
				<CategorizedListComponent list={data.gettingActive} iconColor='#ffffff' />
			</Grid>
			<Grid item xs={12} sm={12} md={6} lg={4}>
				<CategorizedListComponent list={data.travelling} iconColor='#ffffff' />
			</Grid>
			<Grid item xs={12} sm={12} md={6} lg={4}>
				<div className={classes.pageFooterLogoContainer}>
					<a href={footer.href} target='_blank' rel='noopener noreferrer'>
						<img src={`${process.env.REACT_APP_MEDIA_FILES_S3_BUCKET_URL}/personas/${footer.filename}`} alt={footer.alt} />
					</a>
				</div>
			</Grid>
		</>
	);
};

RelaxWithMeSection.propTypes = {
	data: PropTypes.shape({
		headingText: PropTypes.string,
		goingOut: PropTypes.object,
		dining: PropTypes.object,
		stayingIn: PropTypes.object,
		gettingActive: PropTypes.object,
		travelling: PropTypes.object,
	}),
	footer: PropTypes.object,
};

RelaxWithMeSection.defaultProps = {
	data: {
		headingText: '',
		goingOut: null,
		dining: null,
		stayingIn: null,
		gettingActive: null,
		travelling: null,
	},
	footer: null,
};

export default RelaxWithMeSection;
