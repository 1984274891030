import { Map } from 'immutable';
import * as ACTION_TYPES from '../../constants/ActionTypes';

export const initState = new Map({
	appTheme: true,
	isMainMenuCollapsed: false,
	menuIdentifier: '',
	selectedMenuIdentifier: '',
});

const appReducer = (state = initState, action) => {
	switch (action.type) {
		case ACTION_TYPES.TOGGLE_APP_THEME:
			return state.set('appTheme', action.bools);
		case ACTION_TYPES.TOGGLE_MAIN_MENU_EXPANSION:
			return state.set('isMainMenuCollapsed', !state.get('isMainMenuCollapsed'));
		case ACTION_TYPES.CLICK_MAIN_MENU_ITEM:
			if (action.bools) {
				return state.set('menuIdentifier', action.identifier).set('selectedMenuIdentifier', action.identifier);
			}
			return state.set('menuIdentifier', '').set('', action.identifier);
		default:
			return state;
	}
};

export default appReducer;
