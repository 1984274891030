import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { clickMainMenuItem } from '../../../redux/actions/appActions';
import SubMenu from '../SubMenu';
import ExpansionPanelDetails from '../ExpansionPanelDetails';
import ExpansionPanelSummary from '../ExpansionPanelSummary';
import ExpansionPanel from '../ExpansionPanel';
import SingleMenuItemSummary from '../SingleMenuItemSummary';
import CustomTooltip from '../CustomTooltip';

class MenuItem extends React.Component {
	state = {
		isShowName: true,
	};

	componentDidMount() {
		const { isMainMenuCollapsed } = this.props;
		if (isMainMenuCollapsed) {
			this.setState({
				isShowName: false,
			});
		}
	}

	componentDidUpdate(prevProps) {
		const { isMainMenuCollapsed } = this.props;
		if (isMainMenuCollapsed !== prevProps.isMainMenuCollapsed) {
			this.setState({
				isShowName: !isMainMenuCollapsed,
			});
		}
	}

	handleChange = (menuIdentifier) => {
		const { selectedMenuIdentifier, clickMainMenuItem } = this.props;
		if (selectedMenuIdentifier !== menuIdentifier) {
			clickMainMenuItem(true, menuIdentifier);
		} else {
			clickMainMenuItem(false, menuIdentifier);
		}
	};

	handleClick = (link) => {
		const { history } = this.props;
		history.push(link);
	};

	render() {
		const {
			history,
			disabled,
			name,
			path,
			lockedPath,
			menu,
			Menu,
			link,
			menuIdentifier,
			currentMenuIdentifier,
			selectedMenuIdentifier,
			selectedSubMenuIdentifier,
			selectedThirdSubMenuIdentifier,
			isMainMenuCollapsed,
			icon,
		} = this.props;

		return (
			<ExpansionPanel
				square
				expanded={!!(!isMainMenuCollapsed && currentMenuIdentifier && currentMenuIdentifier === menuIdentifier)}
				onChange={disabled || isMainMenuCollapsed ? null : () => this.handleChange(menuIdentifier)}
				onClick={link ? () => this.handleClick(link) : null}
				isMainMenuCollapsed={isMainMenuCollapsed}
			>
				<ExpansionPanelSummary
					isExpanded={currentMenuIdentifier && currentMenuIdentifier === menuIdentifier}
					isSelected={selectedMenuIdentifier && selectedMenuIdentifier === menuIdentifier}
					isMainMenuCollapsed={isMainMenuCollapsed}
					disabled={disabled}
					expandIcon={null}
				>
					<CustomTooltip
						interactive
						disableHoverListener={!isMainMenuCollapsed || disabled}
						placement='right-start'
						title={
							<SubMenu
								menu={menu}
								Menu={Menu}
								history={history}
								menuIdentifier={menuIdentifier}
								selectedSubMenuIdentifier={selectedThirdSubMenuIdentifier}
								selectedMenuIdentifier={selectedSubMenuIdentifier}
							/>
						}
					>
						<div style={{ height: '100%', width: '100%' }}>
							<SingleMenuItemSummary
								isMainMenuCollapsed={isMainMenuCollapsed}
								currentMenuIdentifier={currentMenuIdentifier}
								menuIdentifier={menuIdentifier}
								path={path}
								lockedPath={lockedPath}
								disabled={disabled}
								showArrowIcon={link === undefined}
								name={name}
								isShowName={this.state.isShowName}
								icon={icon}
							/>
						</div>
					</CustomTooltip>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails
					history={history}
					menu={menu}
					Menu={Menu}
					menuIdentifier={menuIdentifier}
					selectedSubMenuIdentifier={selectedSubMenuIdentifier}
					selectedThirdSubMenuIdentifier={selectedThirdSubMenuIdentifier}
					isMainMenuCollapsed={isMainMenuCollapsed}
					handleClick={this.handleClick}
				/>
			</ExpansionPanel>
		);
	}
}

MenuItem.propTypes = {
	history: PropTypes.object,
	disabled: PropTypes.bool,
	name: PropTypes.string,
	path: PropTypes.string,
	lockedPath: PropTypes.string,
	menu: PropTypes.object,
	Menu: PropTypes.array,
	link: PropTypes.string,
	menuIdentifier: PropTypes.string,
	currentMenuIdentifier: PropTypes.string,
	selectedMenuIdentifier: PropTypes.string,
	selectedSubMenuIdentifier: PropTypes.string,
	selectedThirdSubMenuIdentifier: PropTypes.string,
	isMainMenuCollapsed: PropTypes.bool,
	icon: PropTypes.string,
};

MenuItem.defaultProps = {
	history: {},
	disabled: false,
	name: '',
	path: '',
	lockedPath: '',
	menu: {},
	Menu: [],
	link: '',
	menuIdentifier: '',
	currentMenuIdentifier: '',
	selectedMenuIdentifier: '',
	selectedSubMenuIdentifier: '',
	selectedThirdSubMenuIdentifier: '',
	isMainMenuCollapsed: false,
	icon: '',
};

const mapStateToProps = (state) => ({
	selectedThirdSubMenuIdentifier: state.appReducer.get('selectedThirdSubMenuIdentifier'),
	selectedMenuIdentifier: state.appReducer.get('selectedMenuIdentifier'),
});

const mapDispatchToProps = (dispatch) => ({
	clickMainMenuItem: (bools, identifier) => dispatch(clickMainMenuItem(bools, identifier)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuItem);
