import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((props) => ({
	root: {
		lineHeight: '24px',
		padding: (props) => (props.isMainMenuCollapsed ? '8px 20px' : '5px 20px'),
		color: (props) => (props.disabled ? 'rgba(255,255,255,0.65)' : '#fff'),
		fill: (props) => (props.disabled ? 'rgba(255,255,255,0.65)' : '#fff'),
		backgroundColor: (props) => props.isSelected && 'rgba(0,0,0,.25)',
		'&:hover': {
			backgroundColor: (props) => !props.disabled && 'rgba(0,0,0,.15)',
		},
	},
	expanded: {
		minHeight: (props) => (props.isMainMenuCollapsed ? '40px !important' : '34px !important'),
	},
}));

export const styles = {
	root: {
		textDecoration: 'none',
	},
	disabled: {
		opacity: '1 !important',
	},
	expanded: {
		marginTop: '0px !important',
		marginBottom: '0px !important',
	},
	content: {
		marginTop: 0,
		marginBottom: 0,
	},
};