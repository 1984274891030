import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { getCurrentUserInfo, initPreferredName } from '../../../helpers/utility';
import useStyles from './styles';

const UserProfileIcon = (props) => {
	const classes = useStyles(props);
	const { firstName, lastName } = props;
	return (
		<div className={classNames(classes.buttonIconOutside)} data-testid='user-profile-icon'>
			<div className={classNames(classes.buttonIconInside)}>
				<p className={classes.initName}>
					{firstName || lastName
						? `${initPreferredName(firstName)}${initPreferredName(lastName)}`
						: getCurrentUserInfo() && getCurrentUserInfo().username
						? initPreferredName(getCurrentUserInfo().username)
						: '_'}
				</p>
			</div>
		</div>
	);
};

UserProfileIcon.propTypes = {
	firstName: PropTypes.string,
	lastName: PropTypes.string,
};

UserProfileIcon.defaultProps = {
	firstName: '',
	lastName: '',
};

export default UserProfileIcon;
