/* eslint-disable func-names */
import { takeEvery, call, fork, all, put } from 'redux-saga/effects';
import * as ACTION_TYPES from '../../constants/ActionTypes';
import { getUserPermissionsByUserName, authenticateUser, fetchOwnerUserPreferences, resetUserPassword } from '../apis/authAPIs';
import {
	loginSuccess,
	loginFailure,
	logoutSuccess,
	logoutFailure,
	resetUserPasswordDone,
	resetUserPasswordFailed,
	getUserPermissionsActionDone,
	getUserPermissionsActionFailed,
	fetchOwnerUserActionDone,
	fetchOwnerUserActionFailed,
	signOut,
} from '../../redux/actions/authActions';
import { saveToken, clearToken } from '../../helpers/utility';

function* watchFetchUserPermissionsDone() {
	yield takeEvery(ACTION_TYPES.GET_USER_PERMISSIONS, function* (action) {
		try {
			const result = yield call(getUserPermissionsByUserName, action.username);
			yield put(getUserPermissionsActionDone(result));
		} catch (error) {
			console.log(error);
			yield put(getUserPermissionsActionFailed(error));
		}
	});
}

function* watchAuthenticateUserDone() {
	yield takeEvery(ACTION_TYPES.SIGNIN_REQUEST, function* (action) {
		try {
			const result = yield call(authenticateUser, action.username, action.password);
			if (result.message === 'Success') {
				const userInfo = { username: action.username };
				const resultSave = yield call(saveToken, result.token, userInfo, result.refreshToken);
				if (resultSave.message === 'Success') {
					yield put(loginSuccess());
				} else {
					yield put(loginFailure(result));
				}
			} else {
				yield put(loginFailure(result));
			}
		} catch (error) {
			yield put(loginFailure(error));
		}
	});
}

function* watchSignoutDone() {
	yield takeEvery(ACTION_TYPES.SIGNOUT_REQUEST, function* () {
		try {
			yield call(clearToken);
			yield put(logoutSuccess());
		} catch (error) {
			yield put(logoutFailure());
		}
	});
}

function* watchFetchOwnerUserDone() {
	yield takeEvery(ACTION_TYPES.FETCH_OWNER_USER_INFO, function* () {
		try {
			const result = yield call(fetchOwnerUserPreferences);
			if (Array.isArray(result)) {
				yield put(fetchOwnerUserActionDone(result));
			} else {
				yield put(fetchOwnerUserActionFailed(result));
			}
		} catch (error) {
			yield put(fetchOwnerUserActionFailed(error));
		}
	});
}

function* watchResetUserPasswordDone() {
	yield takeEvery(ACTION_TYPES.RESET_USER_PASSWORD, function* (action) {
		try {
			const result = yield call(resetUserPassword, action.userId, action.newPassword);
			if (result.message === 'Success') {
				yield put(resetUserPasswordDone(result));
				yield put(signOut());
			} else {
				yield put(resetUserPasswordFailed(result));
			}
		} catch (error) {
			console.log(error);
			yield put(resetUserPasswordFailed(error));
		}
	});
}

export default function* rootSaga() {
	yield all([
		fork(watchFetchUserPermissionsDone),
		fork(watchAuthenticateUserDone),
		fork(watchSignoutDone),
		fork(watchFetchOwnerUserDone),
		fork(watchResetUserPasswordDone),
	]);
}
