import React from 'react';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@material-ui/icons/ChevronRight';
import LockIcon from '@material-ui/icons/Lock';
import ReactSvg from 'react-svg';
import classNames from 'classnames/bind';
import useStyles from './styles';

const SingleMenuItemSummary = (props) => {
	const classes = useStyles(props);
	const { showArrowIcon, disabled, isMainMenuCollapsed, currentMenuIdentifier, menuIdentifier, path, lockedPath, isShowName, name, icon } = props;
	return (
		<div className={classes.tooltipWrapper} data-testid='single-menu-item-summary'>
			{isMainMenuCollapsed && disabled ? (
				<ReactSvg src={require(`${lockedPath}`)} className={classes.menuItemIcon} data-testid='single-menu-item-summary-lockedPath' />
			) : icon ? (
				<ReactSvg src={icon} className={classes.menuItemIcon} data-testid='single-menu-item-summary-svg' />
			) : (
				path && <ReactSvg src={require(`${path}`)} className={classes.menuItemIcon} data-testid='single-menu-item-summary-path' />
			)}
			{isShowName && <span className={classes.summaryText}>{name}</span>}
			{showArrowIcon &&
				(disabled ? (
					!isMainMenuCollapsed && <LockIcon className={classNames(classes.lockIcon)} />
				) : (
					<ExpandMoreIcon
						className={classNames(classes.expandIcon, {
							[classes.deg90]: !isMainMenuCollapsed && currentMenuIdentifier && currentMenuIdentifier === menuIdentifier,
							[classes.isCollapsed]: !isShowName,
						})}
					/>
				))}
		</div>
	);
};

SingleMenuItemSummary.propTypes = {
	showArrowIcon: PropTypes.bool,
	disabled: PropTypes.bool,
	isMainMenuCollapsed: PropTypes.bool,
	currentMenuIdentifier: PropTypes.string,
	menuIdentifier: PropTypes.string,
	path: PropTypes.string,
	lockedPath: PropTypes.string,
	isShowName: PropTypes.bool,
	name: PropTypes.string,
	icon: PropTypes.string,
};

SingleMenuItemSummary.defaultProps = {
	showArrowIcon: false,
	disabled: false,
	isMainMenuCollapsed: false,
	currentMenuIdentifier: '',
	menuIdentifier: '',
	path: null,
	lockedPath: '',
	isShowName: false,
	name: '',
	icon: '',
};

export default SingleMenuItemSummary;
