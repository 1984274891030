import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { toggleMainMenuExpansion } from '../../../redux/actions/appActions';
import SingleMenuItem from '../MenuItem';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { withStyles } from '@material-ui/core/styles';
import images from '../../../components/Icons';
import styles from './styles.scss';
import style from './styles';

const cx = classNames.bind(styles);

class SideMenu extends React.Component {
	state = {
		hoverIdentifier: '',
		settingIdentifiers: [],
		isExpanded: false,
	};

	handleMenuExpansion = () => {
		const { toggleMainMenuExpansion } = this.props;
		toggleMainMenuExpansion();
	};

	render() {
		const { history, isMainMenuCollapsed, firstName } = this.props;

		return (
			<div className={cx('mainMenu')} data-testid='side-menu'>
				<div className={cx('mainMenuNavigation')}>
					<div className={cx('collapseOrExpandBottom')} data-testid='side-menu-div'>
						{!isMainMenuCollapsed && <p className={cx('hiText')}>Hi, {firstName || 'there'}</p>}
						<div className={cx('toggleCollapsedOrExpanded')} onClick={this.handleMenuExpansion}>
							<ArrowForwardIosIcon className={cx('doubleArrowIcon', { forward: !isMainMenuCollapsed })} />
						</div>
					</div>
				</div>
				<div className={cx('mainMenusWrapper')}>
					<SingleMenuItem
						history={history}
						name='Dashboard'
						link='/centre_dashboard'
						isMainMenuCollapsed={isMainMenuCollapsed}
						icon={images.topApps}
						menuIdentifier='dashboard'
					/>
					<SingleMenuItem
						history={history}
						name='Census Data'
						link='/census_data'
						isMainMenuCollapsed={isMainMenuCollapsed}
						icon={images.topApps}
						menuIdentifier='census'
					/>
					<SingleMenuItem
						history={history}
						name='Personas'
						link='/roymorgan/personas'
						isMainMenuCollapsed={isMainMenuCollapsed}
						icon={images.topApps}
						menuIdentifier='personas'
					/>
					<SingleMenuItem
						history={history}
						name='Communities'
						link='/roymorgan/communities'
						isMainMenuCollapsed={isMainMenuCollapsed}
						icon={images.topApps}
						menuIdentifier='communities'
					/>
				</div>
			</div>
		);
	}
}

SideMenu.propTypes = {
	history: PropTypes.object,
	menuIdentifier: PropTypes.string,
	currentMenuIdentifier: PropTypes.string,
	isMainMenuCollapsed: PropTypes.bool,
	currentSubMenuIdentifier: PropTypes.string,
	firstName: PropTypes.string,
};

SideMenu.defaultProps = {
	history: {},
	isMainMenuCollapsed: false,
	firstName: '',
};

const mapDispatchToProps = (dispatch) => ({
	toggleMainMenuExpansion: () => dispatch(toggleMainMenuExpansion()),
});

export default connect(null, mapDispatchToProps)(withStyles(style)(SideMenu));
