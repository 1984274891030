import * as ACTION_TYPES from '../../constants/ActionTypes';

export function fetchContactSiteList(contactId) {
	return {
		type: ACTION_TYPES.FETCH_CONTACT_SITE_LIST,
		contactId,
	};
}

export function fetchContactSiteListDone(data) {
	return {
		type: ACTION_TYPES.FETCH_CONTACT_SITE_LIST_DONE,
		data,
	};
}

export function fetchContactSiteListFailed(error) {
	return {
		type: ACTION_TYPES.FETCH_CONTACT_SITE_LIST_FAILED,
		error,
	};
}

export function selectSite(selectedSite) {
	return {
		type: ACTION_TYPES.SELECT_SITE,
		selectedSite,
	};
}

export function selectRadius(selectedRadius) {
	return {
		type: ACTION_TYPES.SELECT_RADIUS,
		selectedRadius,
	};
}
