import { takeEvery, call, fork, all, put } from 'redux-saga/effects';
import * as ACTION_TYPES from '../../constants/ActionTypes';
import {
	fetchAgeGroupsDataDone,
	fetchAgeGroupsDataFailed,
	fetchEthnicityAndLanguagesDataDone,
	fetchEthnicityAndLanguagesDataFailed,
	fetchMaritalStatusAndFamilyTypesDataDone,
	fetchMaritalStatusAndFamilyTypesDataFailed,
	fetchPopulationDataDone,
	fetchPopulationDataFailed,
	fetchPropertyOwnershipDataDone,
	fetchPropertyOwnershipDataFailed,
	fetchWorkOccupationDataDone,
	fetchWorkOccupationDataFailed,
} from '../actions/censusDataActions';
import {
	getAgeGroupsData,
	getEthnicityAndLanguagesData,
	getMaritalStatusAndFamilytypesData,
	getPopulationData,
	getPropertyOwnerShipData,
	getWorkOccupationData,
} from '../apis/censusDataAPIs';

function* watchFetchPopulationDataDone() {
	yield takeEvery([ACTION_TYPES.FETCH_POPULATION_DATA, ACTION_TYPES.FETCH_CENSUS_DASHBOARD_DATA], function* (action) {
		try {
			const result = yield call(getPopulationData, action.siteId, action.censusRadius);
			yield put(fetchPopulationDataDone(result));
		} catch (error) {
			yield put(fetchPopulationDataFailed(error));
		}
	});
}

function* watchFetchAgeGroupsDataDone() {
	yield takeEvery([ACTION_TYPES.FETCH_AGE_GROUPS_DATA, ACTION_TYPES.FETCH_CENSUS_DASHBOARD_DATA], function* (action) {
		try {
			const result = yield call(getAgeGroupsData, action.siteId, action.censusRadius);
			yield put(fetchAgeGroupsDataDone(result));
		} catch (error) {
			yield put(fetchAgeGroupsDataFailed(error));
		}
	});
}

function* watchFetchWorkOccupationDataDone() {
	yield takeEvery([ACTION_TYPES.FETCH_WORK_OCCUPATION_DATA, ACTION_TYPES.FETCH_CENSUS_DASHBOARD_DATA], function* (action) {
		try {
			const result = yield call(getWorkOccupationData, action.siteId, action.censusRadius);
			yield put(fetchWorkOccupationDataDone(result));
		} catch (error) {
			yield put(fetchWorkOccupationDataFailed(error));
		}
	});
}

function* watchFetchEthnicityAndLanguagesDataDone() {
	yield takeEvery([ACTION_TYPES.FETCH_ETHNICITY_AND_LANGUAGES_DATA, ACTION_TYPES.FETCH_CENSUS_DASHBOARD_DATA], function* (action) {
		try {
			const result = yield call(getEthnicityAndLanguagesData, action.siteId, action.censusRadius);
			yield put(fetchEthnicityAndLanguagesDataDone(result));
		} catch (error) {
			yield put(fetchEthnicityAndLanguagesDataFailed(error));
		}
	});
}

function* watchFetchMaritalStatusAndFamilyTypesDataDone() {
	yield takeEvery([ACTION_TYPES.FETCH_MARITAL_STATUS_AND_FAMILY_TYPES_DATA, ACTION_TYPES.FETCH_CENSUS_DASHBOARD_DATA], function* (action) {
		try {
			const result = yield call(getMaritalStatusAndFamilytypesData, action.siteId, action.censusRadius);
			yield put(fetchMaritalStatusAndFamilyTypesDataDone(result));
		} catch (error) {
			yield put(fetchMaritalStatusAndFamilyTypesDataFailed(error));
		}
	});
}

function* watchFetchPropertyOwnershipDataDone() {
	yield takeEvery([ACTION_TYPES.FETCH_PROPERTY_OWNER_SHIP_DATA, ACTION_TYPES.FETCH_CENSUS_DASHBOARD_DATA], function* (action) {
		try {
			const result = yield call(getPropertyOwnerShipData, action.siteId, action.censusRadius);
			yield put(fetchPropertyOwnershipDataDone(result));
		} catch (error) {
			yield put(fetchPropertyOwnershipDataFailed(error));
		}
	});
}

export default function* rootSaga() {
	yield all([
		fork(watchFetchPopulationDataDone),
		fork(watchFetchAgeGroupsDataDone),
		fork(watchFetchWorkOccupationDataDone),
		fork(watchFetchEthnicityAndLanguagesDataDone),
		fork(watchFetchMaritalStatusAndFamilyTypesDataDone),
		fork(watchFetchPropertyOwnershipDataDone),
	]);
}
