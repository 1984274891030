import React from 'react';
import PropTypes from 'prop-types';
import ColumnBarChart from '../ColumnBarChart';

import useStyles from './styles';

const DetailedColumnBarChart = (props) => {
	const classes = useStyles(props);

	const { footerText } = props;

	return (
		<div className={classes.chartContainer} data-testid='detailed-column-bar-chart'>
			<ColumnBarChart {...props} />
			{footerText && (
				<div className={classes.chartFooterContainer} data-testid='detailed-column-bar-chart-footer-text'>
					<h3>{footerText}</h3>
				</div>
			)}
		</div>
	);
};

DetailedColumnBarChart.propTypes = {
	footerText: PropTypes.string,
};

DetailedColumnBarChart.defaultProps = {
	footerText: '',
};

export default DetailedColumnBarChart;
