import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CustomButton from '../../CustomButton';
import { textfieldStyle } from '../../../constants/Theme';
import css from './styles';

const style = { ...textfieldStyle, ...css };

class SearchButton extends React.PureComponent {
	render() {
		const { classes, handleClickAction, textColor, color, backgroundColor, highlightHover, disableHover } = this.props;

		return (
			<div className={classes.selectors} data-testid='search-button'>
				<CustomButton
					handleClickAction={handleClickAction}
					textColor={textColor}
					color={color}
					backgroundColor={backgroundColor}
					highlightHover={highlightHover}
					disableHover={disableHover}
				>
					Search
				</CustomButton>
			</div>
		);
	}
}

SearchButton.propTypes = {
	handleClickAction: PropTypes.func,
	textColor: PropTypes.string,
	color: PropTypes.string,
	backgroundColor: PropTypes.string,
	highlightHover: PropTypes.bool,
	disableHover: PropTypes.bool,
};

SearchButton.defaultProps = {
	handleClickAction: null,
	textColor: '',
	color: '',
	backgroundColor: '',
	highlightHover: false,
	disableHover: false,
};

export default withStyles(style)(SearchButton);
