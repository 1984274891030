import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import useStyles from './styles';
import Description from '../Description';

const KnowMeDescription = (props) => {
	const classes = useStyles(props);

	const { description, images } = props;

	return (
		<>
			<div className={classes.row}>
				<Description description={description} />
				<div className={classNames(classes.imageContainer)}>
					<div className={classes.colImage}>
						<img
							src={`${process.env.REACT_APP_MEDIA_FILES_S3_BUCKET_URL}/personas/${images[2].filename}`}
							className={classes.knowMeImage}
							alt={images[2].filename}
						/>
					</div>
					<div className={classes.colImage}>
						<img
							src={`${process.env.REACT_APP_MEDIA_FILES_S3_BUCKET_URL}/personas/${images[3].filename}`}
							className={classes.knowMeImage}
							alt={images[3].filename}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

KnowMeDescription.propTypes = {
	description: PropTypes.string,
	singlePersonaSummaryImages: PropTypes.array,
	colourTheme: PropTypes.object,
};

KnowMeDescription.defaultProps = {
	description: '',
	singlePersonaSummaryImages: [],
	colourTheme: null,
};

export default KnowMeDescription;
