import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Grid } from '@material-ui/core';
import ReactSVG from 'react-svg';

import useStyles from './styles';

const LikeItem = (props) => {
	const { like, index, colourTheme } = props;

	const color = index % 2 !== 0 ? colourTheme.icon.fill : '#ffffff';

	const classes = useStyles({ ...props, color });

	return (
		<Grid item xs={4}>
			<div className={classNames(classes.likesItem)}>
				<span className={classNames(classes.likesItemIcon)}>
					<ReactSVG src={require(`../../../../Icons/personas/${like.filename}`)} />
				</span>
				<span className={classNames(classes.likeLabel)}>{like.title}</span>
			</div>
		</Grid>
	);
};

LikeItem.propTypes = {
	like: PropTypes.object,
	index: PropTypes.number,
	colourTheme: PropTypes.object,
};

LikeItem.defaultProps = {
	like: null,
	index: 0,
	colourTheme: null,
};

export default LikeItem;
