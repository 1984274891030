import * as ACTION_TYPES from '../../constants/ActionTypes';

export function clickMainMenuItem(bools, identifier) {
	return {
		type: ACTION_TYPES.CLICK_MAIN_MENU_ITEM,
		bools,
		identifier,
	};
}

export function toggleMainMenuExpansion() {
	return {
		type: ACTION_TYPES.TOGGLE_MAIN_MENU_EXPANSION,
	};
}

export function toggleSubMenuExpansionPanel(bools, menuIdentifier, submenuIdentifier) {
	return {
		type: ACTION_TYPES.TOGGLE_SUB_MENU_EXPANSION_PANEL,
		bools,
		menuIdentifier,
		submenuIdentifier,
	};
}
