import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from './styles';

const CustomSnackbarModal = (props) => {
	const { message = 'Success', open, handleClose } = props;
	const classes = useStyles();

	return (
		<Snackbar
			className={classes.root}
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			open={open}
			onClose={handleClose}
			message={message}
			action={
				<React.Fragment>
					<IconButton
						aria-label='close'
						color='inherit'
						className={classes.close}
						onClick={handleClose}
						data-testid='custom-snackbar-modal-handle-close'
					>
						<CloseIcon />
					</IconButton>
				</React.Fragment>
			}
			data-testid='custom-snackbar-modal'
		/>
	);
};

CustomSnackbarModal.propTypes = {
	message: PropTypes.string,
	open: PropTypes.bool,
	handleClose: PropTypes.func,
};

CustomSnackbarModal.defaultProps = {
	message: 'Success',
	open: false,
	handleClose: null,
};

export default CustomSnackbarModal;
