export default {
	formControl_autoComplete: {
		width: '100px',
	},
	autocompleteRoot: {
		backgroundColor: '#fff',
	},
	optionLabel: {
		marginLeft: 5,
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		fontSize: 12,
	},
	popupIndicator: {
		padding: 0,
	},
	noOptions: {
		color: '#2774AE',
		fontSize: 13,
	},
	listbox: {
		maxHeight: '80vh',
		width: '100%',
	},
	groupLabel: {
		fontSize: 15,
	},
};
