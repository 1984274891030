import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
	svgContainer: {
		textAlign: 'center',
	},
	socioSvg: {
		width: '100%',
		height: 'auto',
	},
	text: {
		fontSize: '16px',
		fontWeight: '600',
		strokeWidth: 0,
		textAnchor: 'middle',
		fill: 'rgb(255, 255, 255)',
	},
	outerText: {
		fontSize: '16px',
		fontWeight: '600',
		strokeWidth: 0,
		textAnchor: 'middle',
		fill: (props) => props.colourTheme.defultColour || '#55cae5',
	},
	mapStyle: {
		fill: (props) => props.colourTheme.defultColour || '#55cae5',
		stroke: 'white',
		strokeWidth: '1',
	},
	path: {
		strokeWidth: '5',
		stroke: (props) => props.colourTheme.defultColour || '#55cae5',
	},
}));
