import { createTheme } from '@material-ui/core/styles';

export const defaultDashboardFontSize = {
	typography: {
		h3: {
			fontSize: '1.2rem',
			'@media (min-width:300px)': {
				fontSize: '1.5rem',
			},
			'@media (min-width:600px)': {
				fontSize: '2rem',
			},
			'@media (min-width:800px)': {
				fontSize: '2.5rem',
			},
			'@media (min-width:1100px)': {
				fontSize: '3rem',
			},
			'@media (min-width:1400px)': {
				fontSize: '3.5rem',
			},
			'@media (min-width:1600px)': {
				fontSize: '4rem',
			},
		},
	},
};

export const overviewDashboardFontSize = {
	typography: {
		h3: {
			fontSize: '1rem',
			'@media (min-width:300px)': {
				fontSize: '1rem',
			},
			'@media (min-width:600px)': {
				fontSize: '1.5rem',
			},
			'@media (min-width:800px)': {
				fontSize: '1.75rem',
			},
			'@media (min-width:900px)': {
				fontSize: '2rem',
			},
			'@media (min-width:1000px)': {
				fontSize: '2.25rem',
			},
			'@media (min-width:1100px)': {
				fontSize: '2.75rem',
			},
			'@media (min-width:1200px)': {
				fontSize: '3rem',
			},
			'@media (min-width:1300px)': {
				fontSize: '3.25rem',
			},
			'@media (min-width:1400px)': {
				fontSize: '3.5rem',
			},
			'@media (min-width:1600px)': {
				fontSize: '4rem',
			},
		},
	},
};

export const overviewStyle = {
	textfieldHeight: {
		height: '50px',
		width: '100%',
		padding: '0px 50px',
	},
	textfieldHeightNoPadding: {
		height: '50px',
		width: '100%',
	},
	wrapperFull: {
		width: '100%',
		float: 'left',
		padding: '0px 30px',
		lineHeight: '30px',
		display: 'flex',
	},
	wrapper75NoPadding: {
		width: '75%',
		float: 'left',
		lineHeight: '30px',
		display: 'flex',
	},
	wrapperFullNoPadding: {
		width: '100%',
		float: 'left',
		lineHeight: '30px',
		display: 'flex',
	},
	wrapper75: {
		width: '75%',
		float: 'left',
		padding: '0px 30px',
		lineHeight: '30px',
		display: 'flex',
	},
	wrapper: {
		width: '50%',
		float: 'left',
		padding: '0px 30px',
		lineHeight: '30px',
		display: 'flex',
	},
	wrapperNoPadding: {
		width: '50%',
		float: 'left',
		lineHeight: '30px',
		display: 'flex',
	},
	wrapperNoPaddingNoLineHeight: {
		width: '50%',
		float: 'left',
		display: 'flex',
	},
	wrapper33: {
		width: '33%',
		float: 'left',
		padding: '0px 30px',
		lineHeight: '30px',
		display: 'flex',
	},
	wrapperRight: {
		width: '50%',
		float: 'right',
		padding: '0px 30px',
		lineHeight: '30px',
		display: 'flex',
	},
	text: {
		color: '#999',
	},
	title: {
		direction: 'rtl',
		paddingRight: 10,
		float: 'left',
		display: 'flex',
	},
	requiredIcon: {
		fontSize: 8,
		fill: '#c33634',
		marginBottom: 'auto',
		marginTop: 'auto',
	},
	descriptionTextAreaWrapper: {
		padding: '0px 30px',
		height: '200px',
		'& textArea': {
			fontWeight: 'normal',
			padding: 10,
		},
	},
	nameValue: {
		display: 'inline-block',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},
};

export const dashboardCardStyle = {
	chartWrapper: {
		height: 'calc(100% - 60px)',
		width: '100%',
	},
};

export const siteDashboardChartWrapper = 'calc(100% - 60px)';

export const siteDashboardChartWithExportWrapper = 'calc(100% - 100px)';

export const amchartsStyle = {
	tooltipFontSize: '13px',
	labelsFontSize: '11px',
	titleFontSize: '13px',
	legendFontSize: '11px',
	legendWidthHeight: '8px',
	rangeLabelsFontSize: '15px',
};

export const tooltipStyle = {
	head: {
		color: '#fff',
		fontSize: 13,
		lineHeight: '30px',
		height: 30,
		backgroundColor: 'rgba(0, 0, 0, 0.25)',
		padding: '0px 10px',
		borderTopRightRadius: 4,
		borderTopLeftRadius: 4,
		fontWeight: 'bold',
	},
	divider: {
		backgroundColor: '#000',
		height: 1,
		width: '100%',
	},
	description: {
		fontSize: 12,
		fontWeight: '300',
		color: '#fff',
		padding: '10px',
	},
};

export const textfieldStyle = {
	formControl: {
		width: '100%',
		height: 'auto',
	},
	inputLabel: {
		fontSize: 11,
	},
	labelDense: {
		transform: 'translate(14px, 10px) scale(1) !important',
	},
	labelShrink: {
		transform: 'translate(15px, -5px) scale(0.75) !important',
	},
	input: {
		fontSize: 12,
		lineHeight: '30px',
		height: 30,
		// overflow: 'hidden',
		// textOverflow: 'ellipsis',
		// display: '-webkit-box',
		// WebkitLineClamp: 1 /* number of lines to show */,
	},
	inputSelect: {
		fontSize: 12,
		lineHeight: '30px',
		height: 30,
		width: '100%',
		paddingTop: 0,
		paddingBottom: 0,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		display: '-webkit-box',
		WebkitLineClamp: 1 /* number of lines to show */,
		'&:focus': {
			backgroundColor: '#fff',
		},
	},
	cssIconOutlined: {
		top: 0,
		right: 5,
	},
	cssOutlinedInput: {
		height: '100%',
		width: '100%',
		paddingTop: '0px !important',
		paddingBottom: '0px !important',
		'&$cssFocused $notchedOutline': {
			borderColor: '#c4c4c4 !important',
		},
	},
	cssFocused: {},
	notchedOutline: {
		borderColor: '#c4c4c4 !important',
	},
	adornedEnd: {
		paddingRight: '0px',
	},
	underline: {
		// color: 'red',
	},
	dropdownStyle: {
		'& .MuiList-padding': {
			paddingTop: 0,
			paddingBottom: 0,
		},
	},
	menuItem: {
		minHeight: 30,
		fontSize: 12,
		'&:hover': {
			backgroundColor: 'rgba(0,0,0,.04)',
		},
	},
	placeholder: {
		opacity: 0.5,
	},
};

export const tableStyle = {
	border: {
		padding: '3px 10px',
		borderRadius: 11,
		color: '#fff',
		display: 'inline',
	},
	DarkBlue: {
		backgroundColor: '#226cb0',
	},
	Blue: {
		backgroundColor: '#66acec',
	},
	Orange: {
		backgroundColor: '#ed8743',
	},
	Red: {
		backgroundColor: '#2774AE',
	},
	LightGreen: {
		backgroundColor: '#76d7c4',
	},
	Purple: {
		backgroundColor: '#7f77af',
	},
	DeepGreen: {
		backgroundColor: '#4c8062',
	},
	DarkGrey: {
		backgroundColor: '#777777',
	},
	ShopperDarkPixel: {
		backgroundColor: '#2774AE',
	},
	ShopperLightPixel: {
		backgroundColor: '#56B7E6',
	},
	ShopperSky: {
		backgroundColor: '#0762C8',
	},
	ShopperGo: {
		backgroundColor: '#00C2A2',
	},
	ShopperSun: {
		backgroundColor: '#FFC915',
	},
	ShopperLightGrey: {
		backgroundColor: '#B1B3B3',
	},
	ShopperHeritage: {
		backgroundColor: '#DF4661',
	},
	ShopperRoad: {
		backgroundColor: '#7FA0AC',
	},
	ShopperPaleGreen: {
		backgroundColor: '#A7E6D7',
	},
	ShopperPaleYellow: {
		backgroundColor: '#F1EB9C',
	},
	ShopperDarkGrey: {
		backgroundColor: '#716E6A',
	},
	TextDarkBlue: {
		color: '#226cb0',
	},
	TextBlue: {
		color: '#66acec',
	},
	TextOrange: {
		color: '#ed8743',
	},
	TextLightGreen: {
		color: '#76d7c4',
	},
	TextPurple: {
		color: '#7f77af',
	},
	TextDeepGreen: {
		color: '#4c8062',
	},
	TextDarkGrey: {
		color: '#777777',
	},
	greenDot: {
		width: 6,
		height: 6,
		marginLeft: '15px',
		marginRight: '5px',
		marginBottom: 'auto',
		marginTop: 'auto',
		borderRadius: 6,
		backgroundColor: 'green',
	},
	redDot: {
		width: 6,
		height: 6,
		marginLeft: '15px',
		marginRight: '5px',
		marginBottom: 'auto',
		marginTop: 'auto',
		borderRadius: 6,
		backgroundColor: 'red',
	},
	green: {
		fill: '#00C2A2 !important',
	},
	customIconWrapper: {
		display: 'flex',
		width: 30,
		height: 30,
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: 'rgba(195, 54, 52, 0.15)',
			borderRadius: '50%',
		},
		'&:focus': {
			outline: 'none',
		},
	},
	disabledIconWrapper: {
		width: 30,
		height: 30,
		display: 'flex',
	},
	customIcon: {
		fill: '#2774AE',
		fontSize: 20,
		width: 20,
		height: 20,
		marginLeft: 5,
		marginTop: 5,
	},
	disabledIcon: {
		fill: '#c33634',
		fontSize: 20,
		width: 20,
		height: 20,
		margin: 'auto',
		opacity: 0.15,
	},
	productName: {
		float: 'left',
	},
	selfIcon: {
		fill: '#2774AE',
		width: 20,
		height: 20,
		marginLeft: 5,
		float: 'left',
	},
	textFieldWrapper: {
		width: '100%',
	},
	linkTableCell: {
		color: '#545cd8',
	},
	greyOut: {
		color: '#999',
	},
	warningCell: {
		fontSize: 15,
		color: '#c33634',
		lineHeight: '40px',
	},
	childArea: {
		padding: 20,
	},
	fontWeightBold: {
		fontWeight: 'bold',
	},
};

export const darkTheme = createTheme({
	color: {
		paper: {
			bgcolor: '#222',
			textcolor: '#fff',
		},
		card: {
			bgcolor: '#344750',
			textcolor: '#fff',
		},
		divider: {
			dividercolor: '#fff',
		},
		table: {
			bodyrow: {
				bgcolor: '#3f3f3f',
				oddbgcolor: '#545454',
				hoverbgcolor: '#999',
			},
			headcell: {
				bgcolor: '#2c353d',
				textcolor: 'white',
			},
			bodycell: {
				textcolor: '#fff',
				firstcolor: '#00d169',
			},
			hover: {
				textcolor: '#b5b8a7',
			},
		},
		checkbox: {
			checked: '#2774AE',
			unchecked: '#fff',
		},
	},
});

export const lightTheme = createTheme({
	color: {
		paper: {
			bgcolor: '#f2f2f2',
			textcolor: '#000',
			hoverbgcolor: '#dcf5f1',
		},
		card: {
			bgcolor: '#fff',
			textcolor: '#000',
		},
		divider: {
			dividercolor: '#ddd',
		},
		table: {
			bodyrow: {
				bgcolor: '#fff',
				oddbgcolor: '#f5f5f5',
				hoverbgcolor: '#dcf5f1',
			},
			headcell: {
				bgcolor: '#fafafa',
				textcolor: '#5e5e5e',
			},
			bodycell: {
				textcolor: '#5e5e5e',
				firstcolor: '#545cd8',
			},
			hover: {
				textcolor: '#5e5e5e',
			},
		},
		checkbox: {
			checked: '#2774AE',
			unchecked: '#999',
		},
	},
});
