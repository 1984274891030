import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
	loaderWrapper: {
		position: 'relative',
		top: (props) => (props.size ? `calc(50% - ${Math.round(props.size / 2)}px)` : 'calc(50% - 32px)'),
		left: (props) => (props.size ? `calc(50% - ${Math.round(props.size / 2)}px)` : 'calc(50% - 32px)'),
	},
	loader: {
		border: '3px solid #f3f3f3',
		borderRadius: '50%',
		borderTop: '3px solid #2774AE',
		width: (props) => props.size || 64,
		height: (props) => props.size || 64,
		WebkitAnimation: 'spin 2s linear infinite' /* Safari */,
		animation: 'spin 2s linear infinite',
	},
}));
