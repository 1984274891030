import React from 'react';
import CensusDataPageContainer from '../../containers/censusData/censusDataPageContainer';

class CensusDataPage extends React.Component {
	render() {
		return <CensusDataPageContainer {...this.props} />;
	}
}

export default CensusDataPage;
