import React from 'react';
import CommunityPageContainer from '../../../containers/royMorgan/community/communityPageContainer';

class Community extends React.Component {
	render() {
		return <CommunityPageContainer {...this.props} />;
	}
}

export default Community;
