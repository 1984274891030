import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((props) => ({
	root: {
		width: (props) => (props.isMainMenuCollapsed ? '60px' : '220px'),
	},
}));

export const styles = {
	root: {
		backgroundColor: 'inherit',
		boxShadow: 'none',
		position: 'unset',
		'&:before': {
			backgroundColor: 'inherit',
		},
	},
	disabled: {
		backgroundColor: 'inherit !important',
	},
	expanded: {
		marginTop: '0px !important',
		marginBottom: '0px !important',
	},
};
