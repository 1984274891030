import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
	customIcon: {
		fill: (props) => props.color || '#31adce',
		marginRight: 10,
		'& div': {
			'& svg': {
				width: 24,
				height: 24,
				marginTop: 10,
			},
		},
	},
	siteDashboardTitle: {
		height: 40,
		padding: '0px 5px',
		display: 'flex',
		lineHeight: '55px',
		fontSize: (props) => (props.isCentrePage ? 14 : 12),
		textTransform: 'capitalize',
		fontWeight: '600',
		borderBottom: (props) => (props.isCentrePage ? 'none' : '2px solid'),
		borderColor: (props) => props.color || '#ddd',
		whiteSpace: 'nowrap',
		WebkitLineClamp: 1,
		WebkitBoxOrient: 'vertical',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		color: (props) => (props.isWhite ? '#fff' : props.textColor ? props.textColor : '#000'),
	},
}));
