import React from 'react';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { amchartsStyle } from '../../../constants/Theme';
import { Colors } from '../../../constants/Constants.json';

am4core.useTheme(am4themes_animated);

class SinglePieChart extends React.Component {
	componentDidMount() {
		const {
			id,
			data,
			categoryX,
			valueY,
			paddingRight,
			colors,
			isLegend,
			width,
			position,
			valign,
			contentAlign,
			innerRadius,
			paddingTop,
			paddingBottom,
			paddingLeft,
			isPercent,
			radius,
			legendPaddingLeft,
			showLabelsInside,
			showLabels,
		} = this.props;

		let chart = am4core.create(`chart-${id}`, am4charts.PieChart);
		chart.hiddenState.properties.opacity = 0;

		chart.data = data;
		chart.paddingRight = paddingRight;
		chart.paddingLeft = paddingLeft;
		chart.paddingTop = paddingTop;
		chart.paddingBottom = paddingBottom;

		chart.innerRadius = am4core.percent(innerRadius);

		let series = chart.series.push(new am4charts.PieSeries());
		series.dataFields.value = valueY;
		series.dataFields.category = categoryX;
		series.tooltip.label.fontSize = amchartsStyle.labelsFontSize;

		series.alignLabels = false;

		if (showLabelsInside) {
			series.ticks.template.disabled = true;
			series.labels.template.fill = am4core.color('white');
			if (radius && radius !== 10) {
				series.labels.template.radius = am4core.percent(radius);
			} else {
				series.labels.template.radius = am4core.percent(-40);
			}
		}

		if (isPercent) {
			series.labels.template.text = "{value.percent.formatNumber('#.0')}%";
		} else {
			series.labels.template.text = '{value.value}';
		}

		if (!showLabels) {
			series.labels.template.text = '';
		}

		var colorSet = new am4core.ColorSet();
		colorSet.list = colors.map((color) => {
			return new am4core.color(color);
		});
		series.colors = colorSet;

		if (isLegend) {
			chart.legend = new am4charts.Legend();
			chart.legend.position = position;
			chart.legend.valign = valign;
			chart.legend.contentAlign = contentAlign;
			chart.legend.valueLabels.template.text = '';
			chart.legend.fontSize = amchartsStyle.legendFontSize;
			if (width) {
				chart.legend.width = width;
			}
			chart.legend.labels.template.truncate = true;
			chart.legend.itemContainers.template.paddingTop = 5;
			chart.legend.itemContainers.template.paddingBottom = 0;
			chart.legend.itemContainers.template.paddingLeft = legendPaddingLeft;
			chart.legend.itemContainers.template.paddingRight = 0;
			chart.legend.margin(0, 0, 0, 0);

			var markerTemplate = chart.legend.markers.template;
			markerTemplate.width = amchartsStyle.legendWidthHeight;
			markerTemplate.height = amchartsStyle.legendWidthHeight;
		}

		this.chart = chart;
	}
	componentDidUpdate(prevProps) {
		const { data } = this.props;
		if (data.length > 0 && !isEqual(prevProps.data, data)) {
			this.chart.data = data;
		}
	}
	componentWillUnmount() {
		if (this.chart) {
			this.chart.dispose();
		}
	}
	render() {
		const { id } = this.props;
		return <div id={`chart-${id}`} style={{ width: '100%', height: '100%' }} data-testid='single-pie-chart-component'></div>;
	}
}
SinglePieChart.propTypes = {
	id: PropTypes.string,
	data: PropTypes.array,
	categoryX: PropTypes.string,
	valueY: PropTypes.string,
	colors: PropTypes.array,
	isLegend: PropTypes.bool,
	paddingRight: PropTypes.number,
	paddingTop: PropTypes.number,
	paddingBottom: PropTypes.number,
	paddingLeft: PropTypes.number,
	width: PropTypes.number,
	position: PropTypes.oneOf(['absolute', 'bottom', 'left', 'right', 'top']),
	valign: PropTypes.oneOf(['none', 'middle', 'bottom', 'top']),
	contentAlign: PropTypes.oneOf(['none', 'center', 'left', 'right']),
	innerRadius: PropTypes.number,
	isPercent: PropTypes.bool,
	radius: PropTypes.number,
	legendPaddingLeft: PropTypes.number,
	showLabelsInside: PropTypes.bool,
	showLabels: PropTypes.bool,
};

SinglePieChart.defaultProps = {
	id: '',
	data: [],
	categoryX: '',
	valueY: '',
	colors: Colors,
	isLegend: true,
	paddingRight: 20,
	paddingTop: 0,
	paddingBottom: 0,
	paddingLeft: 0,
	width: 0,
	position: 'bottom',
	valign: 'middle',
	contentAlign: 'center',
	innerRadius: 0,
	isPercent: false,
	radius: 10,
	legendPaddingLeft: 0,
	showLabelsInside: false,
	showLabels: true,
};

export default SinglePieChart;
