import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import classNames from 'classnames';

import useStyles from './styles';

const HeaderImages = (props) => {
	const { headerImages } = props;

	const classes = useStyles(props);

	const selectedImages = [headerImages[0], headerImages[1]];

	return (
		<>
			{selectedImages.map((image, index) => {
				const { filename } = image;
				return (
					<Grid item sm={12} md={6} key={`header-images-${index}`}>
						<div className={classNames(classes.column)}>
							<img src={`${process.env.REACT_APP_MEDIA_FILES_S3_BUCKET_URL}/personas/${filename}`} className={classes.img} alt={filename} />
						</div>
					</Grid>
				);
			})}
		</>
	);
};

HeaderImages.propTypes = {
	headerImages: PropTypes.array,
};

HeaderImages.defaultProps = {
	headerImages: [],
};

export default HeaderImages;
