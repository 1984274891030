import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
	buttonIconOutside: {
		borderRadius: (props) => (props.isCircle ? '50%' : '5px'),
		width: 40,
		height: 40,
		padding: 4,
	},
	buttonIconInside: {
		cursor: 'pointer',
		height: 30,
		width: 30,
		backgroundColor: (props) => (props.backgroundColor ? props.backgroundColor : '#56B7E6'),
		borderRadius: (props) => (props.isCircle ? '50%' : '5px'),
		border: '2px solid #2774AE',
	},
	initName: {
		fontSize: 14,
		textAlign: 'center',
		lineHeight: '26px',
		color: '#fff',
	},
}));
