import React from 'react';
import PropTypes from 'prop-types';
import CustomModal from '../../components/Modals/CustomModal';
import PasswordValidationInput from '../../components/TextFields/PasswordValidationInput';
import StarRateIcon from '@material-ui/icons/StarRate';
import { withStyles } from '@material-ui/core/styles';
import style from './styles';

class ResetPasswordModalTemplate extends React.Component {
	render() {
		const {
			classes,
			toggleResetPswModal,
			userNewPassword,
			userNewPasswordAgain,
			inputUserNewPasswordAgain,
			inputUserNewPassword,
			resetUserPasswordFailed,
			resetLoader,
			preferenceErrorMessage,
			handleSave,
			handleCancel,
		} = this.props;

		return (
			<CustomModal
				open={toggleResetPswModal}
				title={'Reset Password'}
				isCreate={false}
				loader={resetLoader}
				cancelAction={handleCancel}
				saveAction={handleSave}
				errorMessage={preferenceErrorMessage}
				removeErrorMessageAction={() => resetUserPasswordFailed({ message: '' })}
			>
				<div className={classes.textfieldHeight}>
					<div className={classes.wrapper}>
						<span className={classes.title} data-testid='reset-password-modal-template-new-password-span'>
							New Password
							<StarRateIcon className={classes.requiredIcon} />
						</span>
						<PasswordValidationInput password={userNewPassword} handleChangePasswordAction={inputUserNewPassword} id={'create-psite-audit-modal'} />
					</div>
				</div>
				<div className={classes.textfieldHeight}>
					<div className={classes.wrapper}>
						<span className={classes.title} data-testid='reset-password-modal-template-confirm-password-span'>
							Confirm Password
							<StarRateIcon className={classes.requiredIcon} />
						</span>
						<PasswordValidationInput
							password={userNewPasswordAgain}
							passwordToMatch={userNewPassword}
							handleChangePasswordAction={inputUserNewPasswordAgain}
							id={'create-psite-audit-modal-confirm'}
						/>
					</div>
				</div>
			</CustomModal>
		);
	}
}

ResetPasswordModalTemplate.propTypes = {
	userNewPassword: PropTypes.string,
	userNewPasswordAgain: PropTypes.string,
	resetUserPasswordFailed: PropTypes.func,
	resetLoader: PropTypes.bool,
	preferenceErrorMessage: PropTypes.string,
	handleResetPassword: PropTypes.func,
	toggleResetPswModal: PropTypes.bool,
	inputUserNewPasswordAgain: PropTypes.func,
	inputUserNewPassword: PropTypes.func,
	handleSave: PropTypes.func,
	handleCancel: PropTypes.func,
};

ResetPasswordModalTemplate.defaultProps = {
	userNewPassword: '',
	userNewPasswordAgain: '',
	resetUserPasswordFailed: null,
	resetLoader: false,
	preferenceErrorMessage: '',
	handleResetPassword: null,
	toggleResetPswModal: false,
	inputUserNewPasswordAgain: null,
	inputUserNewPassword: null,
	handleSave: null,
	handleCancel: null,
};

export default withStyles(style)(ResetPasswordModalTemplate);
