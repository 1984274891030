import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import { darkTheme, lightTheme } from '../../constants/Theme';
import useStyles from './styles';

const CustomCard = (props) => {
	const classes = useStyles(props);

	const { variant } = props;
	return (
		<Card data-testid='custom-card' classes={{ root: classes.root }} variant={variant}>
			{props.children}
		</Card>
	);
};

const HyveCard = (props) => {
	const { appTheme } = props;
	return (
		<ThemeProvider theme={appTheme ? lightTheme : darkTheme}>
			<CustomCard {...props} />
		</ThemeProvider>
	);
};

CustomCard.propTypes = {
	variant: PropTypes.string,
};

CustomCard.defaultProps = {
	variant: 'elevation',
};

const mapStateToProps = (state) => ({
	appTheme: state.appReducer.get('appTheme'),
});

export default connect(mapStateToProps)(HyveCard);
