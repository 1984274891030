import React from 'react';
import CentreDashboardPageContainer from '../../containers/centreDashboard/centreDashboardPageContainer';

class CentreDashboardPage extends React.Component {
	render() {
		return <CentreDashboardPageContainer {...this.props} />;
	}
}

export default CentreDashboardPage;
