import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import HyveCard from '../../components/CustomCard';
import SiteDashboardFilters from '../../components/SiteDashboardFilters';
import SitesSelectorWithSearch from '../../components/SitesSelectorWithSearch';
import CustomLoader from '../../components/CustomLoader';
import DateRangePicker from '../../components/DateRangePicker';
import CentrePageTemplate from '../centrePageTemplate/index.template';
import { Colors } from '../../constants/Constants.json';
import styles from './styles.scss';
import css from './styles.js';

const cx = classNames.bind(styles);

class CentreDashboardTemplate extends React.Component {
	render() {
		const {
			classes,
			sitesLoader,
			sites,
			selectedSiteId,
			siteKey,
			openDatePicker,
			ranges,
			startDate,
			endDate,
			exportSiteName,
			clientWidth,
			csv,
			toggleDateRangePicker,
			handleClickRun,
			handleSelectDateRange,
			wrapperRef,
			handleChangeSite,
			handleCsv,
			currentSiteReportVisitsByDate,
			RVBDateLoader,
			errorMessageRVBDate,
			demographics,
			errorMessageDemographics,
			DemographicsLoader,
		} = this.props;

		if (sitesLoader) {
			return (
				<div className={cx('siteDashboard')} data-testid='centre-dashboard-template-loader'>
					<CustomLoader site={64} />
				</div>
			);
		} else {
			return (
				<div className={cx('centrePageWrapper')} data-testid='centre-dashboard-template'>
					<div className={cx('centrePageLayout')}>
						<div className={cx('centrePageHeader')}>
							<HyveCard isCentrePage={true}>
								<div className={classes.siteDashboardHeader}>
									<div className={classes.siteDashboardTitle} data-testid='centre-dashboard-template-header'>
										<h2 style={{ marginBottom: '10px', fontWeight: 600 }}>CENTRE INSIGHTS</h2>
									</div>
									<SiteDashboardFilters
										showDateFilter={true}
										showSizeFilter={false}
										showStateFilter={false}
										showAreaFilter={false}
										ranges={ranges}
										toggleDateRangePicker={toggleDateRangePicker}
										handleClickRun={handleClickRun}
										color={Colors[16]}
										textColor={Colors[16]}
										highlightHover={true}
										disableHover={true}
										csv={csv}
									/>
									{openDatePicker && (
										<div className={cx('dateRangePickerArea')} ref={wrapperRef} data-testid='centre-dashboard-template-date-picker'>
											<DateRangePicker handleSelectDateRange={handleSelectDateRange} ranges={ranges} />
										</div>
									)}
									<div className={classes.filtersRight}>
										<div className={classNames(classes.siteSelector, classes.selectors, classes.inlineFilters)}>
											<SitesSelectorWithSearch
												selectedSiteId={selectedSiteId}
												sites={sites}
												label='Centre'
												handleChangeSite={handleChangeSite}
												siteKey={siteKey}
											/>
										</div>
									</div>
								</div>
							</HyveCard>
							<div className={cx('centrePageMiddleContent')}>
								<CentrePageTemplate
									ranges={ranges}
									startDate={startDate}
									endDate={endDate}
									exportSiteName={exportSiteName}
									clientWidth={clientWidth}
									handleCsv={handleCsv}
									currentSiteReportVisitsByDate={currentSiteReportVisitsByDate}
									RVBDateLoader={RVBDateLoader}
									errorMessageRVBDate={errorMessageRVBDate}
									demographics={demographics}
									errorMessageDemographics={errorMessageDemographics}
									DemographicsLoader={DemographicsLoader}
								/>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}

CentreDashboardTemplate.propTypes = {
	sitesLoader: PropTypes.bool,
	sites: PropTypes.array,
	selectedSiteId: PropTypes.string,
	siteKey: PropTypes.string,
	openDatePicker: PropTypes.bool,
	startDate: PropTypes.string,
	endDate: PropTypes.string,
	exportSiteName: PropTypes.string,
	clientWidth: PropTypes.number,
	wrapperRef: PropTypes.object,
	handleCsv: PropTypes.func,
	currentSiteReportVisitsByDate: PropTypes.object,
	RVBDateLoader: PropTypes.bool,
	errorMessageRVBDate: PropTypes.string,
	demographics: PropTypes.object,
	errorMessageDemographics: PropTypes.string,
	DemographicsLoader: PropTypes.bool,
	ranges: PropTypes.array,
	toggleDateRangePicker: PropTypes.func,
	csv: PropTypes.array,
	handleClickRun: PropTypes.func,
	handleSelectDateRange: PropTypes.func,
	handleChangeSite: PropTypes.func,
};

CentreDashboardTemplate.defaultProps = {
	sitesLoader: false,
	sites: [],
	selectedSiteId: '',
	siteKey: 'id',
	openDatePicker: false,
	startDate: '',
	endDate: '',
	exportSiteName: '',
	clientWidth: null,
	wrapperRef: {},
	handleCsv: () => {},
	currentSiteReportVisitsByDate: {},
	RVBDateLoader: false,
	errorMessageRVBDate: '',
	demographics: {},
	errorMessageDemographics: '',
	DemographicsLoader: false,
	ranges: [],
	toggleDateRangePicker: null,
	csv: [],
	handleClickRun: null,
	handleSelectDateRange: () => {},
	handleChangeSite: null,
};

export default withStyles(css)(CentreDashboardTemplate);
