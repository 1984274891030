import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
	page: {
		width: '100%',
		height: '100%',
		backgroundColor: 'white',
		padding: 80,
		display: 'flex',
	},
	inside: {
		margin: 'auto',
		textAlign: 'center',
	},
}));
