import * as ACTION_TYPES from '../../constants/ActionTypes';

export function fetchCommunities(siteId, radius) {
	return {
		type: ACTION_TYPES.FETCH_COMMUNITIES,
		siteId,
		radius,
	};
}

export function fetchCommunitiesDone(data) {
	return {
		type: ACTION_TYPES.FETCH_COMMUNITIES_DONE,
		data,
	};
}

export function fetchCommunitiesFailed(error) {
	return {
		type: ACTION_TYPES.FETCH_COMMUNITIES_FAILED,
		error,
	};
}
